import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/textarea.css";

const TextareaComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="textarea-component" className="textarea">
      <div className="textarea-question">
        <Interweave content={title} />{" "}
      </div>
      <div className="textarea-content">
        <textarea cols="30" rows="5" className="textarea" disabled></textarea>
      </div>
    </div>
  );
};

export default TextareaComponent;
