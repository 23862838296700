import api, { headers } from "../../services/api";

export const fetchHomeSurveysFoldersAPI = async () => {
  return api.get("survey/home/folder", { headers });
};

export const createNewFolderAPI = async (data) => {
  return api.post("survey/home/folder", data, { headers });
};

export const createNewSurveyAPI = async (data) => {
  return api.post("survey/home/list", data, { headers });
};

export const updateSurveyAPI = async (surveyid, data) => {
  return api.put("survey/home/" + surveyid, data, { headers });
};

export const updateFolderAPI = async (folderid, data) => {
  return api.put("survey/home/folder/" + folderid, data, { headers });
};

export const deleteSurveyAPI = async (surveyid) => {
  return api.delete("survey/home/" + surveyid, { headers });
};

export const deleteFolderAPI = async (folderid) => {
  return api.delete("survey/home/folder/" + folderid, { headers });
};

export const moveSurveyAPI = async (data) => {
  return api.put("survey/home/move", data, { headers });
};

export const getTempleteEmailByIdAPI = async (enviroment, templateId) => {
  return api.get(`email/${enviroment}/${templateId}`, { headers });
};

export const updateTempleteAPI = async (enviroment, templateId, data) => {
  return api.put(`email/${enviroment}/${templateId}`, data, { headers });
};
