import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "home",
  initialState: {
    surveys: null,
    update: false,
    template: null,
  },
  reducers: {
    setSurveys(state, { payload }) {
      state.surveys = payload;
    },
    setUpdate(state) {
      state.update = !state.update;
    },
    setTemplate(state, { payload }) {
      state.template = payload;
    },
  },
});

export const { setSurveys, setUpdate, setTemplate } = slice.actions;

export const surveys = (state) => state.home.surveys;
export const update = (state) => state.home.update;
export const template = (state) => state.home.template;

export default slice.reducer;
