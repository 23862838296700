const LANGAGUE = {
  //GENERAL
  INIT: "Início",
  PROCESSING: "Processando...",
  LOADING: "Carregando...",
  BUILDER: "Construtor",
  STYLE: "Estilos",
  SHARE: "Compartilhar",
  RESULTS: "Resultados",
  GENERAL: "Geral",
  ADD: "Adicionar",
  ADDING: "Adicionando",
  EDIT: "Editar",
  DUPLICATE: "Duplicar",
  DUPLICATING: "Duplicando",
  MOVE: "Mover",
  MOVING: "Movendo",
  DELETE: "Excluir",
  DELETING: "Excluindo",
  PAGE_NOT_FOUND: "Página não encontrada!",
  CHANGING_LANGUAGE: "Alterando idioma..",
  SURVEYS: "Pesquisas",
  SURVEY: "Pesquisa",
  FILES: "Arquivos",
  LOGOUT: "Sair",
  NAME: "Nome",
  FOLDER: "Pasta",
  FOLDER_NAME: "Nome da Pasta",
  CANCEL: "Cancelar",
  CREATE: "Criar",
  LANGUAGE: "Idioma",
  TITLE: "Título",
  NO_DATA: "Sem dados",
  INSERT: "INSERIR",
  OPEN: "Abrir",
  REOPEN: "Reabrir",
  CAUTION: "ATENÇÃO",
  SAVE: "Salvar",
  SAVING: "Salvando",
  SAVED: "Salvo",
  TRANSLATION: "Tradução",
  CLOSE: "Fechar",
  RENAME: "Renomear",
  TRASH: "Lixeira",
  TRY_AGAIN: "Tente novamente",
  CHANGE_MADE_SUCCESSFULLY: "Alteração feita com sucesso",
  FAILED_MAKE_CHANGE: "Falha ao realizar alteração",
  COPY: "Copiar",
  COPY_LINK: "Copiar URL",
  COPIED_LINK: "URL copiada",
  OPEN_LINK: "Abrir link",
  DEFAULT: "Padrão",
  PREVIEW: "Pré-visualizar",
  GROUP: "Grupo",
  CLEAR: "Limpar",
  PASTE: "Colar",
  IMPORT: "Importar",
  OPTIONS: "Opções",
  LANGUAGES: [
    { value: "de-de", label: "Alemão" },
    { value: "zh-cn", label: "Chinês" },
    { value: "ko-kr", label: "Coreano" },
    { value: "es-es", label: "Espanhol" },
    { value: "fr-fr", label: "Francês" },
    { value: "nl-nl", label: "Holandês" },
    { value: "en-us", label: "Inglês" },
    { value: "it-it", label: "Italiano" },
    { value: "ja-jp", label: "Japonês" },
    { value: "pt-br", label: "Português (Brasil)" },
    { value: "pt-pt", label: "Português (Portugal)" },
    { value: "ru-ru", label: "Russo" },
    { value: "sv-se", label: "Suíço" },
  ],

  //AUTH
  LOGIN_AUTHORIZATED: "Acesso autorizado!",
  FAIL_COMMUNICATION: "Falha de comunicação",
  USER_DO_NOT_FOUND: "Usuário ou senha incorretos.",
  USER: "Usuário",
  PASSWORD: "Senha",
  LOGIN_BTN_TEXT: "ENTRAR",
  SOME_FIELDS_EMPTY: "Algum campo está vazio",
  ERROR_NETWORK: "Você está sem internet!",
  NETWORK_ONLINE: "Você está com internet!",
  EMAIL_UNVERIFIED:
    "Você precisa verificar seu email para utilizar nossa plataforma. Acesse sua caixa de entrada e valide sua conta Google.",
  EMAIL_UNREGISTRED:
    "Você precisa estar registrado em nossa plataforma para utilizar nossos serviços.",
  NO_PERMISSION: "Você precisa de permissão para utilizar nossa plataforma.",
  INVALID_REQUEST: "Requisição Inválida.",
  EXITING: "Saindo",
  LOGIN_WITH_GOOGLE: "Entrar com Google",

  //HOME
  SEARCH_BAR_PLACEHOLDER: "Busque por pasta ou pesquisa",
  CREATE_NEW_FOLDER: "Criar nova pasta",
  NEW_FOLDER: "Nova pasta",
  CREATE_NEW_SURVEY: "Criar nova pesquisa",
  NEW_SURVEY: "Nova pesquisa",
  GRID_VIEW_MODE: "Visão de grade",
  LIST_VIEW_MODE: "Visão de lista",
  ALL_SURVEYS: "Todas pesquisas",
  ACTIVE_LABEL: "Ativa",
  INACTIVE_LABEL: "Inativa",
  EMPTY_FOLDER: "Pasta vazia. Crie uma pasta ou pesquisa (+)",
  FOLDER_CREATED_SUCCESSFULLY: "Pasta criada com successo",
  FAILED_CREATE_FOLDER: "Falha ao criar pasta",
  SURVEY_CREATED: "Pesquisa criada",
  FAIL_CREATED_SURVEY: "Falha ao criar pesquisa",
  FAIL_DELETE_FOLDER: "Falha ao excluir pasta",
  FAIL_DELETE_SURVEY: "Falha ao excluir pesquisa",
  FAIL_COPY_SURVEY: "Falha ao copiar pesquisa",
  DELETED_FOLDER: "Pasta excluída",
  DELETED_SURVEY: "Pesquisa excluída",
  FOLDER_MOVED_SUCCESSFULLY: "Pasta movida com sucesso",
  SURVEY_MOVED_SUCCESSFULLY: "Pesquisa movida com sucesso",
  FAIL_MOVE_ITEM: "Falha ao mover o item",
  CANNOT_MOVE_FOLDER_OWN_FOLDER: "Não pode mover para própria pasta",
  MOVE_SURVEY_MSG1: "Esta pesquisa já está nesta pasta",
  MOVE_FOLDER_MSG1: "Esta pasta já está neste local",
  MOVE_FOLDER_MSG2: "Nenhuma pasta selecionada",
  COPIED_SURVEY: "Pesquisa copiada",
  MESSAGE_DELETE_FOLDER: `
    Deseja realmente excluir esta pasta?<br />
    <span style="color:red"><strong>
    Esta ação é irreversível, todas pesquisas e 
    pastas contidas dentro, serão excluídas.</strong></span>`,
  MESSAGE_DELETE_SURVEY: `
    Deseja realmente excluir esta pesquisa?<br />
    <span style="color:red"><strong>
    Esta ação é irreversível.</strong></span>`,

  //THEME
  THEME: "Tema",
  THEMES: "Temas",
  EMPTY_THEME: "Pasta vazia. Crie uma pasta ou tema (+)",
  ALL_THEMES: "Todos temas",
  SEARCH_BAR_PLACEHOLDER_THEME: "Busque por pasta ou tema",
  CREATE_NEW_THEME: "Criar novo tema",
  NEW_THEME: "Novo Tema",
  CREATED_THEME: "Tema criado",
  FAIL_CREATE_THEME: "Falha ao criar tema",
  COPIED_THEME: "Tema copiado",
  FAIL_COPY_THEME: "Falha ao copiar tema",
  DELETED_THEME: "Tema excluído",
  FAIL_DELETE_THEME: "Falha ao excluir tema",
  THEME_MOVED_SUCCESSFULLY: "Tema movido com sucesso",
  THEME_MSG1: `
    Deseja realmente excluir esta pasta?<br />
    <span style="color:red"><strong>
    Esta ação é irreversível, todos os temas e 
    pastas contidas dentro, serão excluídas.</strong></span>`,
  THEME_MSG2: `
    Deseja realmente excluir este tema?<br />
    <span style="color:red"><strong>
    Esta ação é irreversível.</strong></span>`,
  THEME_MSG3: "Este tema já está nesta pasta",
  THEME_MSG4: "Esta pasta já está neste local",

  //SHARE
  CREATED_LINK: "Link criado",
  FAIL_CREATE_LINK: "Falha ao criar link",
  UPDATED_LINK: "Link atualizado",
  FAIL_UPDATE_LINK: "Falha ao atualizar link",
  TABLET_MODE_EXPLANATION: `O Modo Tablet é ativo para pesquisas via tablet 
    presencial (local). Ex.: O cliente faz uma compra no 
    shopping e avalia a experiência da compra ao final.`,

  //BUILDER
  NEW_PAGE: "Nova Página",
  MESSAGE_EMPTY_BUILD_PAGE: "Vamos adicionar algo a esta página",
  DELETE_PAGE_SUCCESS: "Página excluída com sucesso.",
  DELETE_QUESTION_SUCCESS: "Questão excluída.",
  DUPLICATE_QUESTION_SUCCESS: "Questão duplicada.",
  DUPLICATE_PAGE_SUCCESS: "Página duplicada.",
  SURVEY_DO_NOT_FOUND: "Pesquisa não encontrada",
  NO_SURVEYS_FOUND: "Nenhuma pesquisa encontrada",
  QUESTION: "Questão",
  ACTION: "Ação",
  LOGIC: "Lógica",
  MERGE_PAGES: "Mesclar Páginas",
  MERGING_PAGES: "Mesclando Páginas",
  MERGED_PAGE: "Página Mesclada",
  BREAK_PAGE: "Quebrar página",
  TEXT_EXAMPLE_NEW_QUESTION: "Este é um texto simples",
  CREATING_PAGE: "Criando página",
  CAUTION_MERGE_PAGE: "Esta ação é irreversível! Deseja mesclar páginas?",
  CAUTION_BREAK_PAGE: "Esta ação é irreversível! Deseja quebrar essa página?",
  MOVING_PAGES: "Movendo páginas",
  MOVED_PAGE: "Página movida",
  MOVED_QUESTION: "Questão movida",
  TOP_OF_PAGE: "Topo da página",
  SAVED_PAGE: "Página Salva",
  QUESTION_TYPES: {
    text: "Texto",
    nps: "NPS",
    csat: "CSAT",
    ces: "CES",
    radiobox: "Radiobox",
    checkbox: "Checkbox",
    input: "Input",
    dropdown: "Dropdown",
    textarea: "Campo Aberto",
    optin: "Opt IN",
    mosaic: "Mosaico",
    "auto-complete": "Auto Completar",
    "language-dropdown": "Seletor de Idioma",
    "cascading-dropdown": "Dropdown Cascata",
    "image-upload": "Carregador de Imagens",
    "reset-button": "Botão Resetar",
  },
  QUESTION_HAS_LOGIC: "Esta questão possui lógica",
  EDIT_PAGE: "Editar Página",
  ACTIVE_PAGE: "Página Ativa",
  MOVE_PAGE: "Mover Página",
  NO_APPLY: "Não se aplica",
  RECTANGULAR: "Retangular",
  CIRCULAR: "Circular",
  NO_SHADOW: "Sem Sombra",
  WITH_SHADOW: "Com Sombra",
  STYLES: "Estilos",
  COLORFULL: "Colorido",
  COLORFULL_EMOTICON: "Emoticon Colorido",
  TRIAD: "Tríade",
  ACTIVE_COLOR: "Ativar Cor",
  PILL: "Pílula",
  PILL_CHECK: "Pílula Check",
  BLOCK: "Bloco",
  STARS: "Estrelas",
  STARS_3D: "Estrelas 3D",
  STARS_3D_ANIMATED: "Estrelas 3D Animadas",
  LIKE_NO_LIKE: "Curte/Não Curte",
  LOVE_NO_LOVE: "Ama/Não Ama",
  TEXT: "Texto",
  PHONE: "Telefone",
  DATE: "Data",
  TIME: "Hora",
  DATE_TIME: "Data/Hora",
  CSS_CLASS: "Classe CSS",
  DISPOSITION: "Disposição",
  NORMAL: "Normal",
  COLOR: "Cor",
  ALIGNMENT: "Alinhamento",
  TEXT_BUTTON: "TEXTO BOTÃO",
  MARKED: "Marcado",
  UNMARKED: "Desmarcado",
  BUILD_MSG_1: "Habilitar sombreamento de botões",
  BUILD_MSG_2: `Caso queira formatar algo no css (estilo) da questão, 
    insira a classe desejada. Recurso criado para densenvolvedores 
    de sua instituição ou responsável pelo design.`,
  BUILD_MSG_3: "Selecione uma questão",
  BUILD_MSG_4: "Selecione uma opção",
  BUILD_MSG_5: "Nome da variável",
  BUILD_MSG_6: "Valor da variável",
  BUILD_MSG_7: `Ao inserir uma lógica, esta questão só aparecerá 
    se a(s) condição(ões) for(em) satisfeita(s).`,

  // TEMPLATE BUILDER
  LOADING_TEMPLATE: "Carregando modelo...",
  NEW_TEMPLATE_CREATED: "Novo modelo criado",
  EXPORT_HTML: "Exportar HTML",
  TEMPLATE_UPDATED: "Modelo atualizado"
};

export default LANGAGUE;
