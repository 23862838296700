import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input, Flex, Switch, Space, Divider, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";

//icons
import { FaSquare, FaCircle } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";

//redux
import { updateX, setUpdateX } from "../../../store/builder/builder.slice";

//utils
import { getStorage, setStorage } from "../../../utils/Storage";
import { getLuminosity } from "../../../utils/String";
import i18n from "../../../utils/i18n";

//images
import rbdIcon from "../../../assets/images/survey/radiobox-default.svg";
import rbcIcon from "../../../assets/images/survey/radiobox-check.svg";
import rbpIcon from "../../../assets/images/survey/radiobox-pill.svg";
import rbpcIcon from "../../../assets/images/survey/radiobox-pill-check.svg";
import rbfIcon from "../../../assets/images/survey/radiobox-full.svg";
import cbdIcon from "../../../assets/images/survey/checkbox-default.svg";
import cbcIcon from "../../../assets/images/survey/checkbox-check.svg";
import npsPrevNormal from "../../../assets/images/survey/nps-preview-normal.svg";
import npsPrevTriade from "../../../assets/images/survey/nps-preview-triade.svg";
import npsPrevColorfull from "../../../assets/images/survey/nps-preview-colorfull.svg";
import npsPrevStandard from "../../../assets/images/survey/nps-preview-standard.svg";
import npsPrevStandardIcon from "../../../assets/images/survey/nps-preview-standard-icon.svg";

import csatPrevNormal from "../../../assets/images/survey/csat-preview-normal.svg";
import csatPrevNormal2 from "../../../assets/images/survey/csat-preview-normal2.svg";
import csatPrevColorfull from "../../../assets/images/survey/csat-preview-colorfull.svg";
import csatPrevColor3 from "../../../assets/images/survey/csat-preview-color3.svg";
import csatPrevEVHL from "../../../assets/images/survey/emoticon-very-happy-light.svg";
import csatPrevEVH from "../../../assets/images/survey/emoticon-very-happy.svg";
import csatPrevStandard from "../../../assets/images/survey/csat-preview-standard.svg";
import csatPrevStandardIcon from "../../../assets/images/survey/csat-preview-standard-icon.svg";
import csatPrevE3d05 from "../../../assets/images/survey/emoticon-3d-05.png";
import csatPrevStar from "../../../assets/images/survey/star.svg";
import csatPrevStar3D from "../../../assets/images/survey/star-3d.svg";
import csatPrevS3DA from "../../../assets/images/survey/star-animated.png";
import csatPrevLHUC from "../../../assets/images/survey/like-hands-up-checked.svg";
import csatPrevHeart from "../../../assets/images/survey/heart-red.svg";

//styles
import "../../../assets/styles/builder/build.css";

const AbaLayout = () => {
  const { surveyid } = useParams();
  const dispatch = useDispatch();
  const update = useSelector(updateX);

  //states
  const [qData, setQData] = useState(null);
  const setLocalData = () => setQData(getStorage("EDITION_" + surveyid, true));

  useEffect(() => {
    setLocalData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLocalData();
    // eslint-disable-next-line
  }, [update]);

  const NPSLayout = () => {
    const hasDesign = () => {
      if (qData.properties && qData.properties.triade) {
        return "triade";
      } else if (qData.properties && qData.properties.colorfull) {
        return "colorfull";
      } else if (qData.properties && qData.properties.standard) {
        return "standard";
      }

      return "";
    };

    const returnPreview = () => {
      return {
        triade: npsPrevTriade,
        colorfull: npsPrevColorfull,
        standard: npsPrevStandard,
      };
    };

    const designCustomClass = () => {
      let designCustom = [];

      //aplicando bordas redondas
      if (qData.properties && qData.properties.rounded) {
        designCustom.push("rounded");
      }

      //aplicando sombra
      if (qData.properties && qData.properties.shadow) {
        designCustom.push("shadow");
      }

      //aplicando colorido
      if (qData.properties && qData.properties.colorfull) {
        designCustom.push("colorfull");
      }

      //aplicando três cores (tríade)
      if (qData.properties && qData.properties.triade) {
        designCustom.push("triade");
      }

      //aplicando padrão antigo
      if (qData.properties && qData.properties.standard) {
        designCustom.push("standard");
      }

      return designCustom.join(" ");
    };

    const npsStyleBaseColor = () => {
      const styleNPS =
        qData.properties && qData.properties.baseColor
          ? `
            span.nps-${qData.id} {
              border: solid 1px ${qData.properties.baseColor};
              background-color: ${qData.properties.baseColor};
              color: #fff !important;
            }
            `
          : ``;

      return styleNPS;
    };

    const [npsShape, setNPSShape] = useState(
      qData.properties && qData.properties.rounded ? "rounded" : "square"
    );
    const [npsShadow, setNPSShadow] = useState(
      qData.properties && qData.properties.shadow ? "shadow" : ""
    );
    const [npsDesign, setNPSDesign] = useState(hasDesign());
    const [activeNPSColor, setActiveNPSColor] = useState(
      qData.properties && qData.properties.baseColor
    );
    const [npsBaseColor, setNPSBaseColor] = useState(
      qData.properties && qData.properties.baseColor
        ? qData.properties.baseColor
        : "#000000"
    );
    const [npsClassDesign, setNPSClassDesign] = useState(designCustomClass());
    const [npsStyleBC, setNPSStyleBC] = useState(npsStyleBaseColor());
    const [npsImagePreview, setNPSImagePreview] = useState(
      returnPreview()[hasDesign()] || npsPrevNormal
    );

    const changeNPS = (value, action) => {
      const delOthersPropsMinorThis = (value) => {
        if (value !== "colorfull" && qData.properties.colorfull)
          delete qData.properties.colorfull;
        if (value !== "triade" && qData.properties.triade)
          delete qData.properties.triade;
        if (value !== "baseColor" && qData.properties.baseColor)
          delete qData.properties.baseColor;
        if (value !== "standard" && qData.properties.standard)
          delete qData.properties.standard;
      };

      if (action === "shape") {
        if (value === "rounded") {
          qData.properties.rounded = true;
        } else {
          delete qData.properties.rounded;
        }
        setNPSShape(value);
      } else if (action === "shadow") {
        if (value === "shadow") {
          qData.properties.shadow = true;
        } else {
          delete qData.properties.shadow;
        }
        setNPSShadow(value);
      } else if (action === "design") {
        if (value !== "") {
          if (value === "triade") {
            qData.properties.triade = true;
          } else if (value === "colorfull") {
            qData.properties.colorfull = true;
          } else if (value === "standard") {
            //radiobox
            qData.properties.standard = true;

            if (npsShape === "rounded") {
              setNPSShape("square");
              delete qData.properties.rounded;
            }

            if (npsShadow === "shadow") {
              setNPSShadow("");
              delete qData.properties.shadow;
            }
          }

          setActiveNPSColor(false);
        }

        delOthersPropsMinorThis(value);
        setNPSDesign(value);
      } else if (action === "active-color") {
        qData.properties.baseColor = "#000000";
        setActiveNPSColor(value);
      } else if (action === "color") {
        qData.properties.baseColor = value;
        setNPSBaseColor(value);
      }

      if (action === "design") {
        setNPSImagePreview(returnPreview()[value] || npsPrevNormal);
      }

      setNPSStyleBC(npsStyleBaseColor());
      setNPSClassDesign(designCustomClass());

      setStorage("EDITION_" + surveyid, qData, true);
    };

    return (
      <div className="nps">
        <div className="step1">
          {[
            { label: i18n.RECTANGULAR, key: "square", icon: <FaSquare /> },
            { label: i18n.CIRCULAR, key: "rounded", icon: <FaCircle /> },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === npsShape ? "primary" : "default"}
              onClick={() => changeNPS(btn.key, "shape")}
              icon={btn.icon}
              disabled={btn.key === "rounded" && npsDesign === "standard"}
            >
              {btn.label}
            </Button>
          ))}
        </div>
        <div className="step2">
          {[
            { label: i18n.NO_SHADOW, key: "" },
            { label: i18n.WITH_SHADOW, key: "shadow" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === npsShadow ? "primary" : "default"}
              onClick={() => changeNPS(btn.key, "shadow")}
              icon={btn.icon}
              disabled={btn.key === "shadow" && npsDesign === "standard"}
            >
              {btn.label}
            </Button>
          ))}
        </div>
        <Divider>{i18n.STYLES}</Divider>
        <div className="step3">
          {[
            { label: i18n.DEFAULT, key: "" },
            { label: i18n.TRIAD, key: "triade" },
            { label: i18n.COLORFULL, key: "colorfull" },
            { label: "Radioxbox", key: "standard" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === npsDesign ? "primary" : "default"}
              onClick={() => changeNPS(btn.key, "design")}
            >
              {btn.label}
            </Button>
          ))}
        </div>

        {npsDesign === "" && (
          <div className="switch">
            <Space direction="horizontal">
              <div>
                <Switch
                  value={activeNPSColor}
                  onChange={() => changeNPS(!activeNPSColor, "active-color")}
                />
                {` ${i18n.ACTIVE_COLOR}`}
              </div>
              {activeNPSColor && (
                <>
                  <input
                    type="color"
                    value={npsBaseColor}
                    onChange={({ target: { value } }) => {
                      changeNPS(value, "color");
                    }}
                  />
                </>
              )}
            </Space>
          </div>
        )}

        <div className="nps-preview">
          <div className="head">{i18n.PREVIEW}</div>
          <div className="item">
            {npsDesign !== "standard" ? (
              <span className={`${npsClassDesign} nps-${qData.id}`}>10</span>
            ) : (
              <img
                src={npsPrevStandardIcon}
                className="standard-icon"
                alt="npsPrevStandardIcon"
              ></img>
            )}

            <div className="model">
              <img src={npsImagePreview} alt="preview" />
            </div>
          </div>
          <style>{npsStyleBC}</style>
        </div>
      </div>
    );
  };

  const CSATLayout = () => {
    const hasDesign = () => {
      if (qData.properties && qData.properties.emoticon) {
        return "emoticon";
      } else if (qData.properties && qData.properties.emoticonColorfull) {
        return "emoticon-colorfull";
      } else if (qData.properties && qData.properties.emoticon3D) {
        return "emoticon-3d";
      } else if (qData.properties && qData.properties.colorfull) {
        return "colorfull";
      } else if (qData.properties && qData.properties.stars) {
        return "stars";
      } else if (qData.properties && qData.properties.stars3D) {
        return "stars-3d";
      } else if (qData.properties && qData.properties.stars3DAnimated) {
        return "stars-3d-animated";
      } else if (qData.properties && qData.properties.faces3) {
        return "faces3";
      } else if (qData.properties && qData.properties.likeDeslike) {
        return "like-dislike";
      } else if (qData.properties && qData.properties.loveDislove) {
        return "love-dislove";
      } else if (qData.properties && qData.properties.standard) {
        return "standard";
      }

      return "";
    };

    const returnPreview = () => {
      return {
        colorfull: csatPrevColorfull,
        emoticon: csatPrevColorfull,
        "emoticon-colorfull": csatPrevColorfull,
        faces3: csatPrevColor3,
        "like-dislike": csatPrevNormal2,
        "love-dislove": csatPrevNormal2,
        standard: csatPrevStandard,
      };
    };

    const designCustomClass = () => {
      let designCustom = [];

      //aplicando bordas redondas
      if (qData.properties && qData.properties.rounded)
        designCustom.push("rounded");

      //aplicando sombra
      if (qData.properties && qData.properties.shadow)
        designCustom.push("shadow");

      //aplicando colorido
      if (qData.properties && qData.properties.colorfull)
        designCustom.push("colorfull");

      //aplicando emoticon básicos
      if (qData.properties && qData.properties.emoticon)
        designCustom.push("emoticon");

      //aplicando emoticon colorido
      if (qData.properties && qData.properties.emoticonColorfull)
        designCustom.push("emoticon-colorfull");

      //aplicando emoticon 3D
      if (qData.properties && qData.properties.emoticon3D)
        designCustom.push("emoticon-3d");

      //aplicando estrelas
      if (qData.properties && qData.properties.stars)
        designCustom.push("stars");

      //aplicando estrelas 3D
      if (qData.properties && qData.properties.stars3D)
        designCustom.push("stars-3d");

      //aplicando estrelas 3D animadas
      if (qData.properties && qData.properties.stars3DAnimated)
        designCustom.push("stars-3d-animated");

      //aplicando csat com 3 rostinhos
      if (qData.properties && qData.properties.faces3)
        designCustom.push("faces-3");

      //aplicando like - deslike
      if (qData.properties && qData.properties.likeDeslike)
        designCustom.push("like-dislike");

      //aplicando ama não ama
      if (qData.properties && qData.properties.loveDislove)
        designCustom.push("love-dislove");

      //aplicando padrão antigo
      if (qData.properties && qData.properties.standard)
        designCustom.push("standard");

      return designCustom.join(" ");
    };

    const csatStyleBaseColor = () => {
      const styleCSAT =
        qData.properties && qData.properties.baseColor
          ? `
            span.csat-${qData.id} {
              border: solid 1px ${qData.properties.baseColor};
              background-color: ${qData.properties.baseColor};
              color: #fff !important;
            }
            `
          : ``;

      return styleCSAT;
    };

    const [csatShape, setCsatShape] = useState(
      qData.properties && qData.properties.rounded ? "rounded" : "square"
    );
    const [csatShadow, setCsatShadow] = useState(
      qData.properties && qData.properties.shadow ? "shadow" : ""
    );
    const [csatDesign, setCsatDesign] = useState(hasDesign());
    const [activeCsatColor, setActiveCsatColor] = useState(
      qData.properties && qData.properties.baseColor
    );
    const [csatBaseColor, setCsatBaseColor] = useState(
      qData.properties && qData.properties.baseColor
        ? qData.properties.baseColor
        : "#000000"
    );
    const [csatClassDesign, setCsatClassDesign] = useState(designCustomClass());
    const [csatStyleBC, setCsatStyleBC] = useState(csatStyleBaseColor());
    const [csatImagePreview, setCsatImagePreview] = useState(
      returnPreview()[hasDesign()] || csatPrevNormal
    );

    const changeCSAT = (value, action) => {
      const delOthersPropsMinorThis = (value) => {
        if (value !== "colorfull" && qData.properties.colorfull)
          delete qData.properties.colorfull;
        if (value !== "emoticon" && qData.properties.emoticon)
          delete qData.properties.emoticon;
        if (
          value !== "emoticon-colorfull" &&
          qData.properties.emoticonColorfull
        )
          delete qData.properties.emoticonColorfull;
        if (value !== "emoticon-3d" && qData.properties.emoticon3D)
          delete qData.properties.emoticon3D;
        if (value !== "stars" && qData.properties.stars)
          delete qData.properties.stars;
        if (value !== "stars-3d" && qData.properties.stars3D)
          delete qData.properties.stars3D;
        if (value !== "stars-3d-animated" && qData.properties.stars3DAnimated)
          delete qData.properties.stars3DAnimated;
        if (value !== "faces3" && qData.properties.faces3)
          delete qData.properties.faces3;
        if (value !== "like-dislike" && qData.properties.likeDeslike)
          delete qData.properties.likeDeslike;
        if (value !== "love-dislove" && qData.properties.loveDislove)
          delete qData.properties.loveDislove;
        if (value !== "baseColor" && qData.properties.baseColor)
          delete qData.properties.baseColor;
        if (value !== "standard" && qData.properties.standard)
          delete qData.properties.standard;
      };

      if (action === "shape") {
        if (value === "rounded") {
          qData.properties.rounded = true;
        } else {
          delete qData.properties.rounded;
        }
        setCsatShape(value);
      } else if (action === "shadow") {
        if (value === "shadow") {
          qData.properties.shadow = true;
        } else {
          delete qData.properties.shadow;
        }
        setCsatShadow(value);
      } else if (action === "design") {
        if (value !== "") {
          if (value === "emoticon") {
            qData.properties.emoticon = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "colorfull") {
            qData.properties.colorfull = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "emoticon-colorfull") {
            qData.properties.emoticonColorfull = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "emoticon-3d") {
            qData.properties.emoticon3D = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "stars") {
            qData.properties.stars = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "stars-3d") {
            qData.properties.stars3D = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "stars-3d-animated") {
            qData.properties.stars3DAnimated = true;
            qData.options = [1, 2, 3, 4, 5];
          } else if (value === "faces3") {
            qData.properties.faces3 = true;
            qData.options = [1, 2, 3];
            if (qData.properties?.naOptionLeft)
              delete qData.properties?.naOptionLeft;
            if (qData.properties?.naOptionRight)
              delete qData.properties?.naOptionRight;
          } else if (value === "like-dislike") {
            qData.properties.likeDeslike = true;
            qData.options = [1, 2];
            if (qData.properties?.naOptionLeft)
              delete qData.properties?.naOptionLeft;
            if (qData.properties?.naOptionRight)
              delete qData.properties?.naOptionRight;
          } else if (value === "love-dislove") {
            qData.properties.loveDislove = true;
            qData.options = [1, 2];
            if (qData.properties?.naOptionLeft)
              delete qData.properties.naOptionLeft;
            if (qData.properties?.naOptionRight)
              delete qData.properties.naOptionRight;
          } else if (value === "standard") {
            //radiobox
            qData.properties.standard = true;
            qData.options = [1, 2, 3, 4, 5];
          }

          if (
            [
              "standard",
              "emoticon",
              "emoticon-3d",
              "emoticon-colorfull",
              "stars",
              "stars-3d",
              "stars-3d-animated",
              "faces3",
              "like-dislike",
              "love-dislove",
            ].includes(value)
          ) {
            if (csatShape === "rounded") {
              setCsatShape("square");
              delete qData.properties.rounded;
            }

            if (csatShadow === "shadow") {
              setCsatShadow("");
              delete qData.properties.shadow;
            }
          }

          setActiveCsatColor(false);
        }

        delOthersPropsMinorThis(value);
        setCsatDesign(value);
      } else if (action === "active-color") {
        qData.properties.baseColor = "#000000";
        setActiveCsatColor(value);
      } else if (action === "color") {
        qData.properties.baseColor = value;
        setCsatBaseColor(value);
      }

      if (action === "design") {
        setCsatImagePreview(returnPreview()[value] || csatPrevNormal);
      }

      setCsatStyleBC(csatStyleBaseColor());
      setCsatClassDesign(designCustomClass());

      setStorage("EDITION_" + surveyid, qData, true);
    };

    return (
      <div className="csat">
        <div className="step1">
          {[
            { label: i18n.RECTANGULAR, key: "square", icon: <FaSquare /> },
            { label: i18n.CIRCULAR, key: "rounded", icon: <FaCircle /> },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === csatShape ? "primary" : "default"}
              onClick={() => changeCSAT(btn.key, "shape")}
              icon={btn.icon}
              disabled={
                btn.key === "rounded" &&
                [
                  "standard",
                  "emoticon",
                  "emoticon-3d",
                  "emoticon-colorfull",
                  "stars",
                  "stars-3d",
                  "stars-3d-animated",
                  "faces3",
                  "like-dislike",
                  "love-dislove",
                ].includes(csatDesign)
              }
            >
              {btn.label}
            </Button>
          ))}
        </div>
        <div className="step2">
          {[
            { label: i18n.NO_SHADOW, key: "" },
            { label: i18n.WITH_SHADOW, key: "shadow" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === csatShadow ? "primary" : "default"}
              onClick={() => changeCSAT(btn.key, "shadow")}
              icon={btn.icon}
              disabled={
                btn.key === "shadow" &&
                [
                  "standard",
                  "emoticon",
                  "emoticon-3d",
                  "emoticon-colorfull",
                  "stars",
                  "stars-3d",
                  "stars-3d-animated",
                  "faces3",
                  "like-dislike",
                  "love-dislove",
                ].includes(csatDesign)
              }
            >
              {btn.label}
            </Button>
          ))}
        </div>
        <Divider orientation="left">{i18n.STYLES}</Divider>
        <div className="step3">
          {[
            { label: i18n.DEFAULT, key: "" },
            { label: i18n.COLORFULL, key: "colorfull" },
            { label: "Emoticon", key: "emoticon" },
            { label: i18n.COLORFULL_EMOTICON, key: "emoticon-colorfull" },
            { label: "Emoticon 3D", key: "emoticon-3d" },
            { label: i18n.STARS, key: "stars" },
            { label: i18n.STARS_3D, key: "stars-3d" },
            { label: i18n.STARS_3D_ANIMATED, key: "stars-3d-animated" },
            { label: "3 faces", key: "faces3" },
            { label: i18n.LIKE_NO_LIKE, key: "like-dislike" },
            { label: i18n.LOVE_NO_LOVE, key: "love-dislove" },
            { label: "Radioxbox", key: "standard" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === csatDesign ? "primary" : "default"}
              onClick={() => changeCSAT(btn.key, "design")}
            >
              {btn.label}
            </Button>
          ))}
        </div>
        {csatDesign === "" && (
          <div className="switch">
            <Space direction="horizontal">
              <div>
                <Switch
                  value={activeCsatColor}
                  onChange={() => changeCSAT(!activeCsatColor, "active-color")}
                />
                {` ${i18n.ACTIVE_COLOR}`}
              </div>
              {activeCsatColor && (
                <>
                  <input
                    type="color"
                    value={csatBaseColor}
                    onChange={({ target: { value } }) => {
                      changeCSAT(value, "color");
                    }}
                  />
                </>
              )}
            </Space>
          </div>
        )}

        <div className="csat-preview">
          <div className="head">{i18n.PREVIEW}</div>
          <div className="item">
            {csatDesign !== "standard" ? (
              <>
                {(csatDesign === "" || csatDesign === "colorfull") && (
                  <span className={`${csatClassDesign} csat-${qData.id}`}>
                    5
                  </span>
                )}
                {csatDesign === "emoticon" && (
                  <img
                    src={csatPrevEVHL}
                    className="standard-icon"
                    alt="evhl-icon"
                  ></img>
                )}
                {csatDesign === "emoticon-3d" && (
                  <img
                    src={csatPrevE3d05}
                    className="standard-icon"
                    alt="e3d05-icon"
                  ></img>
                )}
                {["emoticon-colorfull", "faces3"].includes(csatDesign) && (
                  <img
                    src={csatPrevEVH}
                    className="standard-icon"
                    alt="evh-icon"
                  ></img>
                )}
                {csatDesign === "stars" && (
                  <img
                    src={csatPrevStar}
                    className="standard-icon"
                    alt="stars-icon"
                  ></img>
                )}
                {csatDesign === "stars-3d" && (
                  <img
                    src={csatPrevStar3D}
                    className="standard-icon"
                    alt="stars-3d-icon"
                  ></img>
                )}
                {csatDesign === "stars-3d-animated" && (
                  <img
                    src={csatPrevS3DA}
                    className="standard-icon"
                    alt="stars-3d-animated-icon"
                  ></img>
                )}
                {csatDesign === "like-dislike" && (
                  <img
                    src={csatPrevLHUC}
                    className="standard-icon"
                    alt="like-dislike"
                  ></img>
                )}
                {csatDesign === "love-dislove" && (
                  <img
                    src={csatPrevHeart}
                    className="standard-icon"
                    alt="love-dislove"
                  ></img>
                )}
              </>
            ) : (
              <img
                src={csatPrevStandardIcon}
                className="standard-icon"
                alt="csatPrevStandardIcon"
              ></img>
            )}

            <div className="model">
              <img src={csatImagePreview} alt="preview" />
            </div>
          </div>
          <style>{csatStyleBC}</style>
        </div>
      </div>
    );
  };

  const RadioboxLayout = () => {
    const hasDesign = () => {
      if (qData.properties && qData.properties.check) {
        return "check";
      } else if (qData.properties && qData.properties.pill) {
        return "pill";
      } else if (qData.properties && qData.properties.pillCheck) {
        return "pill-check";
      } else if (qData.properties && qData.properties.full) {
        return "full";
      }

      return "";
    };

    const [radioboxDesign, setRadioboxDesign] = useState(hasDesign());

    const changeRadiobox = (value, action) => {
      const delOthersPropsMinorThis = (value) => {
        if (value !== "check" && qData.properties?.check)
          delete qData.properties.check;
        if (value !== "pill" && qData.properties?.pill)
          delete qData.properties.pill;
        if (value !== "pill-check" && qData.properties?.pillCheck)
          delete qData.properties.pillCheck;
        if (value !== "full" && qData.properties?.full) {
          delete qData.properties.full;
          if (qData.properties?.shadow) {
            delete qData.properties.shadow;
          }
        }
      };

      if (action === "design") {
        if (value !== "") {
          if (value === "check") {
            qData.properties.check = true;
          } else if (value === "pill") {
            qData.properties.pill = true;
          } else if (value === "pill-check") {
            qData.properties.pillCheck = true;
          } else if (value === "full") {
            qData.properties.full = true;
          }
        }

        delOthersPropsMinorThis(value);
        setRadioboxDesign(value);
      }

      setStorage("EDITION_" + surveyid, qData, true);
    };

    return (
      <div className="radiobox">
        <div className="wrap-buttons">
          {[
            { icon: rbdIcon, label: i18n.DEFAULT, key: "" },
            { icon: rbcIcon, label: "Check", key: "check" },
            { icon: rbpIcon, label: i18n.PILL, key: "pill" },
            {
              icon: rbpcIcon,
              label: i18n.PILL_CHECK,
              key: "pill-check",
            },
            { icon: rbfIcon, label: i18n.BLOCK, key: "full" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              className={btn.key === radioboxDesign ? "active" : ""}
              onClick={() => changeRadiobox(btn.key, "design")}
            >
              <img src={btn.icon} alt="Icone Botão" />
              {btn.label}
            </Button>
          ))}
        </div>
        {qData.properties?.full && (
          <div className="wrap">
            <Switch
              checked={qData.properties?.shadow}
              onChange={() => {
                if (qData.properties?.shadow) {
                  delete qData.properties.shadow;
                } else {
                  qData.properties.shadow = true;
                }
                setStorage("EDITION_" + surveyid, qData, true);
              }}
            />
            {` ${i18n.BUILD_MSG_1}`}
          </div>
        )}
      </div>
    );
  };

  const CheckboxLayout = () => {
    const hasDesign = () => {
      if (qData.properties && qData.properties.check) {
        return "check";
      } else if (qData.properties && qData.properties.pill) {
        return "pill";
      } else if (qData.properties && qData.properties.pillCheck) {
        return "pill-check";
      } else if (qData.properties && qData.properties.full) {
        return "full";
      }

      return "";
    };

    const [checkboxDesign, setCheckboxDesign] = useState(hasDesign());

    const changeCheckbox = (value, action) => {
      const delOthersPropsMinorThis = (value) => {
        if (value !== "check" && qData.properties?.check)
          delete qData.properties.check;
        if (value !== "pill" && qData.properties?.pill)
          delete qData.properties.pill;
        if (value !== "pill-check" && qData.properties?.pillCheck)
          delete qData.properties.pillCheck;
        if (value !== "full" && qData.properties?.full) {
          delete qData.properties.full;
          if (qData.properties?.shadow) {
            delete qData.properties.shadow;
          }
        }
      };

      if (action === "design") {
        if (value !== "") {
          if (value === "check") {
            qData.properties.check = true;
          } else if (value === "pill") {
            qData.properties.pill = true;
          } else if (value === "pill-check") {
            qData.properties.pillCheck = true;
          } else if (value === "full") {
            qData.properties.full = true;
          }
        }

        delOthersPropsMinorThis(value);
        setCheckboxDesign(value);
      }

      setStorage("EDITION_" + surveyid, qData, true);
    };

    return (
      <div className="checkbox">
        <div className="wrap-buttons">
          {[
            { icon: cbdIcon, label: i18n.DEFAULT, key: "" },
            { icon: cbcIcon, label: "Check", key: "check" },
            { icon: rbpIcon, label: i18n.PILL, key: "pill" },
            {
              icon: rbpcIcon,
              label: i18n.PILL_CHECK,
              key: "pill-check",
            },
            { icon: rbfIcon, label: i18n.BLOCK, key: "full" },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              className={btn.key === checkboxDesign ? "active" : ""}
              onClick={() => changeCheckbox(btn.key, "design")}
            >
              <img src={btn.icon} alt="Icone Botão" />
              {btn.label}
            </Button>
          ))}
        </div>
        {qData.properties?.full && (
          <div className="wrap">
            <Switch
              checked={qData.properties?.shadow}
              onChange={() => {
                if (qData.properties?.shadow) {
                  delete qData.properties.shadow;
                } else {
                  qData.properties.shadow = true;
                }
                setStorage("EDITION_" + surveyid, qData, true);
              }}
            />
            {` ${i18n.BUILD_MSG_1}`}
          </div>
        )}
      </div>
    );
  };

  const InputLayout = () => {
    const hasDesign = () => {
      if (qData.properties?.email) {
        return "email";
      } else if (qData.properties?.cpf) {
        return "cpf";
      } else if (qData.properties?.tel) {
        return "tel";
      } else if (qData.properties?.date) {
        return "date";
      } else if (qData.properties?.time) {
        return "time";
      } else if (qData.properties?.datetime) {
        return "datetime";
      }

      return "";
    };

    const [inputDesign, setInputDesign] = useState(hasDesign());

    const changeInput = (inputData) => {
      const { key, maxLength } = inputData;

      const delOthersPropsMinorThis = (value) => {
        if (value !== "email" && qData.properties?.email)
          delete qData.properties.email;
        if (value !== "cpf" && qData.properties?.cpf)
          delete qData.properties.cpf;
        if (value !== "tel" && qData.properties?.tel)
          delete qData.properties.tel;
        if (value !== "date" && qData.properties?.date)
          delete qData.properties.date;
        if (value !== "time" && qData.properties?.time)
          delete qData.properties.time;
        if (value !== "datetime" && qData.properties?.datetime)
          delete qData.properties.datetime;
      };

      if (key !== "") {
        if (key === "email") {
          qData.properties.email = true;
        } else if (key === "cpf") {
          qData.properties.cpf = true;
        } else if (key === "tel") {
          qData.properties.tel = true;
        } else if (key === "date") {
          qData.properties.date = true;
        } else if (key === "time") {
          qData.properties.time = true;
        } else if (key === "datetime") {
          qData.properties.datetime = true;
        }
      }

      qData.properties.maxLength = maxLength;

      delOthersPropsMinorThis(key);
      setInputDesign(key);
      setStorage("EDITION_" + surveyid, qData, true);
      dispatch(setUpdateX());
    };

    return (
      <div className="input">
        <Flex gap={5} wrap="wrap">
          {[
            { label: i18n.TEXT, key: "", maxLength: 250 },
            { label: "@Email", key: "email", maxLength: 150 },
            { label: "CPF", key: "cpf", maxLength: 15 },
            { label: i18n.PHONE, key: "tel", maxLength: 20 },
            { label: i18n.DATE, key: "date", maxLength: 10 },
            { label: i18n.TIME, key: "time", maxLength: 5 },
            { label: i18n.DATE_TIME, key: "datetime", maxLength: 17 },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === inputDesign ? "primary" : "default"}
              onClick={() => changeInput(btn)}
            >
              {btn.label}
            </Button>
          ))}
        </Flex>
      </div>
    );
  };

  const InputCSSClass = () => {
    const [cssClass, setCssClass] = useState(qData.cssClass || "");

    const changeCSSClass = (value) => {
      if (value !== "") {
        qData.cssClass = value;
      } else {
        delete qData.cssClass;
      }

      setStorage("EDITION_" + surveyid, qData, true);
      setCssClass(value);
    };

    return (
      <div className="editor-css-class">
        <strong>
          {i18n.CSS_CLASS}
          <Tooltip title={i18n.BUILD_MSG_2} style={{ display: "inline" }}>
            <sup>
              <QuestionCircleOutlined />
            </sup>
          </Tooltip>
        </strong>

        <div className="items">
          <Input
            value={cssClass}
            onChange={({ target: { value } }) => changeCSSClass(value)}
          />
        </div>
      </div>
    );
  };

  const ResetButtonLayout = () => {
    const [bgColor, setBgColor] = useState(
      qData.properties?.bgColor || "#9a2376"
    );
    const [align, setAlign] = useState(qData.properties?.align || "center");
    const [block, setBlock] = useState(qData.properties?.block || false);

    const changeValue = (value, action) => {
      if (action === "position") {
        qData.properties.block = value;
        setBlock(value);
      } else if (action === "align") {
        qData.properties.align = value;
        setAlign(value);
      } else if (action === "color") {
        qData.properties.bgColor = value;
        setBgColor(value);
      }

      setStorage("EDITION_" + surveyid, qData, true);
    };

    return (
      <div className="reset-button">
        <Divider>{i18n.DISPOSITION}</Divider>
        <div className="step1">
          {[
            { label: i18n.NORMAL, key: false },
            { label: i18n.BLOCK, key: true },
          ].map((btn, btnIdx) => (
            <Button
              key={`btn-rb${btnIdx}`}
              type={btn.key === block ? "primary" : "default"}
              onClick={() => changeValue(btn.key, "position")}
            >
              {btn.label}
            </Button>
          ))}
        </div>
        <Divider>{i18n.COLOR}</Divider>
        <div className="step2">
          <input
            className="input-color"
            type="color"
            value={bgColor || "#9a2376"}
            onChange={({ target: { value } }) => changeValue(value, "color")}
          />
        </div>
        {!block && (
          <>
            <Divider>{i18n.ALIGNMENT}</Divider>
            <div className="step3">
              {[
                { label: "Esquerda", key: "left" },
                { label: "Centro", key: "center" },
                { label: "Direita", key: "right" },
              ].map((btn, btnIdx) => (
                <Button
                  key={`btn-rb${btnIdx}`}
                  type={btn.key === align ? "primary" : "default"}
                  onClick={() => changeValue(btn.key, "align")}
                >
                  {btn.label}
                </Button>
              ))}
            </div>
          </>
        )}
        <Divider orientation="left">{i18n.PREVIEW}</Divider>
        <div
          className="box"
          style={{
            textAlign: align ? align : "center",
          }}
        >
          <button
            style={{
              backgroundColor: bgColor ? bgColor : "#9a2376",
              color: bgColor
                ? getLuminosity(bgColor) === "dark"
                  ? "#fff"
                  : "#000"
                : "#fff",
              display: block ? "block" : "inline",
              width: block ? "100%" : "auto",
            }}
          >
            {i18n.TEXT_BUTTON}
          </button>
        </div>
      </div>
    );
  };

  const CustomDesign = () => {
    switch (qData.type) {
      case "nps":
        return <NPSLayout />;
      case "csat":
        return <CSATLayout />;
      case "radiobox":
        return <RadioboxLayout />;
      case "checkbox":
        return <CheckboxLayout />;
      case "input":
        return <InputLayout />;
      case "reset-button":
        return <ResetButtonLayout />;
      default:
        return <></>;
    }
  };

  return (
    qData !== null && (
      <>
        {[
          "nps",
          "csat",
          "checkbox",
          "radiobox",
          "input",
          "reset-button",
        ].includes(qData.type) && (
          <div className="editor-design">
            <Divider orientation="left">Design</Divider>
            <div className="editor-items">
              <CustomDesign />
            </div>
          </div>
        )}

        <InputCSSClass />
      </>
    )
  );
};

export default AbaLayout;
