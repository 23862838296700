//import Crypto from "crypto-js";
//import { isLocalhost } from "../../utils/String";

/**
 * Cria um storage se ele não existir. O padrão é vazio, mas
 * ele pode receber um valor padrão desde que seja informado
 * @param {string} name - nome do storage
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const createStorageIfNotExist = (name, value, isJson = false) => {
  const storeFilter = localStorage.getItem(name);

  if (storeFilter === null) {
    if (value !== "" && isJson) {
      value = JSON.stringify(value);
      /* if (!isLocalhost()) {
        value = Crypto.AES.encrypt(value, decryptKeyStorage()).toString();
      } */
    }

    localStorage.setItem(name, value);
  }
};

/**
 * Cria um storage
 * @param {string} name - nome do storage
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const setStorage = (name, value, isJson = false) => {
  if (value !== "" && isJson) {
    value = JSON.stringify(value);
    /* if (!isLocalhost()) {
      value = Crypto.AES.encrypt(value, decryptKeyStorage()).toString();
    } */
  }

  localStorage.setItem(name, value);
};

/**
 * Pega o valor de um storage e retorna se ele existir
 * @param {string} name - nome do storage
 * @param {boolean} isJson - se verdadeiro, transforma uma string em objeto
 * @returns {string|object}
 */
export const getStorage = (name, isJson = false) => {
  let storage = localStorage.getItem(name);

  if (storage !== "" && storage !== null) {
    if (isJson) {
      storage = JSON.parse(storage);

      /* if (!isLocalhost()) {
        storage = Crypto.AES.decrypt(storage, decryptKeyStorage()).toString(
          Crypto.enc.Utf8
        );
      } */
    }
  } else {
    storage = "";
  }

  return storage;
};

/**
 * Exclui um storage específico
 * @param {string} name
 */
export const deleteStorage = (name) => {
  localStorage.removeItem(name);
};

/**
 * Apaga todos os storages do navegador
 */
export const clearStorages = () => {
  localStorage.clear();
};

/**
 * Chave para descriptograr o local storage
 */
export const decryptKeyStorage = () => {
  return "&!*wAq#3EZ5XBq7U";
};
