import React, { useState, useCallback } from "react";

import { Button, Form, Input, Modal } from "antd";

//tiptap
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Paragraph from "@tiptap/extension-paragraph";
import Text2 from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Heading from "@tiptap/extension-heading";
import LinkTT from "@tiptap/extension-link";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";

//icons
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  PictureOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  LuAlignCenter,
  LuAlignLeft,
  LuAlignRight,
  LuAlignJustify,
} from "react-icons/lu";

/**
 * Recebe um objeto passando os sequintes valor:
 * val - valor a ser alterado.
 * fnOK - Recebe a função externa que vai salvar o texto modificado.
 * fnCancel - Recebe a função externa que vai cancelar algo
 * @param {{val, fnOK, fnCancel}}
 * @returns
 */
const Wysiwyg = (props) => {
  let { val, fnOK, fnCancel } = props;

  fnCancel = fnCancel || function () {};

  const { TextArea } = Input;
  const [isEditing, setIsEditing] = useState(false);
  const [wyValue, setWyValue] = useState(val);
  const [openModalLink, setOpenModalLink] = useState(false);
  const [openModalImg, setOpenModalImg] = useState(false);

  const MenuBar = () => {
    const { editor } = useCurrentEditor();

    const addImage = (linkimg) => {
      editor.chain().focus().setImage({ src: linkimg }).run();
      setOpenModalImg(false);
    };

    const setLink = useCallback(
      (desc, url) => {
        editor
          .chain()
          .focus()
          .insertContent(`<a href="${url}">${desc}</a>`)
          .extendMarkRange("link")
          .run();

        setOpenModalLink(false);
      },
      [editor]
    );

    const onFinishLink = (values) => setLink(values.description, values.url);
    const onFinishImg = (values) => addImage(values.url);
    if (!editor) return null;

    return (
      <>
        <div className="toolbar">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            <BoldOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            <ItalicOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={!editor.can().chain().focus().toggleUnderline().run()}
            className={editor.isActive("underline") ? "is-active" : ""}
          >
            <UnderlineOutlined />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "is-active" : ""}
          >
            <StrikethroughOutlined />
          </button>
          <div className="divisor"></div>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive("heading", { level: 1 }) ? "is-active" : ""
            }
          >
            H1
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive("heading", { level: 2 }) ? "is-active" : ""
            }
          >
            H2
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive("heading", { level: 3 }) ? "is-active" : ""
            }
          >
            H3
          </button>
          <div className="divisor"></div>
          <button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            className={
              editor.isActive({ textAlign: "left" }) ? "is-active" : ""
            }
          >
            <LuAlignLeft />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            className={
              editor.isActive({ textAlign: "center" }) ? "is-active" : ""
            }
          >
            <LuAlignCenter />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            className={
              editor.isActive({ textAlign: "right" }) ? "is-active" : ""
            }
          >
            <LuAlignRight />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("justify").run()}
            className={
              editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
            }
          >
            <LuAlignJustify />
          </button>
          <div className="divisor"></div>
          <button onClick={() => setOpenModalImg(!openModalImg)}>
            <PictureOutlined />
          </button>
          <button
            onClick={() => setOpenModalLink(!openModalLink)}
            className={editor.isActive("link") ? "is-active" : ""}
          >
            <LinkOutlined />
          </button>
        </div>
        <Modal
          title="Inserir URL"
          open={openModalLink}
          onCancel={() => setOpenModalLink(!openModalLink)}
          footer={null}
        >
          <Form
            name="basic"
            onFinish={onFinishLink}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Descrição"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Informe o descritivo do link",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Informe o link da URL",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                INSERIR
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Inserir Imagem"
          open={openModalImg}
          onCancel={() => setOpenModalImg(!openModalImg)}
          footer={null}
        >
          <Form
            name="basic"
            onFinish={onFinishImg}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Informe o link da imagem",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                INSERIR
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  };

  const saveChanges = () => {
    fnOK(wyValue);
  };

  return (
    <>
      <div id="inovyo-wysiwyg">
        {isEditing && (
          <>
            <div className="toolbar">
              <button onClick={() => setIsEditing(!isEditing)}>{`</>`}</button>
            </div>
            <TextArea
              rows={4}
              maxLength={100000}
              onChange={({ target: { value } }) => setWyValue(value)}
              value={wyValue}
            />
          </>
        )}
        {!isEditing && (
          <EditorProvider
            slotBefore={<MenuBar />}
            content={wyValue}
            extensions={[
              Document,
              Paragraph,
              Text2,
              Image.configure({
                inline: true,
              }),
              Dropcursor,
              Underline,
              Bold,
              Italic,
              Strike,
              Highlight,
              TextAlign.configure({
                types: ["heading", "paragraph"],
              }),
              Heading,
              LinkTT.configure({
                validate: (href) => /^https?:\/\//.test(href),
                defaultProtocol: "https",
                openOnClick: false,
                HTMLAttributes: {
                  rel: "noopener noreferrer",
                },
              }),
            ]}
            onUpdate={(u) => setWyValue(u.editor.getHTML())}
          />
        )}
      </div>
      <div className="buttons-modal-no-footer">
        <Button onClick={() => fnCancel(false)}>CANCELAR</Button>
        <Button type="primary" onClick={() => saveChanges()}>
          OK
        </Button>
      </div>
    </>
  );
};

export default Wysiwyg;
