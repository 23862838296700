import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "theme",
  initialState: {
    themes: null,
    update: false,
    theme: null,
  },
  reducers: {
    setThemes(state, { payload }) {
      state.themes = payload;
    },
    setUpdate(state) {
      state.update = !state.update;
    },
    setTheme(state, { payload }) {
      state.theme = payload;
    },
  },
});

export const { setThemes, setUpdate, setTheme } = slice.actions;

export const themes = (state) => state.theme.themes;
export const update = (state) => state.theme.update;
export const theme = (state) => state.theme.theme;

export default slice.reducer;
