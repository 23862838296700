import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/mosaic.css";

//images
import MosaicSVG from "../../../../assets/images/survey/mosaic-cards-gray.svg";

const MosaicComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="mosaic-component" className={"mosaic"}>
      <div className="mosaic-title">
        <Interweave content={title} />
      </div>
      <div className="mosaic-content">
        <img src={MosaicSVG} alt="Mosaic" width="100%" />
      </div>
    </div>
  );
};

export default MosaicComponent;
