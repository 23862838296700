import { toast } from "react-toastify";
import { setStorage } from "../../utils/Storage";
import { setCookie } from "../../utils/Cookie";
import i18n from "../../utils/i18n";

//redux
import { doLoginAPI } from "./auth.service";
import { setLoadingRedux } from "../application/app.slice";

export const doLogin = (dataLogin) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    doLoginAPI(dataLogin)
      .then(({ data: jsonAPI }) => {
        toast.success(i18n.LOGIN_AUTHORIZATED, { autoClose: 1000 });
        setStorage("TOKEN", jsonAPI);
        setCookie("TOKEN", jsonAPI, 1);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.USER_DO_NOT_FOUND, { autoClose: 1000 });
        dispatch(setLoadingRedux(false));
      });
  };
};
