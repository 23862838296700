import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Typography, Card } from "antd";
import { toast } from "react-toastify";

//utils
import i18n from "../../../utils/i18n";

//redux
import { doLogin } from "../../../store/auth/auth.action";

//icons
import GoogleIcon from "../../../assets/images/google-icon.svg";

//styles
import "../../../assets/styles/auth/login.css";

const LoginPage = () => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const hostProduction = window.location.hostname.indexOf("localhost") <= -1;

  useEffect(() => {
    if (moment().format("MM") === "12") {
      let scriptTag = document.createElement("script");
      scriptTag.src =
        "https://cdn.jsdelivr.net/gh/nextapps-de/snowflake@master/snowflake.min.js";
      scriptTag.type = "text/javascript";
      document.head.appendChild(scriptTag);
      document.body.classList.add("christmas");
    }
    // eslint-disable-next-line
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();

    if (username === "" || password === "") {
      toast.warning(i18n.SOME_FIELDS_EMPTY);
      return;
    }

    dispatch(
      doLogin({
        username,
        password,
      })
    );
  };

  return (
    <div id="login-page">
      <Card className="login-box">
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          {!hostProduction && (
            <>
              <Form.Item label={i18n.USER} name="username">
                <Input
                  value={username}
                  onChange={({ target: { value } }) => setUsername(value)}
                />
              </Form.Item>

              <Form.Item label={i18n.PASSWORD} name="password">
                <Input.Password
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={(e) => handleLogin(e)}
                  block
                >
                  {i18n.LOGIN_BTN_TEXT}
                </Button>
              </Form.Item>
            </>
          )}

          {hostProduction && (
            <Form.Item>
              <div className="login-google">
                <img src={GoogleIcon} alt="GoogleIcon" />
                <a href="https://api.inovyosurvey.com.br/auth/google">
                  {i18n.LOGIN_WITH_GOOGLE}
                </a>
              </div>
            </Form.Item>
          )}
        </Form>
        <Text
          type="secondary"
          style={{
            display: "block",
            width: "100%",
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          <span style={{ color: "red" }}> &hearts;</span> Inovyo Survey Manager
          <sup>&copy;</sup>
          <br />
          2022 - {moment().format("YYYY")}
        </Text>
      </Card>
      {/* {showTree && <div className="tree"></div>} */}
    </div>
  );
};

export default LoginPage;
