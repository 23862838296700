import api, { headers } from "../../services/api";

export const fetchThemeFoldersAPI = async () => {
  return api.get("theme/home/folder", { headers });
};

export const getThemeAPI = async (themeId) => {
  return api.get(`theme/${themeId}/build`, { headers });
};

export const renameThemeAPI = async (themeId, data) => {
  return api.put(`theme/home/${themeId}`, data, { headers });
};

export const updateThemeAPI = async (themeId, data) => {
  return api.put(`theme/${themeId}/build/`, data, { headers });
};

export const createNewThemeAPI = async (data) => {
  return api.post("theme/home/list", data, { headers });
};

export const deleteThemeAPI = async (themeId) => {
  return api.delete("theme/home/" + themeId, { headers });
};

export const moveThemeAPI = async (data) => {
  return api.put("theme/home/move", data, { headers });
};

export const updateFolderAPI = async (folderid, data) => {
  return api.put("theme/home/folder/" + folderid, data, { headers });
};

export const createNewFolderAPI = async (data) => {
  return api.post("theme/home/folder", data, { headers });
};

export const deleteFolderAPI = async (folderid) => {
  return api.delete("theme/home/folder/" + folderid, { headers });
};
