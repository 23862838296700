import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "builder",
  initialState: {
    surveyData: null,
    updateX: false,
    shareData: null,
    progressLoading: false,
    openEditor: false,
    maxPageId: 1,
    maxQuestionId: 1,
  },
  reducers: {
    setSurveyData(state, { payload }) {
      state.surveyData = payload;
    },
    setUpdateX(state) {
      state.updateX = !state.updateX;
    },
    setShareData(state, { payload }) {
      state.shareData = payload;
    },
    setProgressLoading(state, { payload }) {
      state.progressLoading = payload;
    },
    setOpenEditor(state, { payload }) {
      state.openEditor = payload;
    },
    setMaxPageId(state, { payload }) {
      state.maxPageId = payload;
    },
    setMaxQuestionId(state, { payload }) {
      state.maxQuestionId = payload;
    },
  },
});

export const {
  setSurveyData,
  setUpdateX,
  setShareData,
  setProgressLoading,
  setOpenEditor,
  setMaxPageId,
  setMaxQuestionId,
} = slice.actions;

export const surveyData = (state) => state.builder.surveyData;
export const updateX = (state) => state.builder.updateX;
export const shareData = (state) => state.builder.shareData;
export const progressLoading = (state) => state.builder.progressLoading;
export const openEditorX = (state) => state.builder.openEditor;
export const maxPageId = (state) => state.builder.maxPageId;
export const maxQuestionId = (state) => state.builder.maxQuestionId;

export default slice.reducer;
