import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/checkbox.css";

const CheckboxComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  let design = "checkbox";
  if (data.properties && data.properties.design) {
    design =
      {
        check: "checkbox-check",
        pill: "checkbox-pill",
        full: "checkbox-full",
        "pill-check": "checkbox-pill-check",
      }[data.properties.design] || "checkbox";
  }

  const CheckboxDefault = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <div
                key={`checkbox-option-${data.id}-${i}`}
                className="checkbox-option"
              >
                <input
                  type="checkbox"
                  id={`checkbox-${data.id}-${i}`}
                  name={`checkbox-${data.id}`}
                  className="checkbox-item"
                  disabled
                />
                <label htmlFor={`checkbox-${data.id}-${i}`}>
                  {item.label[language]}
                </label>
              </div>
            );
          })}
      </>
    );
  };

  const CheckboxCustom = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <React.Fragment key={`checkbox-option-${data.id}-${i}`}>
                <div className={`checkbox-option`}>
                  <span>{item.label[language]}</span>
                </div>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const GenerateCheckbox = () => {
    switch (design) {
      case "checkbox":
      case "checkbox-check":
      case "checkbox-pill":
      case "checkbox-pill-check":
        return <CheckboxDefault />;
      case "checkbox-full":
        return <CheckboxCustom />;
      default:
        return <></>;
    }
  };

  return (
    <div id="checkbox-component" className={design}>
      <div className="checkbox-title">
        <Interweave content={title} />
      </div>
      <div className="checkbox-content">
        <GenerateCheckbox />
      </div>
    </div>
  );
};

export default CheckboxComponent;
