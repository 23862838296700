import { toast } from "react-toastify";

//redux
import {
  fetchThemeFoldersAPI,
  getThemeAPI,
  renameThemeAPI,
  createNewThemeAPI,
  updateThemeAPI,
  deleteThemeAPI,
  moveThemeAPI,
  updateFolderAPI,
  createNewFolderAPI,
  deleteFolderAPI,
} from "../../store/theme/theme.service";
import { setThemes, setTheme } from "../../store/theme/theme.slice";
import { setLoadingRedux } from "../../store/application/app.slice";

//utils
import { setStorage, getStorage } from "../../utils/Storage";
import { deleteSession, getSession } from "../../utils/Session";
import i18n from "../../utils/i18n";

export const fetchHomeFolders = (background = false) => {
  return (dispatch) => {
    if (!background) {
      dispatch(setLoadingRedux(true));
    }

    fetchThemeFoldersAPI()
      .then(({ data }) => {
        const themesStorage = getStorage("THEMES", true);

        const themeJSON = {
          id: data.id,
          type: data.type,
          wid: data.wid,
          selectedFolder: themesStorage?.selectedFolder || {
            id: "*",
            title: i18n.INIT,
          },
          themeView: "grid",
          themeFolders: data.folders,
          themeFull: data.full,
          breadcrumb: themesStorage?.breadcrumb || [
            { id: "*", title: i18n.INIT },
          ],
          openFolders: themesStorage?.openFolders || [],
        };

        setStorage("THEMES", themeJSON, true);
        dispatch(setThemes(themeJSON));
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
        if (!background) {
          dispatch(setLoadingRedux(false));
        }
      });
  };
};

export const getThemeById = (themeid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getThemeAPI(themeid)
      .then(({ data }) => {
        //AJUSTES ANTES DE ENTREGAR OS DADOS
        data.header.bgColor = data.header?.bgColor || "#ffffff";
        data.header.fixed =
          typeof data.header?.fixed === "boolean" ? data.header?.fixed : true;
        data.header.logoMobile = data.header?.logoMobile || "";
        data.header.shadow =
          typeof data.header?.shadow === "boolean" ? data.header?.shadow : true;
        data.header.topLine = data.header?.topLine || {
          active: false,
          color: "#9a2376",
        };

        data.footer.bgColor = data.footer?.bgColor || "#f5f5f5";
        data.footer.fixed =
          typeof data.footer?.fixed === "boolean" ? data.footer?.fixed : true;
        data.footer.shadow =
          typeof data.footer?.shadow === "boolean" ? data.footer?.shadow : true;
        data.footer.progressBar = data.footer?.progressBar || "disable";
        data.footer.bottomLine = data.footer?.bottomLine || {
          active: false,
          color: "#9a2376",
        };

        data.scripts = data?.scripts || "";
        data.styles = data?.styles || "";

        if (data?.scriptsHead && data.scriptsHead.length > 0) {
          data.scriptsHead = data.scriptsHead.join(",");
          data.scriptsHead = data.scriptsHead.replaceAll("\n", "");
          data.scriptsHead = data.scriptsHead.replaceAll(" ", "");
        } else {
          data.scriptsHead = "";
        }

        if (data?.stylesHead && data.stylesHead.length > 0) {
          data.stylesHead = data.stylesHead.join(",");
          data.stylesHead = data.stylesHead.replaceAll("\n", "");
          data.stylesHead = data.stylesHead.replaceAll(" ", "");
        } else {
          data.stylesHead = "";
        }

        dispatch(setTheme(data));
        dispatch(setLoadingRedux(false));
      })
      .catch(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};

export const renameTheme = (themeId, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    renameThemeAPI(themeId, data)
      .then(() => {
        toast.success(i18n.CHANGE_MADE_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = `/themes`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_MAKE_CHANGE);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateTheme = (themeId, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateThemeAPI(themeId, data)
      .then(() => {
        toast.success(i18n.CHANGE_MADE_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = `/t/${themeId}/builder`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_MAKE_CHANGE);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const copyTheme = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewThemeAPI(data)
      .then(({ data: themeId }) => {
        toast.success(i18n.COPIED_THEME);
        setTimeout(() => {
          window.location.href = `/t/${themeId}/builder`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COPY_THEME);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const createNewTheme = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewThemeAPI(data)
      .then(({ data: themeId }) => {
        toast.success(i18n.CREATED_THEME); //created
        setTimeout(() => {
          window.location.href = `/t/${themeId}/builder`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_CREATE_THEME);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const createNewThemeFolder = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewFolderAPI(data)
      .then(() => {
        toast.success(i18n.FOLDER_CREATED_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = "/themes";
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_CREATE_FOLDER);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const deleteTheme = (themeId) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    deleteThemeAPI(themeId)
      .then(() => {
        toast.success(i18n.DELETED_THEME);
        setTimeout(() => {
          window.location.href = `/themes`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_DELETE_THEME);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateThemeFolder = (folderid, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateFolderAPI(folderid, data)
      .then(() => {
        toast.success(i18n.CHANGE_MADE_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = `/themes`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_MAKE_CHANGE);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const deleteThemeFolder = (folderid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    deleteFolderAPI(folderid)
      .then(() => {
        toast.success(i18n.DELETED_FOLDER);
        setTimeout(() => {
          window.location.href = `/themes`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_DELETE_FOLDER);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const hanfleDnDTheme = () => {
  return (dispatch) => {
    const d = getSession("DRAGGIND_ID", true);
    const t = getSession("TARGET_ID", true);

    if (
      typeof d === "object" &&
      typeof t === "object" &&
      d !== "" &&
      t !== ""
    ) {
      const surveysStorage = getStorage("THEMES", true);

      // FOLDER => FOLDER
      if (d.type === "f" && t.type === "f") {
        //FOLDER ID === FOLDER ID
        if (d.draggingId === t.targetId) {
          toast.warning(i18n.CANNOT_MOVE_FOLDER_OWN_FOLDER);
          return;
        }

        //FOLDER AT SAME LOCAL
        if (t.targetId === surveysStorage.selectedFolder.id) {
          toast.warning(i18n.THEME_MSG4);
          return;
        }
      }

      // THEME => FOLDER
      if (d.type === "t" && t.type === "f") {
        //THEME AT SAME LOCAL
        if (t.targetId === surveysStorage.selectedFolder.id) {
          toast.warning(i18n.THEME_MSG3);
          return;
        }
      }

      dispatch(setLoadingRedux(true));
      moveThemeAPI({
        dragged: {
          //filho
          id: d.draggingId,
          type: d.type, // [f|s]
        },
        target: {
          //pai
          id: t.targetId,
          folderThemeId: surveysStorage.id,
        },
      })
        .then(() => {
          toast.success(
            `${
              d.type === "f"
                ? i18n.FOLDER_MOVED_SUCCESSFULLY
                : i18n.THEME_MOVED_SUCCESSFULLY //muda para tema
            }`
          );
          setTimeout(() => {
            window.location.href = "/themes";
          }, 2000);
        })
        .catch(() => {
          dispatch(setLoadingRedux(false));
          toast.error(i18n.FAIL_MOVE_ITEM);
        });
    }

    deleteSession("DRAGGIND_ID");
    deleteSession("TARGET_ID");
  };
};
