import LoginPage from "../../pages/auth/login";
import LogoutPage from "../../pages/auth/logout";
import GAuthPage from "../../pages/auth/google-auth";

//AUTH
export const authRoutes = () => {
  return [
    { path: "/login", page: LoginPage },
    { path: "/logout", page: LogoutPage, auth: true },
    { path: "/google-auth", page: GAuthPage },
  ];
};
