import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";

//providers
import { AuthProvider } from "./providers/AuthContext";
import { Provider } from "react-redux";

//redux
import store from "./store";

//routes
import AppRoutes from "./routes";

//styles
import "./assets/styles/global.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#9a2376",
            },
          }}
        >
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
