import React from "react";
import { Interweave } from "interweave";

//utils
import i18n from "../../../../utils/i18n";

//styles
import "../../../../assets/styles/builder/ces.css";

const CESComponent = (props) => {
  const { data, language } = props.data;

  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  const CES = () => (
    <>
      {data.options &&
        data.options.map((opt, i) => {
          return (
            <div
              key={`ces-options-${data.id}-${i}`}
              className={`ces-item ${`option-color-${opt.value}`}`}
            >
              {opt.label[language]}
            </div>
          );
        })}
      {data.properties && data.properties.naOption && (
        <div
          key={`ces-options-0`}
          className={`ces-item ${`option-color-0`}`}
          alt={"na"}
        >
          {i18n.NO_APPLY}
        </div>
      )}
    </>
  );

  const CESEmoticon = () => {};

  const CESEffort = () => (
    <div className="ces-effort-box">
      {data.options &&
        data.options.map((num, i) => {
          return (
            <div
              key={`ces-options-${data.id}-${i}`}
              className={`ces-item ${`ces-color-${num}`}`}
            >
              <div className="number">{num}</div>
            </div>
          );
        })}
      <div className="ces-effort-text">
        <span className="left">Muito Difícil</span>
        <span className="right">Muito Fácil</span>
      </div>
    </div>
  );

  let design = "ces";
  if (data.properties && data.properties.design) {
    design =
      {
        emoticon: "ces-emoticon",
        effort: "ces-effort",
      }[data.properties.design] || "ces";
  }

  const GenerateCES = () => {
    switch (design) {
      case "ces":
        return <CES />;
      case "ces-emoticon":
        return <CESEmoticon />;
      case "ces-effort":
        return <CESEffort />;
      default:
        return <></>;
    }
  };

  return (
    <div id="ces-component" className={design}>
      <div className="ces-title">
        <Interweave content={title} />
      </div>
      <div className="ces-content">
        <GenerateCES />
      </div>
    </div>
  );
};

export default CESComponent;
