import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/plain-text.css";

const PlainTextComponent = (props) => {
  const { data, language } = props.data;

  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="plain-text-component">
      <div className="plain-text-content">
        {data.title && data.title !== "" && <Interweave content={title} />}
      </div>
    </div>
  );
};

export default PlainTextComponent;
