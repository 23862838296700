import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/radiobox.css";

const RadioboxComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  let design = "radio";
  if (data.properties && data.properties.design) {
    design =
      {
        check: "radio-check",
        pill: "radio-pill",
        "pill-check": "radio-pill-check",
        full: "radio-full",
      }[data.properties.design] || "radio";
  }

  const RadioboxDefault = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <div
                key={`radiobox-option-${data.id}-${i}`}
                className="radiobox-option"
              >
                <input
                  type="radio"
                  id={`radiobox-${data.id}-${i}`}
                  name={`radiobox-${data.id}-${i}`}
                  className="radiobox-item"
                  value={item.value}
                  disabled
                  onChange={() => {}}
                />
                <label htmlFor={`radiobox-${data.id}-${i}`}>
                  {item.label[language]}
                </label>
              </div>
            );
          })}
      </>
    );
  };

  const RadioboxCustom = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <React.Fragment key={`radiobox-option-${data.id}-${i}`}>
                <div className={`radiobox-option`}>
                  <span>{item.label[language]}</span>
                </div>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const GenerateRadiobox = () => {
    switch (design) {
      case "radio":
      case "radio-check":
      case "radio-pill":
      case "radio-pill-check":
        return <RadioboxDefault />;
      case "radio-full":
        return <RadioboxCustom />;
      default:
        return <></>;
    }
  };

  return (
    <div id="radiobox-component" className={design}>
      <div className="radiobox-title">
        <Interweave content={title} />{" "}
      </div>
      <div className="radiobox-content">
        <GenerateRadiobox />
      </div>
    </div>
  );
};

export default RadioboxComponent;
