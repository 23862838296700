import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Interweave } from "interweave";
import { toast } from "react-toastify";

//antd
import {
  Input,
  Button,
  Radio,
  Tooltip,
  Modal,
  Form,
  Flex,
  FloatButton,
  Select,
  Popover,
  List,
} from "antd";
import {
  FolderAddFilled,
  FolderOutlined,
  FolderOpenOutlined,
  FileAddFilled,
  UnorderedListOutlined,
  TableOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  CaretRightOutlined,
  LeftCircleOutlined,
  MoreOutlined,
  CloseOutlined,
  DeleteOutlined,
  SwapOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  FontColorsOutlined,
  CheckCircleOutlined,
  LinkOutlined,
  HomeOutlined,
} from "@ant-design/icons";

//components
import EmptyBox from "../../../components/ui/EmptyBox";
import TreeFolders from "../../../components/app/TreeFolders";

//redux
import {
  loadingRedux,
  setLoadingRedux,
  windowWidthRedux,
} from "../../../store/application/app.slice";
import { setUpdate, surveys, update } from "../../../store/general/home.slice";
import {
  fetchHomeSurveysFolders,
  createNewFolder,
  createNewSurvey,
  copySurvey,
  deleteSurvey,
  updateSurvey,
  hanfleDnD,
  deleteFolder,
  updateFolder,
} from "../../../store/general/home.action";

//utils
import i18n from "../../../utils/i18n";
import { getStorage, setStorage } from "../../../utils/Storage";
import { setSession, deleteSession } from "../../../utils/Session";

//styles
import "../../../assets/styles/general/home.css";

const HomePage = () => {
  const dispatch = useDispatch();
  const lang = getStorage("LANG");
  const hostname = window.location.hostname;
  const baseURL =
    hostname.indexOf("localhost") >= 0
      ? "https://devsurvey.inovyo.com"
      : "https://devsurvey.inovyo.com";

  const [menuOpen, setMenuOpen] = useState(true);
  const [showModalCreateFolder, setShowModalCreateFolder] = useState(false);
  const [showModalCreateSurvey, setShowModalCreateSurvey] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [display, setDisplay] = useState("grid");
  const [originalSurveys, setOriginalSurveys] = useState(null);
  const [currentSurveys, setCurrentSurveys] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([{ id: "*", title: i18n.INIT }]);
  const [folderName, setFolderName] = useState("");
  const [surveyName, setSurveyName] = useState("");
  const [appLanguage, setAppLanguage] = useState(lang !== "" ? lang : "pt-br");
  const [searchValue, setSearchValue] = useState("");
  const [animate, setAnimate] = useState("");

  //redux vars
  const loading = useSelector(loadingRedux);
  const listSurveys = useSelector(surveys);
  const updateX = useSelector(update);
  const ww = useSelector(windowWidthRedux);

  useEffect(() => {
    dispatch(fetchHomeSurveysFolders());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (listSurveys !== null) {
      let surveysStorage = getStorage("SURVEYS", true);

      if (ww < 768) {
        surveysStorage.surveyView = "list";
        setMenuOpen(false);
      } else {
        setMenuOpen(ww > 1080);
        surveysStorage.surveyView = ww > 768 ? "grid" : "list";
      }

      setOriginalSurveys(surveysStorage.surveyFull);
      setBreadcrumb(surveysStorage.breadcrumb);
      setDisplay(surveysStorage.surveyView);

      setTimeout(() => {
        extractCurrentView();
      }, 300);

      setTimeout(() => {
        dispatch(setLoadingRedux(false));
      }, 300);
    }

    //eslint-disable-next-line
  }, [listSurveys]);

  useEffect(() => {
    if (listSurveys !== null) {
      const surveysStorage = getStorage("SURVEYS", true);
      setBreadcrumb(surveysStorage.breadcrumb);
      setTimeout(() => extractCurrentView(), 300);
    }
    //eslint-disable-next-line
  }, [listSurveys, updateX]);

  useEffect(() => {
    if (originalSurveys !== null) {
      extractCurrentView();
      dispatch(setUpdate());
    }

    // eslint-disable-next-line
  }, [originalSurveys]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate("");
      const surveysStorage = getStorage("SURVEYS", true);
      if (ww < 768) {
        setDisplay("list");
        setStorage("SURVEYS", { ...surveysStorage, surveyView: "list" }, true);
        setMenuOpen(false);
      } else {
        let homeView = "";
        homeView = ww > 768 && ww < 1280 ? surveysStorage.surveyView : "grid";
        setMenuOpen(ww > 1080);
        setDisplay(homeView);
        setStorage(
          "SURVEYS",
          { ...surveysStorage, surveyView: homeView },
          true
        );
      }
    }, 10);
    // eslint-disable-next-line
  }, [ww]);

  const searchInput = (val) => {
    setSearchValue(val);
    dispatch(setUpdate());
  };

  const changeView = (value) => {
    setDisplay(value);
    let surveysStorage = getStorage("SURVEYS", true);
    setStorage(
      "SURVEYS",
      {
        ...surveysStorage,
        surveyView: value,
      },
      true
    );
  };

  const extractCurrentView = () => {
    const surveysStorage = getStorage("SURVEYS", true);
    let extractData = [];

    if (searchValue !== "") {
      originalSurveys !== null &&
        originalSurveys.forEach((item) => {
          if (
            item.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          ) {
            extractData.push(item);
          }
        });
    } else if (surveysStorage.selectedFolder.id === "full") {
      originalSurveys !== null &&
        originalSurveys.forEach((item) => {
          if (item.type === "s") {
            extractData.push(item);
          }
        });
    } else {
      originalSurveys !== null &&
        originalSurveys.forEach((item) => {
          if (item.folderId === surveysStorage.selectedFolder.id) {
            extractData.push(item);
          }
        });
    }

    setCurrentSurveys(extractData.length > 0 ? extractData : null);
  };

  const Breadcrumb = () => {
    const biggerOrEqual4 = breadcrumb.length >= 4;

    const handleBackFolder = () => {
      const surveysStorage = getStorage("SURVEYS", true);
      let breadcrumbCopy = [...breadcrumb];
      breadcrumbCopy.pop();
      setBreadcrumb(breadcrumbCopy);
      surveysStorage.breadcrumb = breadcrumbCopy;
      surveysStorage.selectedFolder = breadcrumbCopy[breadcrumbCopy.length - 1];
      setStorage("SURVEYS", surveysStorage, true);
      dispatch(setUpdate());
    };

    const findInBreadcrumb = (item) => {
      let breadcrumbCopy = [...breadcrumb];
      let breadcrumbRenewed = [];
      let finded = false;

      breadcrumbCopy.forEach((el) => {
        if (!finded) {
          breadcrumbRenewed.push(el);

          if (el.id === item.id) {
            finded = true;
          }
        }
      });

      return breadcrumbRenewed;
    };

    const handleOptionFolder = (item) => {
      const surveysStorage = getStorage("SURVEYS", true);
      let newBreadcrumb = findInBreadcrumb(item);
      setBreadcrumb(newBreadcrumb);
      surveysStorage.breadcrumb = newBreadcrumb;
      surveysStorage.selectedFolder = item;
      setStorage("SURVEYS", surveysStorage, true);
      dispatch(setUpdate());
    };

    const backFolder =
      breadcrumb.length > 1 ? (
        <div
          className="i-breadcrumb-item-touch back-folder"
          onClick={() => handleBackFolder()}
        >
          <LeftCircleOutlined />
        </div>
      ) : (
        <></>
      );

    const miniMenu = () => {
      let miniBread = [];

      breadcrumb.forEach((b, i) => {
        if (breadcrumb.length - 2 !== i && breadcrumb.length - 1 !== i) {
          miniBread.push(b);
        }
      });

      return miniBread.map((mb, j) => {
        return (
          <div
            className="mini-menu-item"
            key={`mini-menu-item-${j}`}
            onClick={() => handleOptionFolder(mb)}
          >
            {mb.title}
          </div>
        );
      });
    };

    return (
      <div className="i-breadcrumb">
        {backFolder}
        {biggerOrEqual4 ? (
          <>
            <Popover
              id="mini-menu-breadcrumb"
              content={<>{miniMenu()}</>}
              placement="bottom"
              trigger="click"
              style={{}}
            >
              <div className="i-breadcrumb-item-touch">...</div>
            </Popover>
            <div className="i-breadcrumb-item">
              <CaretRightOutlined style={{ fontSize: 10 }} />
            </div>
            <div
              className="i-breadcrumb-item-touch"
              onClick={() =>
                handleOptionFolder(breadcrumb[breadcrumb.length - 2])
              }
            >
              {breadcrumb[breadcrumb.length - 2]["title"]}
            </div>
            <div className="i-breadcrumb-item">
              <CaretRightOutlined style={{ fontSize: 10 }} />
            </div>
            <div
              className="i-breadcrumb-item-touch"
              onClick={() =>
                handleOptionFolder(breadcrumb[breadcrumb.length - 1])
              }
            >
              {breadcrumb[breadcrumb.length - 1]["title"]}
            </div>
          </>
        ) : (
          <>
            {breadcrumb.map((b, i) => {
              b.title = b.id === "*" ? i18n.INIT : b.title;
              let clickable = breadcrumb.length !== i + 1;

              const hasArroy = clickable ? (
                <div className="i-breadcrumb-item">
                  <CaretRightOutlined style={{ fontSize: 10 }} />
                </div>
              ) : (
                <></>
              );

              return (
                <React.Fragment key={"i-breadcrumb-item" + i}>
                  <div
                    className={
                      clickable
                        ? "i-breadcrumb-item-touch"
                        : "i-breadcrumb-item"
                    }
                    onClick={() => {
                      if (clickable) handleOptionFolder(b);
                    }}
                  >
                    {b.title}
                  </div>
                  {hasArroy}
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const enterFolder = (item) => {
    const surveysStorage = getStorage("SURVEYS", true);
    let breadcrumbCopy = surveysStorage.breadcrumb;
    breadcrumbCopy.push(item);
    surveysStorage.breadcrumb = breadcrumbCopy;
    surveysStorage.selectedFolder = item;
    setStorage("SURVEYS", surveysStorage, true);
    setBreadcrumb(breadcrumbCopy);
    dispatch(setUpdate());
  };

  const handleCreateFolder = () => {
    const surveysStorage = getStorage("SURVEYS", true);

    dispatch(
      createNewFolder({
        id: surveysStorage.id,
        parent_id: surveysStorage.selectedFolder.id,
        name: folderName,
      })
    );
    setTimeout(() => setFolderName(""), 1000);
  };

  const handleCreateSurvey = () => {
    const surveysStorage = getStorage("SURVEYS", true);

    dispatch(
      createNewSurvey({
        language: appLanguage,
        folder_id: surveysStorage.selectedFolder.id,
        name: surveyName,
        status: "active",
      })
    );
    setTimeout(() => setSurveyName(""), 1000);
  };

  const PopoverOptionFolder = ({ folderData }) => {
    const { title, id: fId } = folderData;

    const [showModalMoveFolder, setShowModalMoveFolder] = useState(false);
    const [showModalRenameFolder, setShowModalRenameFolder] = useState(false);
    const [showModalDeleteFolder, setShowModalDeleteFolder] = useState(false);
    const [showMiniMenu, setMiniMenu] = useState(false);
    const [titleRenamed, setTitleRenamed] = useState(title);
    const [chosenFolder, setChosenFolder] = useState(0);
    const [previewFolders, setPreviewFolders] = useState([]);

    const handleRename = () => {
      let surveysStorage = getStorage("SURVEYS", true);
      dispatch(
        updateFolder(fId, { name: titleRenamed, id: surveysStorage.id })
      );
    };

    const handleDeleteFolder = () => dispatch(deleteFolder(fId));

    const handleMoveFolder = () => {
      if (chosenFolder === 0) {
        toast.warning(i18n.MOVE_FOLDER_MSG2);
        return;
      }

      setPreviewFolders([]);
      setChosenFolder(0);
      setShowModalMoveFolder(false);
    };

    const TreeFoldersMove = () => {
      const surveysStorage = getStorage("SURVEYS", true);

      const showFolder = (fData) => {
        let previewFoldersCopy = [...previewFolders];

        if (previewFoldersCopy.includes(fData.key)) {
          previewFoldersCopy.splice(previewFoldersCopy.indexOf(fData.key), 1);
        } else {
          previewFoldersCopy.push(fData.key);
        }
        setPreviewFolders(previewFoldersCopy);
      };

      const clickFolder = (className, fData) => {
        const classString = typeof className === "string" ? className : "";

        if (
          classString.indexOf("name") >= 0 ||
          classString.indexOf("tree-list-item") >= 0
        ) {
          //mover para própria pasta
          if (fData.key === fId) {
            toast.warning(i18n.CANNOT_MOVE_FOLDER_OWN_FOLDER);
          } else if (surveysStorage.selectedFolder.id === fData.key) {
            //Sua pasta já está neste local
            toast.warning(i18n.MOVE_FOLDER_MSG1);
          } else {
            setChosenFolder(fData.key);
          }
        }
      };

      const MountLevels = ({ children, lvl, hierarchy }) => {
        return children.map((f, i) => {
          return (
            <React.Fragment key={`tree-list-item` + i}>
              <div
                className={`tree-list-item ${
                  chosenFolder === f.key ? "checked" : ""
                }`}
                onClick={({ target: { className } }) =>
                  clickFolder(className, f)
                }
              >
                <div className="icon" onClick={() => showFolder(f)}>
                  {previewFolders.includes(f.key) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </div>
                <div className="name">{f.title}</div>
              </div>
              <div
                className={`lvl-${lvl} ${
                  previewFolders.includes(f.key) ? "open" : "close"
                }`}
              >
                {f.children.length > 0 && (
                  <MountLevels
                    children={f.children}
                    lvl={lvl + 1}
                    hierarchy={[
                      ...hierarchy,
                      {
                        id: f.key,
                        title: f.title,
                        type: "f",
                      },
                    ]}
                  />
                )}
              </div>
            </React.Fragment>
          );
        });
      };

      return (
        <div id="tree-move-survey">
          <div
            className={`tree-list-item ${
              chosenFolder === "*" ? "checked" : ""
            }`}
            onClick={({ target: { className } }) =>
              clickFolder(className, {
                key: "*",
                title: i18n.INIT,
                type: "f",
              })
            }
          >
            <div className="icon">
              <HomeOutlined />
            </div>
            <div className="name">{i18n.INIT}</div>
          </div>
          <MountLevels
            children={surveysStorage.surveyFolders}
            lvl={1}
            hierarchy={[
              {
                id: "*",
                title: i18n.HOME,
                type: "f",
              },
            ]}
          />
        </div>
      );
    };

    return (
      <>
        <div
          className="more-options"
          onClick={() => setMiniMenu(!showMiniMenu)}
        >
          <MoreOutlined />
        </div>
        <Modal
          closeIcon={null}
          open={showMiniMenu}
          footer={null}
          onCancel={() => setMiniMenu(false)}
          styles={{ content: { padding: 0 } }}
          width={"200px"}
        >
          <List
            size="small"
            bordered
            dataSource={[
              {
                label: (
                  <>
                    <FontColorsOutlined /> &nbsp;{i18n.RENAME}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalRenameFolder(true);
                },
              },
              {
                label: (
                  <>
                    <SwapOutlined /> &nbsp;{i18n.MOVE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalMoveFolder(true);
                },
              },
              {
                label: (
                  <>
                    <DeleteOutlined /> &nbsp;{i18n.DELETE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDeleteFolder(true);
                },
              },
            ]}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer", justifyContent: "flex-start" }}
                onClick={() => item.fn()}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          open={showModalDeleteFolder}
          onCancel={() => setShowModalDeleteFolder(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDeleteFolder()}
          title={i18n.CAUTION.toUpperCase() + "!"}
        >
          <Interweave content={i18n.MESSAGE_DELETE_FOLDER} />
        </Modal>
        <Modal
          open={showModalMoveFolder}
          onCancel={() => {
            setPreviewFolders([]);
            setChosenFolder(0);
            setShowModalMoveFolder(false);
          }}
          onOk={() => handleMoveFolder()}
          cancelText={i18n.CANCEL}
          title="Mover pasta para"
        >
          <TreeFoldersMove />
        </Modal>
        <Modal
          open={showModalRenameFolder}
          onCancel={() => {
            setShowModalRenameFolder(false);
            setTitleRenamed(title);
          }}
          cancelText={i18n.CANCEL}
          onOk={() => {
            handleRename();
          }}
          title="Renomear pasta"
        >
          <Input
            value={titleRenamed}
            onChange={({ target: { value } }) => setTitleRenamed(value)}
          />
        </Modal>
      </>
    );
  };

  const PopoverOptionSurvey = ({ surveyData }) => {
    const { title, id: sId, status } = surveyData;

    const [showModalMoveSurvey, setShowModalMoveSurvey] = useState(false);
    const [showModalRenameSurvey, setShowModalRenameSurvey] = useState(false);
    const [showModalDuplicate, setShowModalDuplicate] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);
    const [showModalReopen, setShowModalReopen] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showMiniMenu, setMiniMenu] = useState(false);
    const [titleRenamed, setTitleRenamed] = useState(title);
    const [chosenFolder, setChosenFolder] = useState(0);
    const [previewFolders, setPreviewFolders] = useState([]);

    const handleRename = () =>
      dispatch(updateSurvey(sId, { name: titleRenamed }));

    const handleInactiveSurvey = () =>
      dispatch(updateSurvey(sId, { status: "inactive" }));

    const handleActiveSurvey = () =>
      dispatch(updateSurvey(sId, { status: "active" }));

    const handleDeleteSurvey = () => dispatch(deleteSurvey(sId));

    const handleDuplicateSurvey = (surveyCopyID) =>
      dispatch(copySurvey({ copySurvey: surveyCopyID }));

    const handleMoveSurvey = () => {
      if (chosenFolder === 0) {
        toast.warning(i18n.MOVE_FOLDER_MSG2);
        return;
      }

      setPreviewFolders([]);
      setChosenFolder(0);
      setShowModalMoveSurvey(false);
    };

    const TreeFoldersMove = () => {
      const surveysStorage = getStorage("SURVEYS", true);

      const showFolder = (fData) => {
        let previewFoldersCopy = [...previewFolders];

        if (previewFoldersCopy.includes(fData.key)) {
          previewFoldersCopy.splice(previewFoldersCopy.indexOf(fData.key), 1);
        } else {
          previewFoldersCopy.push(fData.key);
        }
        setPreviewFolders(previewFoldersCopy);
      };

      const clickFolder = (className, fData) => {
        const classString = typeof className === "string" ? className : "";

        if (
          classString.indexOf("name") >= 0 ||
          classString.indexOf("tree-list-item") >= 0
        ) {
          if (fData.key !== surveysStorage.selectedFolder.id) {
            setChosenFolder(fData.key);
          } else {
            toast.warning(i18n.MOVE_SURVEY_MSG1);
          }
        }
      };

      const MountLevels = ({ children, lvl, hierarchy }) => {
        return children.map((f, i) => {
          return (
            <React.Fragment key={`tree-list-item` + i}>
              <div
                className={`tree-list-item ${
                  chosenFolder === f.key ? "checked" : ""
                }`}
                onClick={({ target: { className } }) =>
                  clickFolder(className, f)
                }
              >
                <div className="icon" onClick={() => showFolder(f)}>
                  {previewFolders.includes(f.key) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </div>
                <div className="name">{f.title}</div>
              </div>
              <div
                className={`lvl-${lvl} ${
                  previewFolders.includes(f.key) ? "open" : "close"
                }`}
              >
                {f.children.length > 0 && (
                  <MountLevels
                    children={f.children}
                    lvl={lvl + 1}
                    hierarchy={[
                      ...hierarchy,
                      {
                        id: f.key,
                        title: f.title,
                        type: "f",
                      },
                    ]}
                  />
                )}
              </div>
            </React.Fragment>
          );
        });
      };

      return (
        <div id="tree-move-survey">
          <div
            className={`tree-list-item ${
              chosenFolder === "*" ? "checked" : ""
            }`}
            onClick={({ target: { className } }) =>
              clickFolder(className, {
                key: "*",
                title: i18n.INIT,
                type: "f",
              })
            }
          >
            <div className="icon">
              <HomeOutlined />
            </div>
            <div className="name">{i18n.INIT}</div>
          </div>

          <MountLevels
            children={surveysStorage.surveyFolders}
            lvl={1}
            hierarchy={[
              {
                id: "*",
                title: i18n.HOME,
                type: "f",
              },
            ]}
          />
        </div>
      );
    };

    return (
      <>
        <div
          className="more-options"
          onClick={() => setMiniMenu(!showMiniMenu)}
        >
          <MoreOutlined />
        </div>
        <Modal
          closeIcon={null}
          open={showMiniMenu}
          footer={null}
          onCancel={() => setMiniMenu(false)}
          styles={{ content: { padding: 0 } }}
          width={"200px"}
        >
          <List
            size="small"
            bordered
            dataSource={[
              {
                label: (
                  <>
                    <LinkOutlined /> &nbsp;{i18n.COPY_LINK}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  navigator.clipboard.writeText(`${baseURL}/s/${sId}/builder`);
                  toast.success(i18n.COPIED_LINK);
                },
              },
              {
                label: (
                  <>
                    <CopyOutlined /> &nbsp;{i18n.DUPLICATE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDuplicate(true);
                },
              },
              {
                label: (
                  <>
                    <FontColorsOutlined /> &nbsp;{i18n.RENAME}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalRenameSurvey(!showModalRenameSurvey);
                },
              },
              {
                label: (
                  <>
                    <SwapOutlined /> &nbsp;{i18n.MOVE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalMoveSurvey(true);
                },
              },
              {
                label: (
                  <>
                    {status === "active" ? (
                      <>
                        <CloseCircleOutlined />
                        &nbsp; {i18n.CLOSE}
                      </>
                    ) : (
                      <>
                        <CheckCircleOutlined /> &nbsp;{i18n.REOPEN}
                      </>
                    )}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  if (status === "active") {
                    setShowModalClose(true);
                  } else {
                    setShowModalReopen(true);
                  }
                },
              },
              {
                label: (
                  <>
                    <DeleteOutlined /> &nbsp;{i18n.DELETE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDelete(true);
                },
              },
            ]}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer", justifyContent: "flex-start" }}
                onClick={() => item.fn()}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Modal>

        <Modal
          open={showModalDuplicate}
          onCancel={() => setShowModalDuplicate(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDuplicateSurvey(sId)}
          title={i18n.DUPLICATE.toUpperCase() + "!"}
        >
          Deseja fazer uma cópia desta pesquisa?
          <br />
          <h3>{title}</h3>
        </Modal>

        <Modal
          open={showModalClose}
          onCancel={() => setShowModalClose(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleInactiveSurvey()}
          title={i18n.CLOSE.toUpperCase() + "!"}
        >
          Deseja realmente fechar está pesquisa? Ao realizar esta ação, a
          pesquisa não poderá mais ser respondida.
        </Modal>

        <Modal
          open={showModalReopen}
          onCancel={() => setShowModalReopen(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleActiveSurvey()}
          title={i18n.REOPEN.toUpperCase() + "!"}
        >
          Deseja realmente reativar está pesquisa? A mesma voltará a ser
          respondida normalmente.
        </Modal>

        <Modal
          open={showModalDelete}
          onCancel={() => setShowModalDelete(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDeleteSurvey()}
          title={i18n.CAUTION.toUpperCase() + "!"}
        >
          <Interweave content={i18n.MESSAGE_DELETE_SURVEY} />
        </Modal>

        <Modal
          open={showModalMoveSurvey}
          onCancel={() => {
            setPreviewFolders([]);
            setChosenFolder(0);
            setShowModalMoveSurvey(false);
          }}
          onOk={() => handleMoveSurvey()}
          title="Mover pesquisa para"
        >
          <TreeFoldersMove />
        </Modal>
        <Modal
          open={showModalRenameSurvey}
          onCancel={() => setShowModalRenameSurvey(false)}
          cancelText={i18n.CANCEL}
          onOk={() => {
            handleRename();
          }}
          title="Renomear pesquisa"
        >
          <Input
            value={titleRenamed}
            onChange={({ target: { value } }) => setTitleRenamed(value)}
          />
        </Modal>
      </>
    );
  };

  return (
    <>
      <div id="home-page">
        <aside
          className={`aside-menu ${animate}`}
          style={{ left: menuOpen ? 0 : "-300px" }}
        >
          <TreeFolders />
          <Button
            className={`aside-menu-retract`}
            type="text"
            icon={menuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => {
              setAnimate(!menuOpen ? "close-animation" : "open-animation");
              setMenuOpen(!menuOpen);
            }}
          />
        </aside>
        <article
          className={`article-content ${ww > 1080 ? animate : ""}`}
          style={{
            width: ww > 1080 && menuOpen ? "calc(100% - 300px)" : "100%",
          }}
        >
          <div className="box-enviroment-title">{i18n.SURVEYS}</div>
          <div className="box-enviroment-bar">
            <div className="box-align-horizontal">
              <div className="input-box">
                <Input
                  className="search-input"
                  placeholder={i18n.SEARCH_BAR_PLACEHOLDER}
                  onChange={({ target: { value } }) => searchInput(value)}
                  value={searchValue}
                />
                {searchValue.length > 0 && (
                  <div
                    className="close-search-input"
                    onClick={() => searchInput("")}
                  >
                    <CloseOutlined />
                  </div>
                )}
              </div>
              <div>
                <Radio.Group
                  className="display-view"
                  onChange={({ target: { value } }) => changeView(value)}
                  value={display}
                  size="medium"
                >
                  <Tooltip title={i18n.GRID_VIEW_MODE}>
                    <Radio.Button value="grid">
                      <TableOutlined />
                    </Radio.Button>
                  </Tooltip>
                  <Tooltip title={i18n.LIST_VIEW_MODE}>
                    <Radio.Button value="list">
                      <UnorderedListOutlined />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                gap: "5px",
              }}
            ></div>
            {originalSurveys !== null && <Breadcrumb />}
          </div>
          <div className="box-enviroments-content">
            <div className={`box-enviroments-content-itens ${display}`}>
              {currentSurveys !== null &&
                currentSurveys.map((item, index) => (
                  <div
                    className={`explorer-item ${
                      item.type === "s" ? "survey" : "folder"
                    } ${item.status === "active" ? "active" : "inactive"}`}
                    key={`explorer-item-${index}`}
                    onDragStart={(e) => {
                      e.target.classList.add("dragging");
                      setSession(
                        "DRAGGIND_ID",
                        { draggingId: item.id, type: item.type },
                        true
                      );
                    }}
                    onDragEnd={(e) => {
                      e.target.classList.remove("dragging");
                      let overred = document.querySelector(
                        "div.tree-list-item > div.name.dragover"
                      );

                      if (overred) {
                        overred.classList.remove("dragover");
                      }

                      dispatch(hanfleDnD());
                    }}
                    onDragLeave={(e) => {
                      if (item.type === "f") {
                        e.target.classList.remove("dragover");
                        setTimeout(() => {
                          deleteSession(
                            "TARGET_ID",
                            { targetId: item.id, type: "f" },
                            true
                          );
                        }, 10);
                      }
                    }}
                    onDragEnter={() => {
                      if (item.type === "f") {
                        setTimeout(() => {
                          setSession(
                            "TARGET_ID",
                            { targetId: item.id, type: "f" },
                            true
                          );
                        }, 15);
                      }
                    }}
                    onDragOver={(e) => {
                      if (item.type === "f") {
                        e.preventDefault();
                        e.target.classList.add("dragover");
                      }
                    }}
                    draggable={true}
                  >
                    {item.type === "s" ? (
                      <>
                        <PopoverOptionSurvey surveyData={item} />
                        {item.status === "active" ? (
                          <div className="s-status-1">{i18n.ACTIVE_LABEL}</div>
                        ) : (
                          <div className="s-status-0">
                            {i18n.INACTIVE_LABEL}
                          </div>
                        )}

                        <a href={`/s/${item.id}/builder`} draggable={false}>
                          {item.title}
                        </a>

                        <ul>
                          <li>
                            <a href={`/s/${item.id}/builder`}>{i18n.BUILDER}</a>
                          </li>
                          <li>
                            <a href={`/s/${item.id}/share`}>{i18n.SHARE}</a>
                          </li>
                          <li>
                            <a href={`/s/${item.id}/general`}>{i18n.GENERAL}</a>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <div
                          className="description"
                          onClick={() => {
                            if (item.type === "f") enterFolder(item);
                          }}
                        >
                          {item.title}
                        </div>
                        <PopoverOptionFolder folderData={item} />
                      </>
                    )}
                  </div>
                ))}

              {currentSurveys === null && !loading && (
                <EmptyBox type="empty-folder" />
              )}
            </div>
          </div>
        </article>
      </div>

      <Modal
        title={i18n.NEW_FOLDER}
        open={showModalCreateFolder}
        cancelText={i18n.CANCEL}
        okText={i18n.CREATE}
        onCancel={() => {
          setShowModalCreateFolder(false);
          setFolderName("");
        }}
        onOk={() => handleCreateFolder()}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.NAME} htmlFor="folder-name">
            <Input
              id="folder-name"
              placeholder={i18n.FOLDER_NAME}
              onChange={({ target: { value } }) => setFolderName(value)}
              value={folderName}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={i18n.NEW_SURVEY}
        open={showModalCreateSurvey}
        onCancel={() => {
          setShowModalCreateSurvey(false);
          setSurveyName("");
        }}
        footer={null}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.TITLE} htmlFor="survey-name">
            <Input
              id="survey-name"
              placeholder="Título da pesquisa"
              onChange={({ target: { value } }) => setSurveyName(value)}
              value={surveyName}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label={i18n.LANGUAGE} htmlFor="survey-language">
            <Select
              id="survey-language"
              placeholder="Idioma da pesquisa"
              defaultValue={appLanguage}
              options={i18n.LANGUAGES}
              onChange={({ target: { value } }) => setAppLanguage(value)}
            />
          </Form.Item>
          <Flex justify="flex-end" align="flex-end" gap={4}>
            <Button
              onClick={() => {
                setShowModalCreateSurvey(false);
                setSurveyName("");
              }}
            >
              {i18n.CANCEL}
            </Button>
            <Button type="primary" onClick={() => handleCreateSurvey()}>
              {i18n.CREATE}
            </Button>
          </Flex>
        </Form>
      </Modal>
      <Modal
        title="Opções da Pasta"
        open={showModal3}
        okText="Criar"
        cancelText="Cancelar"
        onCancel={() => setShowModal3(false)}
        onOK={() => handleCreateSurvey()}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        Opções da pasta
      </Modal>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{
          right: 24,
        }}
        icon={<PlusOutlined />}
      >
        <FloatButton
          icon={<FolderAddFilled />}
          tooltip={i18n.CREATE_NEW_FOLDER}
          onClick={() => setShowModalCreateFolder(!showModalCreateFolder)}
        />
        <FloatButton
          icon={<FileAddFilled />}
          tooltip={i18n.CREATE_NEW_SURVEY}
          onClick={() => setShowModalCreateSurvey(!showModalCreateSurvey)}
        />
      </FloatButton.Group>
    </>
  );
};

export default HomePage;
