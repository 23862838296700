import i18n_ptBR from "./pt-BR";
import i18n_enUS from "./en-US";

import { getStorage } from "../Storage";

const i18n = (() => {
  const langObj = {
    "pt-br": i18n_ptBR,
    "en-us": i18n_enUS,
  };
  const lang = langObj?.[getStorage("LANG")] || i18n_ptBR;
  return lang;
})();

export default i18n;
