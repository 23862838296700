import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/auto-complete.css";

const AutoCompleteComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="auto-complete-component" className="auto-complete">
      <div className="auto-complete-title">
        <Interweave content={title} />{" "}
      </div>
      <div className="auto-complete-content">
        <input type="text" disabled />
      </div>
    </div>
  );
};

export default AutoCompleteComponent;
