import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Interweave } from "interweave";

import {
  Button,
  Radio,
  Tooltip,
  Input,
  Popover,
  Form,
  Modal,
  List,
  FloatButton,
  Flex,
  ConfigProvider,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
  TableOutlined,
  CloseOutlined,
  LeftCircleOutlined,
  CaretRightOutlined,
  MoreOutlined,
  LinkOutlined,
  CopyOutlined,
  FontColorsOutlined,
  SwapOutlined,
  DeleteOutlined,
  PlusOutlined,
  FolderAddFilled,
  FileAddFilled,
  FolderOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";

//components
import EmptyBox from "../../components/ui/EmptyBox";
import TreeThemeFolders from "../../components/app/theme/TreeThemeFolders";

//redux
import {
  loadingRedux,
  setLoadingRedux,
  windowWidthRedux,
} from "../../store/application/app.slice";
import { setUpdate, themes, update } from "../../store/theme/theme.slice";
import {
  fetchHomeFolders,
  hanfleDnDTheme,
  copyTheme,
  renameTheme,
  deleteTheme,
  updateThemeFolder,
  createNewTheme,
  createNewThemeFolder,
  deleteThemeFolder,
} from "../../store/theme/theme.action";

//utils
import i18n from "../../utils/i18n";
import { getStorage, setStorage } from "../../utils/Storage";
import { setSession, deleteSession } from "../../utils/Session";

//images
import PaintBrush from "../../assets/images/theme/paint-brush.svg";

//styles
import "../../assets/styles/themes/home.css";

const ThemesPage = () => {
  const dispatch = useDispatch();
  const hostname = window.location.hostname;
  const baseURL =
    hostname.indexOf("localhost") >= 0
      ? "https://devsurvey.inovyo.com"
      : "https://devsurvey.inovyo.com";

  //states
  const [menuOpen, setMenuOpen] = useState(true);
  const [display, setDisplay] = useState("grid");
  const [originalThemes, setOriginalThemes] = useState(null);
  const [currentThemes, setCurrentThemes] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([{ id: "*", title: i18n.INIT }]);
  const [searchValue, setSearchValue] = useState("");
  const [animate, setAnimate] = useState("");
  const [folderName, setFolderName] = useState("");
  const [themeName, setThemeName] = useState("");
  const [showModalCreateFolder, setShowModalCreateFolder] = useState(false);
  const [showModalCreateTheme, setShowModalCreateTheme] = useState(false);

  //redux vars
  const loading = useSelector(loadingRedux);
  const themesRedux = useSelector(themes);
  const updateX = useSelector(update);
  const ww = useSelector(windowWidthRedux);

  useEffect(() => {
    dispatch(fetchHomeFolders());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (themesRedux !== null) {
      const themesStorage = getStorage("THEMES", true);

      if (ww < 768) {
        themesStorage.themeView = "list";
        setMenuOpen(false);
      } else {
        setMenuOpen(ww > 1080);
        themesStorage.themeView = ww > 768 ? "grid" : "list";
      }

      setOriginalThemes(themesStorage.themeFull);
      setBreadcrumb(themesStorage.breadcrumb);
      setDisplay(themesStorage.themeView);

      setTimeout(() => {
        extractCurrentView();
      }, 300);

      setTimeout(() => {
        dispatch(setLoadingRedux(false));
      }, 300);
    }

    //eslint-disable-next-line
  }, [themesRedux]);

  useEffect(() => {
    if (themesRedux !== null) {
      const themesStorage = getStorage("THEMES", true);
      setBreadcrumb(themesStorage.breadcrumb);
      setTimeout(() => extractCurrentView(), 300);
    }
    //eslint-disable-next-line
  }, [themesRedux, updateX]);

  useEffect(() => {
    if (originalThemes !== null) {
      extractCurrentView();
      dispatch(setUpdate());
    }

    // eslint-disable-next-line
  }, [originalThemes]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate("");
      const themesStorage = getStorage("THEMES", true);
      if (ww < 768) {
        setDisplay("list");
        setStorage("THEMES", { ...themesStorage, themeView: "list" }, true);
        setMenuOpen(false);
      } else {
        let homeView = "";
        homeView = ww > 768 && ww < 1280 ? themesStorage.themeView : "grid";
        setMenuOpen(ww > 1080);
        setDisplay(homeView);
        setStorage("THEMES", { ...themesStorage, themeView: homeView }, true);
      }
    }, 10);

    //eslint-disable-next-line
  }, [ww]);

  const searchInput = (val) => {
    setSearchValue(val);
    dispatch(setUpdate());
  };

  const changeView = (value) => {
    setDisplay(value);
    let themesStorage = getStorage("THEMES", true);
    setStorage(
      "THEMES",
      {
        ...themesStorage,
        themeView: value,
      },
      true
    );
  };

  const extractCurrentView = () => {
    const themesStorage = getStorage("THEMES", true);
    let extractData = [];

    if (searchValue !== "") {
      originalThemes !== null &&
        originalThemes.forEach((item) => {
          if (
            item.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          ) {
            extractData.push(item);
          }
        });
    } else if (themesStorage.selectedFolder.id === "full") {
      originalThemes !== null &&
        originalThemes.forEach((item) => {
          if (item.type === "t") {
            extractData.push(item);
          }
        });
    } else {
      originalThemes !== null &&
        originalThemes.forEach((item) => {
          if (item.folderId === themesStorage.selectedFolder.id) {
            extractData.push(item);
          }
        });
    }

    setCurrentThemes(extractData.length > 0 ? extractData : null);
  };

  const Breadcrumb = () => {
    const biggerOrEqual4 = breadcrumb.length >= 4;

    const handleBackFolder = () => {
      const themesStorage = getStorage("THEMES", true);
      let breadcrumbCopy = [...breadcrumb];
      breadcrumbCopy.pop();
      setBreadcrumb(breadcrumbCopy);
      themesStorage.breadcrumb = breadcrumbCopy;
      themesStorage.selectedFolder = breadcrumbCopy[breadcrumbCopy.length - 1];
      setStorage("THEMES", themesStorage, true);
      dispatch(setUpdate());
    };

    const findInBreadcrumb = (item) => {
      let breadcrumbCopy = [...breadcrumb];
      let breadcrumbRenewed = [];
      let finded = false;

      breadcrumbCopy.forEach((el) => {
        if (!finded) {
          breadcrumbRenewed.push(el);

          if (el.id === item.id) {
            finded = true;
          }
        }
      });

      return breadcrumbRenewed;
    };

    const handleOptionFolder = (item) => {
      const themesStorage = getStorage("THEMES", true);
      let newBreadcrumb = findInBreadcrumb(item);
      setBreadcrumb(newBreadcrumb);
      themesStorage.breadcrumb = newBreadcrumb;
      themesStorage.selectedFolder = item;
      setStorage("THEMES", themesStorage, true);
      dispatch(setUpdate());
    };

    const backFolder =
      breadcrumb.length > 1 ? (
        <div
          className="i-breadcrumb-item-touch back-folder"
          onClick={() => handleBackFolder()}
        >
          <LeftCircleOutlined />
        </div>
      ) : (
        <></>
      );

    const miniMenu = () => {
      let miniBread = [];

      breadcrumb.forEach((b, i) => {
        if (breadcrumb.length - 2 !== i && breadcrumb.length - 1 !== i) {
          miniBread.push(b);
        }
      });

      return miniBread.map((mb, j) => {
        return (
          <div
            className="mini-menu-item"
            key={`mini-menu-item-${j}`}
            onClick={() => handleOptionFolder(mb)}
          >
            {mb.title}
          </div>
        );
      });
    };

    return (
      <div className="i-breadcrumb">
        {backFolder}
        {biggerOrEqual4 ? (
          <>
            <Popover
              id="mini-menu-breadcrumb-theme"
              content={<>{miniMenu()}</>}
              placement="bottom"
              trigger="click"
              style={{}}
            >
              <div className="i-breadcrumb-item-touch">...</div>
            </Popover>
            <div className="i-breadcrumb-item">
              <CaretRightOutlined style={{ fontSize: 10 }} />
            </div>
            <div
              className="i-breadcrumb-item-touch"
              onClick={() =>
                handleOptionFolder(breadcrumb[breadcrumb.length - 2])
              }
            >
              {breadcrumb[breadcrumb.length - 2]["title"]}
            </div>
            <div className="i-breadcrumb-item">
              <CaretRightOutlined style={{ fontSize: 10 }} />
            </div>
            <div
              className="i-breadcrumb-item-touch"
              onClick={() =>
                handleOptionFolder(breadcrumb[breadcrumb.length - 1])
              }
            >
              {breadcrumb[breadcrumb.length - 1]["title"]}
            </div>
          </>
        ) : (
          <>
            {breadcrumb.map((b, i) => {
              b.title = b.id === "*" ? i18n.INIT : b.title;
              let clickable = breadcrumb.length !== i + 1;

              const hasArroy = clickable ? (
                <div className="i-breadcrumb-item">
                  <CaretRightOutlined style={{ fontSize: 10 }} />
                </div>
              ) : (
                <></>
              );

              return (
                <React.Fragment key={"i-breadcrumb-item" + i}>
                  <div
                    className={
                      clickable
                        ? "i-breadcrumb-item-touch"
                        : "i-breadcrumb-item"
                    }
                    onClick={() => {
                      if (clickable) handleOptionFolder(b);
                    }}
                  >
                    {b.title}
                  </div>
                  {hasArroy}
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const enterFolder = (item) => {
    const themesStorage = getStorage("THEMES", true);
    let breadcrumbCopy = themesStorage.breadcrumb;
    breadcrumbCopy.push(item);
    themesStorage.breadcrumb = breadcrumbCopy;
    themesStorage.selectedFolder = item;
    setStorage("THEMES", themesStorage, true);
    setBreadcrumb(breadcrumbCopy);
    dispatch(setUpdate());
  };

  const handleCreateFolder = () => {
    const themesStorage = getStorage("THEMES", true);

    dispatch(
      createNewThemeFolder({
        id: themesStorage.id,
        parent_id: themesStorage.selectedFolder.id,
        name: folderName,
      })
    );
    setTimeout(() => setFolderName(""), 1000);
  };

  const handleCreateTheme = () => {
    const themesStorage = getStorage("THEMES", true);
    dispatch(
      createNewTheme({
        folder_id: themesStorage.selectedFolder.id,
        name: themeName,
      })
    );
    setTimeout(() => setThemeName(""), 1000);
  };

  const PopoverOptionFolder = ({ folderData }) => {
    const { title, id: fId } = folderData;

    const [showMiniMenu, setMiniMenu] = useState(false);
    const [showModalRenameFolder, setShowModalRenameFolder] = useState(false);
    const [titleRenamed, setTitleRenamed] = useState(title);
    const [showModalDeleteFolder, setShowModalDeleteFolder] = useState(false);
    const [showModalMoveFolder, setShowModalMoveFolder] = useState(false);
    const [chosenFolder, setChosenFolder] = useState(0);
    const [previewFolders, setPreviewFolders] = useState([]);

    const handleRename = () => {
      let themesStorage = getStorage("THEMES", true);
      dispatch(
        updateThemeFolder(fId, { name: titleRenamed, id: themesStorage.id })
      );
    };

    const handleDeleteFolder = () => dispatch(deleteThemeFolder(fId));

    const handleMoveFolder = () => {
      if (chosenFolder === 0) {
        toast.warning(i18n.MOVE_FOLDER_MSG2);
        return;
      }

      setPreviewFolders([]);
      setChosenFolder(0);
      setShowModalMoveFolder(false);
      dispatch(hanfleDnDTheme());
    };

    const TreeFoldersMove = () => {
      const themesStorage = getStorage("THEMES", true);

      const showFolder = (fData) => {
        let previewFoldersCopy = [...previewFolders];

        if (previewFoldersCopy.includes(fData.key)) {
          previewFoldersCopy.splice(previewFoldersCopy.indexOf(fData.key), 1);
        } else {
          previewFoldersCopy.push(fData.key);
        }
        setPreviewFolders(previewFoldersCopy);
      };

      const clickFolder = (className, fData) => {
        const classString = typeof className === "string" ? className : "";

        if (
          classString.indexOf("name") >= 0 ||
          classString.indexOf("tree-list-item") >= 0
        ) {
          //mover para própria pasta
          if (fData.key === fId) {
            toast.warning(i18n.CANNOT_MOVE_FOLDER_OWN_FOLDER);
          } else if (themesStorage.selectedFolder.id === fData.key) {
            //Sua pasta já está neste local
            toast.warning(i18n.MOVE_FOLDER_MSG1);
          } else {
            setChosenFolder(fData.key);
            setSession("TARGET_ID", { targetId: fData.key, type: "f" }, true);
          }
        }
      };

      const MountLevels = ({ children, lvl, hierarchy }) => {
        return children.map((f, i) => {
          return (
            <React.Fragment key={`tree-list-item` + i}>
              <div
                className={`tree-list-item ${
                  chosenFolder === f.key ? "checked" : ""
                }`}
                onClick={({ target: { className } }) =>
                  clickFolder(className, f)
                }
              >
                <div className="icon" onClick={() => showFolder(f)}>
                  {previewFolders.includes(f.key) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </div>
                <div className="name">{f.title}</div>
              </div>
              <div
                className={`lvl-${lvl} ${
                  previewFolders.includes(f.key) ? "open" : "close"
                }`}
              >
                {f.children.length > 0 && (
                  <MountLevels
                    children={f.children}
                    lvl={lvl + 1}
                    hierarchy={[
                      ...hierarchy,
                      {
                        id: f.key,
                        title: f.title,
                        type: "f",
                      },
                    ]}
                  />
                )}
              </div>
            </React.Fragment>
          );
        });
      };

      return (
        <div id="tree-move-themes">
          <MountLevels
            children={themesStorage.themeFolders}
            lvl={1}
            hierarchy={[
              {
                id: "*",
                title: i18n.HOME,
                type: "f",
              },
            ]}
          />
        </div>
      );
    };

    return (
      <>
        <div
          className="more-options"
          onClick={() => setMiniMenu(!showMiniMenu)}
        >
          <MoreOutlined />
        </div>
        <Modal
          closeIcon={null}
          open={showMiniMenu}
          footer={null}
          onCancel={() => setMiniMenu(false)}
          styles={{ content: { padding: 0 } }}
          width={"200px"}
        >
          <List
            size="small"
            bordered
            dataSource={[
              {
                label: (
                  <>
                    <FontColorsOutlined /> &nbsp;{i18n.RENAME}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalRenameFolder(true);
                },
              },
              {
                label: (
                  <>
                    <SwapOutlined /> &nbsp;{i18n.MOVE}
                  </>
                ),
                fn: () => {
                  setSession(
                    "DRAGGIND_ID",
                    { draggingId: fId, type: "f" },
                    true
                  );
                  setMiniMenu(!showMiniMenu);
                  setShowModalMoveFolder(true);
                },
              },
              {
                label: (
                  <>
                    <DeleteOutlined /> &nbsp;{i18n.DELETE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDeleteFolder(true);
                },
              },
            ]}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer", justifyContent: "flex-start" }}
                onClick={() => item.fn()}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          open={showModalRenameFolder}
          onCancel={() => {
            setShowModalRenameFolder(false);
            setTitleRenamed(title);
          }}
          cancelText={i18n.CANCEL}
          onOk={() => {
            handleRename();
          }}
          title="Renomear pasta"
        >
          <Input
            value={titleRenamed}
            onChange={({ target: { value } }) => setTitleRenamed(value)}
          />
        </Modal>
        <Modal
          open={showModalDeleteFolder}
          onCancel={() => setShowModalDeleteFolder(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDeleteFolder()}
          title={i18n.CAUTION.toUpperCase() + "!"}
        >
          <Interweave content={i18n.THEME_MSG1} />
        </Modal>
        <Modal
          open={showModalMoveFolder}
          onCancel={() => {
            setPreviewFolders([]);
            setChosenFolder(0);
            setShowModalMoveFolder(false);
            deleteSession("DRAGGIND_ID");
            deleteSession("TARGET_ID");
          }}
          onOk={() => handleMoveFolder()}
          cancelText={i18n.CANCEL}
          title="Mover pasta para"
        >
          <TreeFoldersMove />
        </Modal>
      </>
    );
  };

  const PopoverOptionTheme = ({ themeData }) => {
    const { title, id: tId } = themeData;

    const [showMiniMenu, setMiniMenu] = useState(false);
    const [showModalDuplicate, setShowModalDuplicate] = useState(false);
    const [showModalRename, setShowModalRename] = useState(false);
    const [titleRenamed, setTitleRenamed] = useState(title);
    const [showModalMove, setShowModalMove] = useState(false);
    const [chosenFolder, setChosenFolder] = useState(0);
    const [previewFolders, setPreviewFolders] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const handleDuplicateTheme = (themeCopyID) =>
      dispatch(copyTheme({ copyTheme: themeCopyID }));

    const handleRename = () =>
      dispatch(renameTheme(tId, { name: titleRenamed }));

    const handleDeleteTheme = () => dispatch(deleteTheme(tId));

    const handleMoveSurvey = () => {
      if (chosenFolder === 0) {
        toast.warning(i18n.MOVE_FOLDER_MSG2);
        return;
      }

      setPreviewFolders([]);
      setChosenFolder(0);
      setShowModalMove(false);
      dispatch(hanfleDnDTheme());
    };
    const TreeFoldersMove = () => {
      const themesStorage = getStorage("THEMES", true);

      const showFolder = (fData) => {
        let previewFoldersCopy = [...previewFolders];

        if (previewFoldersCopy.includes(fData.key)) {
          previewFoldersCopy.splice(previewFoldersCopy.indexOf(fData.key), 1);
        } else {
          previewFoldersCopy.push(fData.key);
        }
        setPreviewFolders(previewFoldersCopy);
      };

      const clickFolder = (className, fData) => {
        const classString = typeof className === "string" ? className : "";

        if (
          classString.indexOf("name") >= 0 ||
          classString.indexOf("tree-list-item") >= 0
        ) {
          //mover para própria pasta
          if (fData.key !== themesStorage.selectedFolder.id) {
            setChosenFolder(fData.key);
            setSession("TARGET_ID", { targetId: fData.key, type: "f" }, true);
          } else {
            //Sua pasta já está neste local
            toast.warning(i18n.MOVE_FOLDER_MSG1);
          }
        }
      };

      const MountLevels = ({ children, lvl, hierarchy }) => {
        return children.map((f, i) => {
          return (
            <React.Fragment key={`tree-list-item` + i}>
              <div
                className={`tree-list-item ${
                  chosenFolder === f.key ? "checked" : ""
                }`}
                onClick={({ target: { className } }) =>
                  clickFolder(className, f)
                }
              >
                <div className="icon" onClick={() => showFolder(f)}>
                  {previewFolders.includes(f.key) ? (
                    <FolderOpenOutlined />
                  ) : (
                    <FolderOutlined />
                  )}
                </div>
                <div className="name">{f.title}</div>
              </div>
              <div
                className={`lvl-${lvl} ${
                  previewFolders.includes(f.key) ? "open" : "close"
                }`}
              >
                {f.children.length > 0 && (
                  <MountLevels
                    children={f.children}
                    lvl={lvl + 1}
                    hierarchy={[
                      ...hierarchy,
                      {
                        id: f.key,
                        title: f.title,
                        type: "f",
                      },
                    ]}
                  />
                )}
              </div>
            </React.Fragment>
          );
        });
      };

      return (
        <div id="tree-move-themes">
          <MountLevels
            children={themesStorage.themeFolders}
            lvl={1}
            hierarchy={[
              {
                id: "*",
                title: i18n.HOME,
                type: "f",
              },
            ]}
          />
        </div>
      );
    };

    return (
      <>
        <div
          className="more-options"
          onClick={() => setMiniMenu(!showMiniMenu)}
        >
          <MoreOutlined />
        </div>
        <Modal
          closeIcon={null}
          open={showMiniMenu}
          footer={null}
          onCancel={() => setMiniMenu(false)}
          styles={{ content: { padding: 0 } }}
          width={"200px"}
        >
          <List
            size="small"
            bordered
            dataSource={[
              {
                label: (
                  <>
                    <LinkOutlined /> &nbsp;{i18n.COPY_LINK}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  navigator.clipboard.writeText(`${baseURL}/t/${tId}/builder`);
                  toast.success(i18n.COPIED_LINK);
                },
              },
              {
                label: (
                  <>
                    <CopyOutlined /> &nbsp;{i18n.DUPLICATE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDuplicate(true);
                },
              },
              {
                label: (
                  <>
                    <FontColorsOutlined /> &nbsp;{i18n.RENAME}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalRename(!showModalRename);
                },
              },
              {
                label: (
                  <>
                    <SwapOutlined /> &nbsp;{i18n.MOVE}
                  </>
                ),
                fn: () => {
                  setSession(
                    "DRAGGIND_ID",
                    { draggingId: tId, type: "t" },
                    true
                  );
                  setMiniMenu(!showMiniMenu);
                  setShowModalMove(true);
                },
              },
              {
                label: (
                  <>
                    <DeleteOutlined /> &nbsp;{i18n.DELETE}
                  </>
                ),
                fn: () => {
                  setMiniMenu(!showMiniMenu);
                  setShowModalDelete(true);
                },
              },
            ]}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer", justifyContent: "flex-start" }}
                onClick={() => item.fn()}
              >
                {item.label}
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          open={showModalDuplicate}
          onCancel={() => setShowModalDuplicate(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDuplicateTheme(tId)}
          title={i18n.DUPLICATE.toUpperCase() + "!"}
        >
          Deseja fazer uma cópia deste tema?
          <br />
          <h3>{title}</h3>
        </Modal>
        <Modal
          open={showModalRename}
          onCancel={() => setShowModalRename(false)}
          cancelText={i18n.CANCEL}
          onOk={() => {
            handleRename();
          }}
          title="Renomear tema"
        >
          <Input
            value={titleRenamed}
            onChange={({ target: { value } }) => setTitleRenamed(value)}
          />
        </Modal>
        <Modal
          open={showModalMove}
          onCancel={() => {
            setPreviewFolders([]);
            setChosenFolder(0);
            setShowModalMove(false);
            deleteSession("DRAGGIND_ID");
            deleteSession("TARGET_ID");
          }}
          onOk={() => handleMoveSurvey()}
          title="Mover tema para"
        >
          <TreeFoldersMove />
        </Modal>
        <Modal
          open={showModalDelete}
          onCancel={() => setShowModalDelete(false)}
          cancelText={i18n.CANCEL}
          onOk={() => handleDeleteTheme()}
          title={i18n.CAUTION.toUpperCase() + "!"}
        >
          <Interweave content={i18n.THEME_MSG2} />
        </Modal>
      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#232f9a",
        },
      }}
    >
      <div id="theme-page">
        <aside
          className={`aside-menu ${animate}`}
          style={{ left: menuOpen ? 0 : "-300px" }}
        >
          <TreeThemeFolders />
          <Button
            className="aside-menu-retract"
            type="text"
            icon={menuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => {
              if (!menuOpen) {
                setAnimate("close-animation");
              } else {
                setAnimate("open-animation");
              }
              setMenuOpen(!menuOpen);
            }}
          />
        </aside>
        <article
          className={`article-content ${ww > 1080 ? animate : ""}`}
          style={{
            width: ww > 1080 && menuOpen ? "calc(100% - 300px)" : "100%",
          }}
        >
          <div className="box-enviroment-title">{i18n.THEMES}</div>
          <div className="box-enviroment-bar">
            <div className="box-align-horizontal">
              <div className="input-box">
                <Input
                  className="search-input"
                  placeholder={i18n.SEARCH_BAR_PLACEHOLDER_THEME}
                  onChange={({ target: { value } }) => searchInput(value)}
                  value={searchValue}
                />
                {searchValue.length > 0 && (
                  <div
                    className="close-search-input"
                    onClick={() => searchInput("")}
                  >
                    <CloseOutlined />
                  </div>
                )}
              </div>
              <div>
                <Radio.Group
                  className="display-view"
                  onChange={({ target: { value } }) => changeView(value)}
                  value={display}
                  size="medium"
                >
                  <Tooltip title={i18n.GRID_VIEW_MODE}>
                    <Radio.Button value="grid">
                      <TableOutlined />
                    </Radio.Button>
                  </Tooltip>
                  <Tooltip title={i18n.LIST_VIEW_MODE}>
                    <Radio.Button value="list">
                      <UnorderedListOutlined />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                gap: "5px",
              }}
            ></div>
            {originalThemes !== null && <Breadcrumb />}
          </div>
          <div className="box-enviroments-content">
            <div className={`box-enviroments-content-itens ${display}`}>
              {currentThemes !== null &&
                currentThemes.map((item, index) => (
                  <div
                    className={`explorer-item ${
                      item.type === "t" ? "theme" : "folder"
                    }`}
                    key={`explorer-item-${index}`}
                    onDragStart={(e) => {
                      e.target.classList.add("dragging");
                      setSession(
                        "DRAGGIND_ID",
                        { draggingId: item.id, type: item.type },
                        true
                      );
                    }}
                    onDragEnd={(e) => {
                      e.target.classList.remove("dragging");
                      let overred = document.querySelector(
                        "div.tree-list-item > div.name.dragover"
                      );

                      if (overred) {
                        overred.classList.remove("dragover");
                      }

                      dispatch(hanfleDnDTheme());
                    }}
                    onDragLeave={(e) => {
                      if (item.type === "f") {
                        e.target.classList.remove("dragover");
                        setTimeout(() => {
                          deleteSession(
                            "TARGET_ID",
                            { targetId: item.id, type: "f" },
                            true
                          );
                        }, 10);
                      }
                    }}
                    onDragEnter={() => {
                      if (item.type === "f") {
                        setTimeout(() => {
                          setSession(
                            "TARGET_ID",
                            { targetId: item.id, type: "f" },
                            true
                          );
                        }, 15);
                      }
                    }}
                    onDragOver={(e) => {
                      if (item.type === "f") {
                        e.preventDefault();
                        e.target.classList.add("dragover");
                      }
                    }}
                    draggable={true}
                  >
                    {item.type === "t" ? (
                      <>
                        <PopoverOptionTheme themeData={item} />
                        <img src={PaintBrush} alt="PaintBrush" width="35px" />
                        <a href={`/t/${item.id}/builder`} draggable={false}>
                          {item.title}
                        </a>
                      </>
                    ) : (
                      <>
                        <div
                          className="description"
                          onClick={() => {
                            if (item.type === "f") enterFolder(item);
                          }}
                        >
                          {item.title}
                        </div>
                        <PopoverOptionFolder folderData={item} />
                      </>
                    )}
                  </div>
                ))}
              {currentThemes === null && !loading && (
                <EmptyBox type="empty-theme" />
              )}
            </div>
          </div>
        </article>
      </div>

      <Modal
        title={i18n.NEW_FOLDER}
        open={showModalCreateFolder}
        cancelText={i18n.CANCEL}
        okText={i18n.CREATE}
        onCancel={() => {
          setShowModalCreateFolder(false);
          setFolderName("");
        }}
        onOk={() => handleCreateFolder()}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.NAME} htmlFor="folder-name">
            <Input
              id="folder-name"
              placeholder={i18n.FOLDER_NAME}
              onChange={({ target: { value } }) => setFolderName(value)}
              value={folderName}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={i18n.NEW_THEME}
        open={showModalCreateTheme}
        onCancel={() => {
          setShowModalCreateTheme(false);
          setThemeName("");
        }}
        footer={null}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.TITLE} htmlFor="theme-name">
            <Input
              id="theme-name"
              placeholder="Título do Tema"
              onChange={({ target: { value } }) => setThemeName(value)}
              value={themeName}
              autoComplete="off"
            />
          </Form.Item>
          <Flex justify="flex-end" align="flex-end" gap={4}>
            <Button
              onClick={() => {
                setShowModalCreateTheme(false);
                setThemeName("");
              }}
            >
              {i18n.CANCEL}
            </Button>
            <Button type="primary" onClick={() => handleCreateTheme()}>
              {i18n.CREATE}
            </Button>
          </Flex>
        </Form>
      </Modal>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{
          right: 24,
        }}
        icon={<PlusOutlined />}
      >
        <FloatButton
          icon={<FolderAddFilled />}
          tooltip={i18n.CREATE_NEW_FOLDER}
          onClick={() => setShowModalCreateFolder(!showModalCreateFolder)}
        />
        <FloatButton
          icon={<FileAddFilled />}
          tooltip={i18n.CREATE_NEW_THEME}
          onClick={() => setShowModalCreateTheme(!showModalCreateTheme)}
        />
      </FloatButton.Group>
    </ConfigProvider>
  );
};

export default ThemesPage;
