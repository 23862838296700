import React from "react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

//components
import ContentApp from "./ContentApp";
import HeaderApp from "./HeaderApp";

//ui
import Loading from "../ui/Loading";

//redux
import { loadingRedux } from "../../store/application/app.slice";

const LayoutApp = (props) => {
  const { children } = props;
  let loading = useSelector(loadingRedux);

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        closeOnClick
        pauseOnHover
        autoClose={2000}
      />
      <HeaderApp />
      <ContentApp>{children}</ContentApp>
    </>
  );
};

export default LayoutApp;
