import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/dropdown.css";

//itens de pergunta
const DropdownComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="dropdown-component" className="dropdown">
      <div className="dropdown-title">
        <Interweave content={title} />{" "}
      </div>
      <div className="dropdown-content">
        <select className="select" disabled>
          <option defaultValue>...</option>
        </select>
      </div>
    </div>
  );
};

export default DropdownComponent;
