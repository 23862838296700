import React, { useEffect } from "react";
import ReactLoading from "react-loading";

//utils
import { clearStorages } from "../../../utils/Storage";
import { clearSessions } from "../../../utils/Session";
import { clearCookies } from "../../../utils/Cookie";
import i18n from "../../../utils/i18n";

//styles
import "../../../assets/styles/auth/logout.css";

const LogoutPage = () => {
  useEffect(() => {
    setTimeout(() => {
      clearStorages();
      clearSessions();
      clearCookies();
      window.location.href = "/login";
      return;
    }, 1000);
  }, []);

  return (
    <div id="logout-page">
      <div>
        {i18n.EXITING}...
        <br />
        <ReactLoading type="bubbles" color="#000000" />
      </div>
    </div>
  );
};

export default LogoutPage;
