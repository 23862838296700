import React from "react";
import { BgColorsOutlined, MehOutlined } from "@ant-design/icons";
import { FiInbox } from "react-icons/fi";

//styles
import "../../assets/styles/components/app/empty.css";

const Empty = ({ children, type }) => {
  const icons = {
    themes: <BgColorsOutlined />,
    sad: <MehOutlined />,
  }[type] || <FiInbox />;

  return (
    <div id="box-empty">
      {icons}
      <div>{children}</div>
    </div>
  );
};

export default Empty;
