import {
  getSurveyByIdAPI,
  updateSurveyByIdAPI,
  getLinksByIdSurveyAPI,
  createSubLinkAPI,
  updateLinkAPI,
  downloadFileDocxAPI,
} from "../../store/builder/builder.service";
import {
  setSurveyData,
  setShareData,
  setProgressLoading,
  setOpenEditor,
} from "../../store/builder/builder.slice";
import { toast } from "react-toastify";
import { message } from "antd";

//redux
import { setLoadingRedux } from "../../store/application/app.slice";

//utils
import { setStorage } from "../../utils/Storage";
import { getSession, setSession } from "../../utils/Session";
import i18n from "../../utils/i18n";

const updateScroll = (surveyid) => {
  let scrollY = getSession("SCROLL_" + surveyid);
  scrollY = scrollY !== "" ? scrollY : window.scrollY;
  setTimeout(() => {
    window.scrollTo(scrollY, parseInt(scrollY));
  }, 300);
};

export const getSurveyById = (surveyid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getSurveyByIdAPI(surveyid)
      .then(({ data }) => {
        dispatch(setSurveyData(data));
        setStorage("SURVEY_" + surveyid, data, true);
        dispatch(setLoadingRedux(false));
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
        dispatch(setLoadingRedux(false));
      })
      .finally(() => {
        setTimeout(() => {
          updateScroll(surveyid);
        }, 500);
      });
  };
};

export const updateSurveyById = (
  surveyid,
  surveyLocal,
  redirect,
  message,
  openEditor
) => {
  return (dispatch) => {
    updateSurveyByIdAPI(surveyid, surveyLocal)
      .then(() => {
        setTimeout(() => {
          if (redirect) {
            toast.success(message !== "" ? message : i18n.SAVED, {
              autoClose: 1000,
            });
            setTimeout(() => {
              window.location.href = `/s/${surveyid}/builder`;
            }, 1500);
          } else {
            dispatch(setProgressLoading(false));
            dispatch(setSurveyData(surveyLocal));
            toast.success(message !== "" ? message : i18n.SAVED, {
              autoClose: 1000,
            });
            updateScroll(surveyid);
            if (openEditor) dispatch(setOpenEditor(true));
          }
        }, 1000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
        dispatch(setProgressLoading(false));
      });
  };
};

export const updateSurveyTitle = (surveyid, surveyLocal) => {
  message.info(i18n.SAVING + "....", 2);

  return () => {
    updateSurveyByIdAPI(surveyid, surveyLocal)
      .then(() => {
        message.success(i18n.SAVED, 2);
        setStorage("SURVEY_" + surveyid, surveyLocal, true);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
      });
  };
};

export const updateGeneralData = (surveyid, surveyLocal) => {
  message.info(i18n.SAVING + "....", 2);

  return (dispatch) => {
    dispatch(setProgressLoading(false));
    updateSurveyByIdAPI(surveyid, surveyLocal)
      .then(() => {
        toast.success(i18n.SAVED);
        setTimeout(() => {
          window.location.href = `/s/${surveyid}/general`;
        }, 1500);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
        dispatch(setProgressLoading(false));
      });
  };
};

export const getLinksByIdSurvey = (surveyid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getLinksByIdSurveyAPI(surveyid)
      .then(({ data }) => {
        data = data.map((link) => {
          link.key = link.id;
          return link;
        });

        dispatch(setShareData(data));
        setSession("SHARE_" + surveyid, data, true);
      })
      .catch(() => {
        dispatch(setShareData({}));
        toast.error(i18n.FAIL_COMMUNICATION);
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};

export const createSubLink = (surveyid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createSubLinkAPI(surveyid)
      .then(() => {
        toast.success(i18n.CREATED_LINK);
        setTimeout(() => {
          window.location.href = `/s/${surveyid}/share`;
        }, 1500);
      })
      .catch(() => {
        dispatch(setShareData({}));
        toast.error(i18n.FAIL_CREATE_LINK);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateLink = (surveyid, shareid, linkData) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateLinkAPI(surveyid, shareid, linkData)
      .then(() => {
        toast.success(i18n.UPDATED_LINK);
        setTimeout(() => {
          window.location.href = `/s/${surveyid}/share`;
        }, 1500);
      })
      .catch(() => {
        dispatch(setShareData({}));
        toast.error(i18n.FAIL_UPDATE_LINK);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const downloadFileDocx = (surveyid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    toast.info("Baixando...");

    downloadFileDocxAPI(surveyid)
      .then((file) => {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:${file.data.mimeType};base64,${file.data.base64}`; //Image Base64 Goes here
        a.download = file.data.fileName; //File name Here
        a.click();

        dispatch(setLoadingRedux(false));
        toast.success("Baixado!");
      })
      .catch(() => {
        dispatch(setLoadingRedux(false));
        toast.error("Falha ao baixar arquivo");
      });
  };
};
