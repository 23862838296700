import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import moment from "moment";

console.log(
  `
  ###############################################################################\r
  ###  ##  ###  ###      ###  ####  ##  ######  ###      #####       ##      ####\r
  ###  ##   ##  ##  ####  ##  ####  ###  ####  ###  ####  ###                 ###\r
  ###  ##    #  ##  ####  ###  ##  #####  ##  ####  ####  ###                 ###\r
  ###  ##  #    ##  ####  ###  ##  ######    #####  ####  ####               ####\r
  ###  ##  ##   ##  ####  ####    ########  ######  ####  ######           ######\r
  ###  ##  ###  ###      ######  #########  #######      ##########     #########\r
  ###############################################################################\r
  ##########       %cD    E    V    E    L    O    P    E    R    S%c       #########\r
  ###############################################################################\r
  ######### %c♥%c FEITO COM AMOR - TODOS DIREITOS RESERVADOS - INOVYO ${moment().format(
    "YYYY"
  )} %c♥%c ########\r
  ###############################################################################\r
`,
  "color: #e95df1",
  "color: black",
  "color: red",
  "color: black",
  "color: red",
  "color: black"
);

//console.log = function() {}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
