/**
 * Converte um objeto de estrutura lógica da pesquisa
 * em um array de fácil leitura e interpretação
 * @param {object} elemento
 * @param {number} lvl_pai
 * @returns {[]}
 */
export const jsonReader = (elemento, lvl_pai = 2) => {
  let array = [];
  let lvl_atual = elemento["lvl"];

  if (lvl_atual === 0) {
    if (lvl_pai === lvl_atual + 2) {
      return [[elemento]];
    }
    if (lvl_pai === lvl_atual + 1) {
      return [elemento];
    }
  } else {
    let new_array = [];
    new_array.push(...jsonReader(elemento["element_1"], lvl_atual));
    new_array.push(elemento["operator"]);
    new_array.push(...jsonReader(elemento["element_2"], lvl_atual));

    if (lvl_pai > lvl_atual) {
      array.push(new_array);
    } else if (lvl_pai === lvl_atual) {
      array.push(...new_array);
    }
  }

  return array;
};

/**
 * Converte um array de estrutura lógica da pesquisa
 * em um objeto para iterpretação da pesquisa
 * @param {object} elemento
 * @param {number} lvl
 * @returns {object}
 */
export const arrayReader = (element, lvl = 2) => {
  const isJSONObject = (variable) => {
    return (
      typeof variable === "object" &&
      !Array.isArray(variable) &&
      variable !== null
    );
  };

  if (isJSONObject(element)) {
    return element;
  } else if (element.length === 1) {
    return arrayReader(element[0], lvl - 1);
  } else {
    const element_1 = arrayReader(element[0], lvl - 1);
    const operator = element[1];
    const element_2 = arrayReader(element.slice(2), lvl);

    return { element_1, element_2, lvl, operator };
  }
};
