const LANGAGUE = {
  //GENERAL
  INIT: "Home",
  PROCESSING: "Processing...",
  LOADING: "Loading...",
  BUILDER: "Builder",
  STYLE: "Style",
  SHARE: "Share",
  RESULTS: "Results",
  GENERAL: "General",
  ADD: "Add",
  ADDING: "Adding",
  EDIT: "Edit",
  DUPLICATE: "Duplicate",
  DUPLICATING: "Duplicating",
  MOVE: "Move",
  MOVING: "Moving",
  DELETE: "Delete",
  DELETING: "Deleting",
  PAGE_NOT_FOUND: "Page not found!",
  CHANGING_LANGUAGE: "Changing language..",
  SURVEYS: "Surveys",
  SURVEY: "Survey",
  FILES: "Files",
  LOGOUT: "Logout",
  NAME: "Name",
  FOLDER: "Folder",
  FOLDER_NAME: "Folder name",
  CANCEL: "Cancel",
  CREATE: "Create",
  LANGUAGE: "Language",
  TITLE: "Title",
  NO_DATA: "No data",
  INSERT: "INSERT",
  OPEN: "Open",
  REOPEN: "Re-Open",
  CAUTION: "Caution",
  SAVE: "Save",
  SAVING: "Saving",
  SAVED: "Saved",
  TRANSLATION: "Translation",
  CLOSE: "Close",
  RENAME: "Rename",
  TRASH: "Trash",
  TRY_AGAIN: "Try again",
  CHANGE_MADE_SUCCESSFULLY: "Change made successfully",
  FAILED_MAKE_CHANGE: "Failed to make change",
  COPY: "Copy",
  COPY_LINK: "Copy URL",
  COPIED_LINK: "URL copied",
  OPEN_LINK: "Open link",
  DEFAULT: "Default",
  PREVIEW: "Preview",
  GROUP: "Group",
  CLEAR: "Clear",
  PASTE: "Paste",
  IMPORT: "Import",
  OPTIONS: "Options",
  LANGUAGES: [
    { value: "de-de", label: "German" },
    { value: "zh-cn", label: "Chinese" },
    { value: "ko-kr", label: "Korean" },
    { value: "es-es", label: "Spanish" },
    { value: "fr-fr", label: "French" },
    { value: "nl-nl", label: "Dutch" },
    { value: "en-us", label: "English" },
    { value: "it-it", label: "Italian" },
    { value: "ja-jp", label: "Japanese" },
    { value: "pt-br", label: "Portuguese (Brazil)" },
    { value: "pt-pt", label: "Portuguese (Portugal)" },
    { value: "ru-ru", label: "Russian" },
    { value: "sv-se", label: "Swiss" },
  ],

  //AUTH
  LOGIN_AUTHORIZATED: "Login authorizated",
  FAIL_COMMUNICATION: "Comunication failure",
  USER_DO_NOT_FOUND: "Incorrect username or password.",
  USER: "User",
  PASSWORD: "Password",
  LOGIN_BTN_TEXT: "LOGIN",
  SOME_FIELDS_EMPTY: "Some field is empty",
  ERROR_NETWORK: "You are without network!",
  NETWORK_ONLINE: "You are with network!",
  EMAIL_UNVERIFIED:
    "You need to verify your email to use our platform. Access your inbox and validate your Google account.",
  EMAIL_UNREGISTRED:
    "You need to be registered on our platform to use our services.",
  NO_PERMISSION: "You need permission to use our platform.",
  INVALID_REQUEST: "Invalid Request.",
  EXITING: "Exiting",
  LOGIN_WITH_GOOGLE: "Sign in with Google",

  //HOME
  SEARCH_BAR_PLACEHOLDER: "Search for a folder or survey",
  CREATE_NEW_FOLDER: "Create new folder",
  NEW_FOLDER: "New folder",
  CREATE_NEW_SURVEY: "Create new survey",
  NEW_SURVEY: "New survey",
  GRID_VIEW_MODE: "Grid view",
  LIST_VIEW_MODE: "List view",
  ALL_SURVEYS: "All Surveys",
  ACTIVE_LABEL: "Active",
  INACTIVE_LABEL: "Inactive",
  EMPTY_FOLDER: "Empty folder. Create a folder or survey (+)",
  FOLDER_CREATED_SUCCESSFULLY: "Folder created successfully",
  FAILED_CREATE_FOLDER: "Failed to create folder",
  SURVEY_CREATED: "Survey created",
  FAIL_CREATED_SURVEY: "Fail to create Survey",
  FAIL_DELETE_FOLDER: "Fail to delete Folder",
  FAIL_DELETE_SURVEY: "Fail to delete Survey",
  FAIL_COPY_SURVEY: "Fail to copy Survey",
  DELETED_FOLDER: "Deleted Folder",
  DELETED_SURVEY: "Deleted Survey",
  FOLDER_MOVED_SUCCESSFULLY: "Folder moved successfully",
  SURVEY_MOVED_SUCCESSFULLY: "Survey moved successfully",
  FAIL_MOVE_ITEM: "Failed to move item",
  CANNOT_MOVE_FOLDER_OWN_FOLDER: "Cannot move to own folder",
  MOVE_SURVEY_MSG1: "This search is already in this folder",
  MOVE_FOLDER_MSG1: "This folder is already in this local",
  MOVE_FOLDER_MSG2: "No folders selected",
  COPIED_SURVEY: "Copied Survey",
  MESSAGE_DELETE_FOLDER: `
    Do you really want to delete this folder?<br />
    <span style="color:red"><strong>
    This action is irreversible, all searches and folders 
    contained within will be deleted.</strong></span>`,
  MESSAGE_DELETE_SURVEY: `
    Do you really want to delete this survey?<br />
    <span style="color:red"><strong>
    This action is irreversible.</strong></span>`,

  //THEME
  THEME: "Theme",
  THEMES: "Themes",
  EMPTY_THEME: "Empty folder. Create a folder or theme (+)",
  ALL_THEMES: "All themes",
  SEARCH_BAR_PLACEHOLDER_THEME: "Search for a folder or theme",
  CREATE_NEW_THEME: "Create new survey",
  NEW_THEME: "New Theme",
  CREATED_THEME: "Created theme",
  FAIL_CREATE_THEME: "Fail to create Theme",
  COPIED_THEME: "Copied Theme",
  FAIL_COPY_THEME: "Fail to copy Theme",
  DELETED_THEME: "Deleted Theme",
  FAIL_DELETE_THEME: "Fail to delete Theme",
  THEME_MOVED_SUCCESSFULLY: "Theme moved successfully",
  THEME_MSG1: `
    Do you really want to delete this folder?<br />
    <span style="color:red"><strong>
    This action is irreversible, all themes and folders 
    contained within will be deleted.</strong></span>`,
  THEME_MSG2: `
    Do you really want to delete this theme?<br />
    <span style="color:red"><strong>
    This action is irreversible.</strong></span>`,
  THEME_MSG3: "This theme is already in this folder",
  THEME_MSG4: "This folder is already in this local",

  //SHARE
  CREATED_LINK: "Created link",
  FAIL_CREATE_LINK: "Fail to create link",
  UPDATED_LINK: "Updated link",
  FAIL_UPDATE_LINK: "Fail to update link",
  TABLET_MODE_EXPLANATION: `Tablet Mode is active for tablet searches 
    face-to-face (local). Ex.: The customer makes a purchase on the 
    shopping mall and evaluates the shopping experience at the end.`,

  //BUILDER
  NEW_PAGE: "New Page",
  MESSAGE_EMPTY_BUILD_PAGE: "Let's add something to this page",
  DELETE_PAGE_SUCCESS: "Page deleted.",
  DELETE_QUESTION_SUCCESS: "Question deleted.",
  DUPLICATE_QUESTION_SUCCESS: "Question duplicated.",
  DUPLICATE_PAGE_SUCCESS: "Page duplicated.",
  SURVEY_DO_NOT_FOUND: "Survey do not found",
  NO_SURVEYS_FOUND: "No surveys found",
  QUESTION: "Question",
  ACTION: "Action",
  LOGIC: "Logic",
  MERGE_PAGES: "Merge Pages",
  MERGING_PAGES: "Merging Pages",
  MERGED_PAGE: "Page Merged",
  BREAK_PAGE: "Break page",
  TEXT_EXAMPLE_NEW_QUESTION: "This is a simple text",
  CREATING_PAGE: "Creating page",
  CAUTION_MERGE_PAGE:
    "This action is irreversible! Do you want to merge pages?",
  CAUTION_BREAK_PAGE:
    "This action is irreversible! Do you want to break this page?",
  MOVING_PAGES: "Moving pages",
  MOVED_PAGE: "Moved Page",
  MOVED_QUESTION: "Moved Question",
  TOP_OF_PAGE: "Top of Page",
  SAVED_PAGE: "Saved Page",
  QUESTION_TYPES: {
    text: "Text",
    nps: "NPS",
    csat: "CSAT",
    ces: "CES",
    radiobox: "Radiobox",
    checkbox: "Checkbox",
    input: "Input",
    dropdown: "Dropdown",
    textarea: "Textarea",
    optin: "OptIN",
    mosaic: "Mosaic",
    "auto-complete": "Auto Complete",
    "language-dropdown": "Language Selector",
    "cascading-dropdown": "Cascading Dropdown",
    "image-upload": "Image Upload",
    "reset-button": "Reset Button",
  },
  QUESTION_HAS_LOGIC: "This question has logic",
  EDIT_PAGE: "Edit Page",
  ACTIVE_PAGE: "Active Page",
  MOVE_PAGE: "Move Page",
  NO_APPLY: "Not apply",
  RECTANGULAR: "Rectangular",
  CIRCULAR: "Circular",
  NO_SHADOW: "No Shadow",
  WITH_SHADOW: "With Shadow",
  STYLES: "Styles",
  COLORFULL: "Colorfull",
  COLORFULL_EMOTICON: "Colorfull Emoticon",
  TRIAD: "Triad",
  ACTIVE_COLOR: "Active Color",
  PILL: "Pill",
  PILL_CHECK: "Pill Check",
  BLOCK: "Block",
  STARS: "Stars",
  STARS_3D: "3D Stars",
  STARS_3D_ANIMATED: "Animated 3D Stars",
  LIKE_NO_LIKE: "Like/No Like",
  LOVE_NO_LOVE: "Love/No Love",
  TEXT: "Text",
  PHONE: "Phone",
  DATE: "Date",
  TIME: "Time",
  DATE_TIME: "Date/Time",
  CSS_CLASS: "CSS Class",
  DISPOSITION: "Disposition",
  NORMAL: "Normal",
  COLOR: "Color",
  ALIGNMENT: "Alignment",
  TEXT_BUTTON: "BUTTON'S TEXT",
  MARKED: "Marked",
  UNMARKED: "Unmarked",
  BUILD_MSG_1: "Enable button shading",
  BUILD_MSG_2: `If you want to format something in the css (style) 
    of the question, enter the desired class. Resource created for 
    developers at your institution or those responsible for design.`,
  BUILD_MSG_3: "Select a question",
  BUILD_MSG_4: "Select an option",
  BUILD_MSG_5: "Variable name",
  BUILD_MSG_6: "Variable value",
  BUILD_MSG_7: `When entering logic, this question will only appear
    if the condition(s) are satisfied.`,

  // TEMPLATE BUILDER
  LOADING_TEMPLATE: "Loading template...",
  NEW_TEMPLATE_CREATED: "Novo template criado",
  EXPORT_HTML: "Export HTML",
  TEMPLATE_UPDATED: "Template Updated"
};

export default LANGAGUE;
