import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/input.css";

const InputComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="input-component" className="text">
      <div className="input-question">
        <Interweave content={title} />{" "}
      </div>
      <div className="input-content">
        <input type="text" disabled />
      </div>
    </div>
  );
};

export default InputComponent;
