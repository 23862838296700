import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//style
import "../../../assets/styles/components/app/builder/horizontal-menu.css";

//utils
import i18n from "../../../utils/i18n";
import { getStorage } from "../../../utils/Storage";

//redux
import { updateSurveyTitle } from "../../../store/builder/builder.action";
import { surveyData } from "../../../store/builder/builder.slice";

const HorizontalMenu = () => {
  const dispatch = useDispatch();
  const currentUrl = window.location.pathname;
  const { surveyid } = useParams();
  const [editable, setEditable] = useState(false);

  //states
  const [surveyName, setSurveyName] = useState("");
  const survey = useSelector(surveyData);

  useEffect(() => {
    if (survey !== null && Object.keys(survey).length > 0) {
      setSurveyName(survey.surveyNameInternal);
    }
    // eslint-disable-next-line
  }, [survey]);

  useEffect(() => {
    if (editable) {
      const saveSurveyTitle = setTimeout(() => {
        const surveyData = getStorage("SURVEY_" + surveyid, true);
        surveyData.surveyNameInternal = surveyName;
        dispatch(updateSurveyTitle(surveyid, surveyData));
      }, 1000);
      return () => clearTimeout(saveSurveyTitle);
    }
    // eslint-disable-next-line
  }, [surveyName]);

  const links = [
    {
      label: i18n.BUILDER,
      url: "builder",
    },
    {
      label: i18n.SHARE,
      url: "share",
    },
    {
      label: i18n.GENERAL,
      url: "general",
    },
  ];

  return (
    <>
      <div id="builder-horizontal-menu">
        <input
          type="text"
          name="title-survey-input"
          className="title-survey"
          contentEditable={editable}
          onClick={() => setEditable(true)}
          onBlur={() => setEditable(false)}
          onChange={({ target: { value } }) => setSurveyName(value)}
          value={surveyName}
        />
        <ul>
          {links.map((item, i) => {
            return (
              <li key={`bhm-${i}`}>
                <a
                  href={`/s/${surveyid}/${item.url}`}
                  className={currentUrl.indexOf(item.url) >= 0 ? "active" : ""}
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default HorizontalMenu;
