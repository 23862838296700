import React from "react";
import { Button } from "antd";
import { FaUserLock } from "react-icons/fa";

//utils
import i18n from "../../../utils/i18n";

//styles
import "../../../assets/styles/auth/google-auth.css";

const GoogleAuthPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("error");

  const msgError =
    {
      "email-unverified": i18n.EMAIL_UNVERIFIED,
      "email-unregistred": i18n.EMAIL_UNREGISTRED,
      "no-permission": i18n.NO_PERMISSION,
    }[myParam] || i18n.INVALID_REQUEST;

  return (
    <div id="google-auth-page">
      <div className="error-icon">
        <FaUserLock />
      </div>
      <div className="error-box">{msgError}</div>
      <div className="error-btn">
        <Button href="/login" type="primary">
          {i18n.TRY_AGAIN}
        </Button>
      </div>
    </div>
  );
};

export default GoogleAuthPage;
