/**
 * Gera uma string aleatoria
 * @param {string} qtd - quantidade de caracteres a serem retornados
 * @returns
 */
export const randomId = (qtd) => {
  qtd = qtd || 10;

  var result = "";
  var characters = `
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
    ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < qtd; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Retorna o tipos de questões em formuato modular e categorizados
 * @returns {[]}
 */
export const questionTypesList = () => {
  return [
    {
      label: "Básicos",
      items: {
        text: "Texto",
        radiobox: "Radiobox",
        checkbox: "Checkbox",
        input: "Input",
        dropdown: "Dropdown",
        textarea: "Campo Aberto",
      },
    },
    {
      label: "Intermediários",
      items: {
        nps: "NPS",
        csat: "CSAT",
        ces: "CES",
        optin: "Opt IN",
        "language-dropdown": "Seletor de Idioma",
        "auto-complete": "Auto Completar",
        "image-upload": "Upload de Imagens",
        "reset-button": "Reiniciar Pesquisa (Botão)",
      },
    },
    {
      label: "Avançados",
      items: {
        mosaic: "Mosaico",
        "cascading-dropdown": "Dropdown Cascata",
      },
    },
  ];
};

/**
 * Retorna o tipo do input de acordo com o seu design
 * @param {object} questionProperties
 * @returns {string}
 */
export const inputTypes = (questionProperties) => {
  if (questionProperties?.tel) {
    return "Telefone";
  } else if (questionProperties?.email) {
    return "E-mail";
  } else if (questionProperties?.cpf) {
    return "CPF";
  } else if (questionProperties?.date) {
    return "Data";
  } else if (questionProperties?.time) {
    return "Hora";
  } else if (questionProperties?.datetime) {
    return "Data/Hora";
  }
};

/**
 * Descobre se um hexadecimal é escuro ou claro, com base na sua luminosidade
 * @param {string} hexadecimal - (String) #000000
 * @returns {string}
 */
export const getLuminosity = (hexadecimal) => {
  var nib = hexadecimal.split("");
  var r = parseInt(nib[1] + nib[2], 16);
  var g = parseInt(nib[3] + nib[4], 16);
  var b = parseInt(nib[5] + nib[6], 16);
  var luminosity = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosity < 127.5 ? "dark" : "light";
};

/**
 * Retorna se está em ambiente de desenvolvimento
 * @returns {Boolean}
 */
export const isLocalhost = () => {
  return (
    window.location.hostname.indexOf("localhost") >= 0 ||
    window.location.hostname.indexOf("127.0.0.1") >= 0
  );
};
