import React, { createContext } from "react";
import userAuth from "./hooks/userAuth";

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated, token, loading, version } = userAuth();

  return (
    <Context.Provider value={{ authenticated, token, loading, version }}>
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
