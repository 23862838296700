import { generalRoutes } from "../modules/general";
import { authRoutes } from "../modules/auth";
import { builderRoutes } from "../modules/builder";
import { themesRoutes } from "../modules/theme";
import { fileRoutes } from "../modules/file";

import Page404 from "../../pages/general/page404";

export const listRoutes = () => {
  let routesArray = [];
  let routesArrayNew = routesArray.concat(
    generalRoutes(),
    authRoutes(),
    builderRoutes(),
    themesRoutes(),
    fileRoutes()
  );

  //page do not found
  routesArrayNew.push({ path: "*", page: Page404 });

  return routesArrayNew;
};

export const activeRoute = () => {
  let route = window.location.pathname;
  let routeData = {};

  listRoutes().forEach((element) => {
    if (element.path === route || route.indexOf(element.name) >= 0) {
      routeData = element;
      return;
    }
  });

  return routeData;
};
