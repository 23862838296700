import BuilderHomePage from "../../pages/builder/build";
import BuilderSharePage from "../../pages/builder/share";
import BuilderGeneralPage from "../../pages/builder/general";

//AUTH
export const builderRoutes = () => {
  return [
    {
      path: "/s/:surveyid/builder",
      page: BuilderHomePage,
      auth: true,
      name: "/s/",
    },
    {
      path: "/s/:surveyid/share",
      page: BuilderSharePage,
      auth: true,
      name: "/s/",
    },
    {
      path: "/s/:surveyid/general",
      page: BuilderGeneralPage,
      auth: true,
      name: "/s/",
    },
    { path: "/s/:surveyid", page: BuilderHomePage, auth: true, name: "/s/" },
  ];
};
