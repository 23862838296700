import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/optin.css";

const OptinComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="optin-component">
      <div className="optin-content">
        <input
          type="checkbox"
          id={`optin-${data.id}`}
          name={`optin-${data.id}`}
          className="optin-item"
          disabled
        />
        <span htmlFor={`optin-${data.id}`}>
          <Interweave content={title} blockList="span" />
        </span>
      </div>
    </div>
  );
};

export default OptinComponent;
