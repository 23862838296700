import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MonacoEditor from "@monaco-editor/react";
import {
  Segmented,
  InputNumber,
  Select,
  ColorPicker,
  Space,
  Input,
  Button,
} from "antd";

//icons
import {
  BsArrowsExpand,
  BsAlignCenter,
  BsDashLg,
  BsDropletFill,
  BsImageFill,
  BsCaretRightSquare,
  BsBack,
  BsFillHouseHeartFill,
} from "react-icons/bs";
import { FaMattressPillow } from "react-icons/fa6";

//components
import Empty from "../../../components/app/Empty";

//redux
import { getThemeById, updateTheme } from "../../../store/theme/theme.action";
import { theme } from "../../../store/theme/theme.slice";
import {
  loadingRedux,
  windowWidthRedux,
} from "../../../store/application/app.slice";

//utils
import { setSession, getSession } from "../../../utils/Session";
import { getLuminosity } from "../../../utils/String";
import i18n from "../../../utils/i18n";

//styles
import "../../../assets/styles/themes/editor.css";

const ThemeBuilderPage = () => {
  const dispatch = useDispatch();
  const { themeid } = useParams();
  const { TextArea } = Input;

  const [update, setUpdate] = useState(false);
  const [themeData, setThemeData] = useState(null);
  const [original, setOriginal] = useState(null);
  const [isEqual, setIsEqual] = useState(true);
  const [segmentId, setSegmentId] = useState("header");
  const [headerStyle, setHeaderStyle] = useState({});
  const [footerStyle, setFooterStyle] = useState({});
  const [buttomStyle, setButtomStyle] = useState("");
  const [colorProgressBarText, setColorProgressBarText] = useState("#ffffff");
  const themeRedux = useSelector(theme);
  const loading = useSelector(loadingRedux);
  const ww = useSelector(windowWidthRedux);

  useEffect(() => {
    dispatch(getThemeById(themeid));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (themeRedux !== null) {
      setSession(`THEME_EDITION_${themeid}`, themeRedux, true);
      setOriginal(JSON.stringify(themeRedux));
      setThemeData(themeRedux);
    }
    // eslint-disable-next-line
  }, [themeRedux]);

  useEffect(() => {
    if (themeRedux !== null) {
      let themeStorage = getSession(`THEME_EDITION_${themeid}`, true);

      /******* HEADER STYLES *******/
      const styleHeader = {
        height: themeStorage.header.height,
        position: themeStorage.header?.fixed ? "sticky" : "static",
        boxShadow: themeStorage.header?.shadow
          ? "rgba(0, 0, 0, 0.2) 0px 5px 5px"
          : "none",
        borderTop: themeStorage.header?.topLine?.active
          ? `8px solid ${themeStorage.header.topLine.color}`
          : `none`,
        backgroundColor: themeStorage.header?.bgColor,
      };

      /******* FOOTER STYLES *******/
      const styleFooter = {
        position: themeStorage.footer?.fixed ? "sticky" : "static",
        boxShadow: themeStorage.footer?.shadow
          ? "rgba(0, 0, 0, 0.2) 0px -5px 5px"
          : "none",
        borderBottom: themeStorage.footer?.bottomLine?.active
          ? `8px solid ${themeStorage.footer.bottomLine.color}`
          : `none`,
        backgroundColor: themeStorage.footer?.bgColor,
      };

      /******* BUTTON STYLES *******/
      const styleButton = `
        button.button-survey {
          background-color: ${
            themeStorage.footer?.colorButtons
              ? `${themeStorage.footer.colorButtons} !important`
              : "#9a2376 !important"
          };
          color: ${
            themeStorage.footer?.colorButtons
              ? getLuminosity(themeStorage.footer.colorButtons) === "dark"
                ? "#ffffff !important"
                : "#000000 !important"
              : "#ffffff !important"
          };
        }
        button.button-survey:hover {
          background-color: ${
            themeStorage.footer?.colorButtons
              ? `${themeStorage.footer.colorButtons}cc !important`
              : "#9a2376 !important"
          }
        }
        button.button-survey:active {
          background-color: ${
            themeStorage.footer?.colorButtons
              ? `${themeStorage.footer.colorButtons} !important`
              : "#9a2376"
          }
        }
      `;

      /******* PROGRESS BAR TEXT COLOR *******/
      setColorProgressBarText(
        themeStorage.footer?.colorButtons
          ? getLuminosity(themeStorage.footer.colorButtons) === "dark"
            ? "#ffffff"
            : "#000000"
          : "#ffffff"
      );

      setHeaderStyle(styleHeader);
      setFooterStyle(styleFooter);
      setButtomStyle(styleButton);
      setThemeData(themeStorage);
    }
    // eslint-disable-next-line
  }, [update, themeRedux]);

  const change = (value, action) => {
    let themeStorage = getSession(`THEME_EDITION_${themeid}`, true);
    let rx = /\d+/g;

    switch (action) {
      case "title":
        themeStorage.themeName = value;
        break;
      case "height-header":
        if (rx.test(value)) {
          themeStorage.header.height = value;
        }
        break;
      case "height-logo-header":
        if (rx.test(value)) {
          themeStorage.header.logoHeight = value;
        }
        break;
      case "position-header":
        themeStorage.header.fixed = value === 2;
        break;
      case "position-footer":
        themeStorage.footer.fixed = value === 2;
        break;
      case "shadow-header":
        themeStorage.header.shadow = value === 1;
        break;
      case "shadow-footer":
        themeStorage.footer.shadow = value === 1;
        break;
      case "topline-header":
        themeStorage.header.topLine = themeStorage.header?.topLine
          ? { ...themeStorage.header?.topLine, active: value === 1 }
          : { ative: value === 1, color: "#9a2376" };
        break;
      case "bottomline-footer":
        themeStorage.footer.bottomLine = themeStorage.footer?.bottomLine
          ? { ...themeStorage.footer?.bottomLine, active: value === 1 }
          : { ative: value === 1, color: "#9a2376" };
        break;
      case "topline-color-header":
        themeStorage.header.topLine.color = value;
        break;
      case "bottomline-color-footer":
        themeStorage.footer.bottomLine.color = value;
        break;
      case "bgcolor-header":
        themeStorage.header.bgColor = value;
        break;
      case "bgcolor-footer":
        themeStorage.footer.bgColor = value;
        break;
      case "color-buttons-footer":
        themeStorage.footer.colorButtons = value;
        break;
      case "logo-header":
        themeStorage.header.logo =
          value !== ""
            ? value
            : "https://ism-dev-qngp98mp-bucket.s3.amazonaws.com/logo-inovyo.svg";
        break;
      case "logo-mobile-header":
        themeStorage.header.logoMobile = value;
        break;
      case "progressbar-footer":
        themeStorage.footer.progressBar = value;
        break;
      case "styles":
        themeStorage.styles = value;
        break;
      case "scripts":
        themeStorage.scripts = value;
        break;
      case "styles-head":
        value = value.replaceAll("\n", "").replaceAll(" ", "");
        themeStorage.stylesHead = value;
        break;
      case "scripts-head":
        value = value.replaceAll("\n", "").replaceAll(" ", "");
        themeStorage.scriptsHead = value;
        break;
      default:
        break;
    }

    setSession(`THEME_EDITION_${themeid}`, themeStorage, true);
    setUpdate(!update);
    setIsEqual(original === JSON.stringify(themeStorage));
  };

  const saveTheme = () => {
    let themeStorage = getSession(`THEME_EDITION_${themeid}`, true);

    themeStorage.scriptsHead =
      themeStorage.scriptsHead !== ""
        ? themeStorage.scriptsHead.split(",")
        : [];

    themeStorage.stylesHead =
      themeStorage.stylesHead !== "" ? themeStorage.stylesHead.split(",") : [];

    dispatch(updateTheme(themeid, themeStorage));
  };

  return (
    <>
      {!loading && themeData !== null && themeRedux !== null && ww > 720 && (
        <div id="theme-editor-page">
          <div className="main-edition">
            <div className="box-left">
              <Button
                type="link"
                href="/themes"
                icon={<BsFillHouseHeartFill />}
              >
                {i18n.ALL_THEMES}
              </Button>
              <div className="theme-title">
                <Input
                  value={themeData.themeName}
                  onChange={({ target: { value } }) => change(value, "title")}
                />
              </div>
              <Segmented
                options={[
                  { label: "Cabeçalho", value: "header" },
                  { label: "Rodapé", value: "footer" },
                  { label: "CSS", value: "css" },
                  { label: "JS", value: "js" },
                ]}
                value={segmentId}
                onChange={(value) => setSegmentId(value)}
                block
              />
              <div className="content-editor">
                {segmentId === "header" && (
                  <>
                    <div className="one-box">
                      <div>
                        <label className="label-input" htmlFor="height-header">
                          <BsArrowsExpand /> Altura do Cabeçalho
                          <span
                            style={{
                              color: "#bbb",
                              fontStyle: "italic",
                              fontSize: 12,
                            }}
                          >
                            (min: 50px | max: 150px)
                          </span>
                        </label>
                        <InputNumber
                          id="height-header"
                          min={50}
                          max={150}
                          value={themeData.header.height}
                          onChange={(value) => change(value, "height-header")}
                          addonAfter="px"
                          changeOnWheel
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="side-by-side">
                      <div>
                        <label
                          className="label-input"
                          htmlFor="position-header"
                        >
                          <BsAlignCenter /> Posicionamento
                        </label>
                        <Select
                          id="position-header"
                          options={[
                            { label: "Livre", value: 1 },
                            { label: "Fixo", value: 2 },
                          ]}
                          value={themeData.header.fixed ? 2 : 1}
                          onChange={(value) => change(value, "position-header")}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div>
                        <label className="label-input" htmlFor="shadow-header">
                          <BsBack /> Sombra
                        </label>
                        <Select
                          id="shadow-header"
                          options={[
                            { label: "Sim", value: 1 },
                            { label: "Não", value: 2 },
                          ]}
                          value={themeData.header?.shadow ? 1 : 2}
                          onChange={(value) => change(value, "shadow-header")}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="one-box">
                      <div>
                        <label className="label-input" htmlFor="topline-header">
                          <BsDashLg /> Linha do topo
                        </label>
                        <Select
                          id="topline-header"
                          options={[
                            { label: "Sim", value: 1 },
                            { label: "Não", value: 2 },
                          ]}
                          value={
                            themeData.header?.topLine &&
                            themeData.header.topLine?.active
                              ? 1
                              : 2
                          }
                          onChange={(value) => change(value, "topline-header")}
                          style={{ width: "100%" }}
                        />
                        {themeData.header?.topLine &&
                          themeData.header.topLine?.active && (
                            <Space>
                              <ColorPicker
                                value={themeData.header.topLine.color}
                                size="large"
                                style={{ marginTop: 5 }}
                                onChangeComplete={(color) =>
                                  change(
                                    color.toHexString(),
                                    "topline-color-header"
                                  )
                                }
                              />
                              <span>{themeData.header.topLine.color}</span>
                            </Space>
                          )}
                      </div>
                    </div>
                    <div className="side-by-side">
                      <div>
                        <label className="label-input" htmlFor="bgcolor-header">
                          <BsDropletFill /> Cor de fundo
                        </label>
                        <Space>
                          <ColorPicker
                            id="bgcolor-header"
                            value={themeData.header.bgColor}
                            size="large"
                            style={{ marginTop: 5 }}
                            onChangeComplete={(color) =>
                              change(color.toHexString(), "bgcolor-header")
                            }
                          />
                          <span>{themeData.header.bgColor}</span>
                        </Space>
                      </div>
                      <div>
                        <label
                          className="label-input"
                          htmlFor="height-logo-header"
                        >
                          <BsArrowsExpand /> Altura da Logo{" "}
                          <span
                            style={{
                              color: "#bbb",
                              fontStyle: "italic",
                              fontSize: 12,
                            }}
                          >
                            (min: 30px | max: 150px)
                          </span>
                        </label>
                        <InputNumber
                          id="height-logo-header"
                          min={30}
                          max={150}
                          value={themeData.header.logoHeight}
                          onChange={(value) =>
                            change(value, "height-logo-header")
                          }
                          addonAfter="px"
                          changeOnWheel
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="one-box">
                      <label className="label-input" htmlFor="logo-header">
                        <BsImageFill /> URL da Logo{" "}
                        <span
                          style={{
                            backgroundColor: "#ff8787",
                            color: "white",
                            borderRadius: 5,
                            padding: "0 5px",
                            fontSize: "12px",
                          }}
                        >
                          Recurso temporário
                        </span>
                      </label>
                      <Input
                        id="logo-header"
                        value={themeData.header.logo}
                        size="large"
                        style={{ marginTop: 5 }}
                        onChange={({ target: { value } }) =>
                          change(value, "logo-header")
                        }
                      />
                    </div>
                    <div className="one-box">
                      <label
                        className="label-input"
                        htmlFor="logo-mobile-header"
                      >
                        <BsImageFill /> URL da Logo (Mobile/Celular){" "}
                        <span
                          style={{
                            backgroundColor: "#ff8787",
                            color: "white",
                            borderRadius: 5,
                            padding: "0 5px",
                            fontSize: "12px",
                          }}
                        >
                          Recurso temporário
                        </span>
                      </label>
                      <Input
                        id="logo-mobile-header"
                        value={themeData.header.logoMobile}
                        size="large"
                        style={{ marginTop: 5 }}
                        onChange={({ target: { value } }) =>
                          change(value, "logo-mobile-header")
                        }
                      />
                    </div>
                  </>
                )}
                {segmentId === "footer" && (
                  <>
                    <div className="side-by-side">
                      <div>
                        <label
                          className="label-input"
                          htmlFor="position-footer"
                        >
                          <BsAlignCenter /> Posicionamento
                        </label>
                        <Select
                          id="position-footer"
                          options={[
                            { label: "Livre", value: 1 },
                            { label: "Fixo", value: 2 },
                          ]}
                          value={themeData.footer.fixed ? 2 : 1}
                          onChange={(value) => change(value, "position-footer")}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div>
                        <label className="label-input" htmlFor="shadow-footer">
                          <BsBack /> Sombra
                        </label>
                        <Select
                          id="shadow-footer"
                          options={[
                            { label: "Sim", value: 1 },
                            { label: "Não", value: 2 },
                          ]}
                          value={themeData.footer?.shadow ? 1 : 2}
                          onChange={(value) => change(value, "shadow-footer")}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="one-box">
                      <div>
                        <label
                          className="label-input"
                          htmlFor="bottomline-footer"
                        >
                          <BsDashLg /> Linha de baixo
                        </label>
                        <Select
                          id="bottomline-footer"
                          options={[
                            { label: "Sim", value: 1 },
                            { label: "Não", value: 2 },
                          ]}
                          value={
                            themeData.footer?.bottomLine &&
                            themeData.footer.bottomLine?.active
                              ? 1
                              : 2
                          }
                          onChange={(value) =>
                            change(value, "bottomline-footer")
                          }
                          style={{ width: "100%" }}
                        />
                        {themeData.footer?.bottomLine &&
                          themeData.footer.bottomLine?.active && (
                            <Space>
                              <ColorPicker
                                value={themeData.footer.bottomLine.color}
                                size="large"
                                style={{ marginTop: 5 }}
                                onChangeComplete={(color) =>
                                  change(
                                    color.toHexString(),
                                    "bottomline-color-footer"
                                  )
                                }
                              />
                              <span>{themeData.footer.bottomLine.color}</span>
                            </Space>
                          )}
                      </div>
                    </div>
                    <div className="side-by-side">
                      <div>
                        <label className="label-input" htmlFor="bgcolor-footer">
                          <BsDropletFill /> Cor de fundo
                        </label>
                        <Space>
                          <ColorPicker
                            id="bgcolor-footer"
                            value={themeData.footer.bgColor}
                            size="large"
                            style={{ marginTop: 5 }}
                            onChangeComplete={(color) =>
                              change(color.toHexString(), "bgcolor-footer")
                            }
                          />
                          <span>{themeData.footer.bgColor}</span>
                        </Space>
                      </div>
                      <div>
                        <label
                          className="label-input"
                          htmlFor="color-buttons-footer"
                        >
                          <BsCaretRightSquare /> Cor dos botões
                        </label>
                        <Space>
                          <ColorPicker
                            id="color-buttons-footer"
                            value={themeData.footer.colorButtons}
                            size="large"
                            style={{ marginTop: 5 }}
                            onChangeComplete={(color) =>
                              change(
                                color.toHexString(),
                                "color-buttons-footer"
                              )
                            }
                          />
                          <span>{themeData.footer.colorButtons}</span>
                        </Space>
                      </div>
                    </div>
                    <div className="one-box">
                      <div>
                        <label
                          className="label-input"
                          htmlFor="progressbar-footer"
                        >
                          <FaMattressPillow /> Barra de progresso
                        </label>
                        <Select
                          id="progressbar-footer"
                          options={[
                            { label: "Desabilitado", value: "disable" },
                            { label: "Percentual", value: "percent" },
                            { label: "Etapas", value: "steps" },
                          ]}
                          value={themeData.footer?.progressBar}
                          onChange={(value) =>
                            change(value, "progressbar-footer")
                          }
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {segmentId === "css" && (
                  <>
                    <MonacoEditor
                      height="50vh"
                      defaultLanguage="css"
                      value={themeData?.styles || ""}
                      theme="vs-dark"
                      onChange={(style) => change(style, "styles")}
                    />
                    <div className="three-parties">
                      <label htmlFor="">URL de Terceiros</label>
                      <span>
                        (Divida os links de css por vírgula [url,url,url...])
                      </span>
                      <TextArea
                        rows={4}
                        value={themeData?.stylesHead}
                        onChange={({ target: { value } }) =>
                          change(value, "styles-head")
                        }
                      />
                    </div>
                  </>
                )}
                {segmentId === "js" && (
                  <>
                    <MonacoEditor
                      height="50vh"
                      defaultLanguage="javascript"
                      value={themeData?.scripts || ""}
                      theme="vs-dark"
                      onChange={(script) => change(script, "scripts")}
                    />
                    <div className="three-parties">
                      <label htmlFor="">URL de Terceiros</label>
                      <span>
                        (Divida os links de js por vírgula [url,url,url...])
                      </span>
                      <TextArea
                        rows={4}
                        value={themeData?.scriptsHead}
                        onChange={({ target: { value } }) =>
                          change(value, "scripts-head")
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="box-right">
              <div className="theme-title">Pré-visualizar</div>
              <div className="preview-box">
                <div className="header" style={headerStyle}>
                  <img
                    src={themeData.header.logo}
                    alt="logo-header"
                    height={themeData.header.logoHeight}
                  />
                </div>
                <div className="body">CORPO DA PESQUISA</div>
                <div className="footer" style={footerStyle}>
                  <div className="box-buttons">
                    <button className="button-survey">Voltar</button>
                    <button className="button-survey">Avançar</button>
                  </div>
                  {themeData.footer?.progressBar &&
                    themeData.footer.progressBar !== "disable" && (
                      <div className="box-progress">
                        {themeData.footer.progressBar === "percent" && (
                          <div
                            className="static-bar"
                            style={{
                              borderColor: themeData.footer.colorButtons,
                            }}
                          >
                            <div
                              className="bar"
                              style={{
                                backgroundColor: themeData.footer.colorButtons,
                                width: `50%`,
                                color: colorProgressBarText,
                              }}
                            >
                              50%
                            </div>
                          </div>
                        )}

                        {themeData.footer.progressBar === "steps" && (
                          <div
                            className="steps-bar"
                            style={{
                              borderColor: themeData.footer.colorButtons,
                              color: colorProgressBarText,
                            }}
                          >
                            <div
                              className="bar"
                              style={{
                                backgroundColor: themeData.footer.colorButtons,
                              }}
                            >
                              1/2
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  <div className="box-poweredby">
                    Powered by Inovyo Experience Management
                  </div>
                  <style>{buttomStyle}</style>
                </div>
              </div>
            </div>
          </div>
          <div className="box-save">
            <Button
              type="primary"
              disabled={isEqual}
              onClick={() => saveTheme()}
            >
              SALVAR
            </Button>
            {!isEqual ? <span>Alterações não salvas</span> : <></>}
          </div>
        </div>
      )}
      {themeData === null && themeRedux === null && !loading && ww > 720 && (
        <div id="theme-editor-page">
          <Empty type="theme">Tema não encontrado</Empty>
        </div>
      )}
      {ww <= 720 && (
        <div id="theme-editor-page">
          <Empty type="sad">
            Desculpe! A edição de temas só funciona na resolução acima de 720px
          </Empty>
        </div>
      )}
    </>
  );
};

export default ThemeBuilderPage;
