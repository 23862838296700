/**
 * Informa se uma variável é um objeto ou não
 * @param {object} obj
 * @returns {boolean}
 */
export const isObject = (obj) => {
  return typeof obj === "object" && obj !== null;
};

/**
 * Retorna os grupos de operadores por blocos.
 * Cada bloco vai depender do tipo de questão que for escolhida
 * na criação de uma `lógica de questão`
 * @returns {{}}
 */
export const operatorBlocks = () => {
  return {
    //NPS | CSAT
    block1: [
      { value: "equal", label: "É igual a" },
      { value: "not-equal", label: "É diferente de" },
      { value: "in", label: "Contêm uma das opções" },
      { value: "not-in", label: "Não contêm uma das opções" },
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
      { value: "greater", label: "É maior que" },
      { value: "greater-equal", label: "É maior ou igual a" },
      { value: "less", label: "É menor que" },
      { value: "less-equal", label: "É menor ou igual a" },
    ],
    //CES | RADIOBOX | CHECKBOX | DROPDOWN | SELETOR DE IDIOMA | MOSAICO
    block2: [
      { value: "equal", label: "É igual a" },
      { value: "not-equal", label: "É diferente de" },
      { value: "in", label: "Contêm uma das opções" },
      { value: "not-in", label: "Não contêm uma das opções" },
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
    ],
    //OPTIN
    block3: [
      { value: "equal", label: "É igual a" },
      { value: "not-equal", label: "É diferente de" },
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
    ],
    //AUTO COMPLETAR | INPUT | TEXTAREA
    block4: [
      { value: "contains", label: "Contém a palavra" },
      { value: "not-contains", label: "Não contém a palavra" },
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
    ],
    //AUTO COMPLETAR | INPUT | TEXTAREA (QUANDO QUE VALIDAR VALOR EXATO)
    block5: [
      { value: "equal", label: "É igual a" },
      { value: "not-equal", label: "É diferente de" },
      { value: "contains", label: "Contém a palavra" },
      { value: "not-contains", label: "Não contém a palavra" },
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
    ],
    //DROPDOWN CASCATA
    block6: [
      { value: "answered", label: "Respondeu a questão" },
      { value: "not-answered", label: "Não respondeu a questão" },
    ],
    //URL
    block7: [
      { value: "equal", label: "É igual a" },
      { value: "not-equal", label: "É diferente de" },
      { value: "contains", label: "Contêm" },
      { value: "not-contains", label: "Não Contêm" },
    ],
  };
};

/**
 *
 * @param {string} type Tipo de análise. Questão, url ou contato.
 * @param {string} questionType Tipo da questão, para saber o grupo correto
 * @returns {{}}
 */
export const logicGroups = (type, questionType) => {
  const opBlocks = operatorBlocks();

  let types = {
    responses: {
      nps: opBlocks.block1,
      csat: opBlocks.block1,
      ces: opBlocks.block2,
      radiobox: opBlocks.block2,
      checkbox: opBlocks.block2,
      dropdown: opBlocks.block2,
      "language-dropdown": opBlocks.block2,
      mosaic: opBlocks.block2,
      optin: opBlocks.block3,
      "auto-complete": opBlocks.block4,
      input: opBlocks.block4,
      textarea: opBlocks.block4,
      "cascading-dropdown": opBlocks.block6,
    },
    url: opBlocks.block7,
  };

  return type === `responses` ? types[type][questionType] : types[type];
};
