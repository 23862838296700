import { configureStore } from "@reduxjs/toolkit";

import builder from "./builder/builder.slice";
import auth from "./auth/auth.slice";
import app from "./application/app.slice";
import home from "./general/home.slice";
import theme from "./theme/theme.slice";

export default configureStore({
  reducer: {
    app,
    auth,
    builder,
    home,
    theme,
  },
});
