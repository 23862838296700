import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "app",
  initialState: {
    loadingRedux: false,
    updateRedux: false,
    windowWidthRedux: window.innerWidth,
    windowHWRedux: [window.innerWidth, window.innerHeight],
  },
  reducers: {
    setLoadingRedux(state, { payload }) {
      state.loadingRedux = payload;
    },
    setUpdateRedux(state, { payload }) {
      state.updateRedux = payload;
    },
    setWindowWidthRedux(state) {
      state.windowWidthRedux = window.innerWidth;
    },
    setWindowHWRedux(state) {
      state.windowHWRedux = [window.innerWidth, window.innerHeight];
    },
  },
});

export const {
  setLoadingRedux,
  setUpdateRedux,
  setWindowWidthRedux,
  setWindowHWRedux,
} = slice.actions;

export const loadingRedux = (state) => state.app.loadingRedux;
export const updateRedux = (state) => state.app.updateRedux;
export const windowWidthRedux = (state) => state.app.windowWidthRedux;
export const windowHWRedux = (state) => state.app.windowHWRedux;

export default slice.reducer;
