import ThemesPage from "../../pages/themes";
import ThemeBuilderPage from "../../pages/themes/builder";

//temas
export const themesRoutes = () => {
  return [
    { path: "/themes", page: ThemesPage, auth: true },
    {
      path: "/t/:themeid/builder",
      page: ThemeBuilderPage,
      auth: true,
      name: "/t/",
    },
  ];
};
