import HomePage from "../../pages/general/home";
import TemplateBuilder from "../../pages/general/template-builder";

//GENERAL
export const generalRoutes = () => {
  return [
    { path: "/", exact: "/", page: HomePage, auth: true },
    {
      path: "/template-builder/:enviroment/:templateid",
      page: TemplateBuilder,
      auth: true,
      name: "/template-builder/",
    },
  ];
};
