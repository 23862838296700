import React from "react";
import { Interweave } from "interweave";

//styles
import "../../../../assets/styles/builder/nps.css";

const NPSComponent = (props) => {
  const { data, language } = props.data;

  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  let design = "nps";
  if (data.properties && data.properties.design) {
    design =
      {
        colorfull: "nps-colorfull",
        triade: "nps-triade",
      }[data.properties.design] || "nps";
  }

  const styleNPS =
    data.properties && data.properties.baseColor
      ? `
      div.nps-${data.id} .nps-content .item {
        border: solid 1px ${data.properties.baseColor} !important;
      }`
      : ``;

  return (
    <div id="nps-component" className={`${design} nps-${data.id}`}>
      <div className="nps-title">
        <Interweave content={title} />
      </div>
      <div className="nps-content">
        {data.options &&
          data.options.map((num, i) => {
            return (
              <div
                key={`nps-options-${i}`}
                className={`item ${
                  data.design !== "" ? "option-color-" + num : ""
                }`}
                alt={num}
              >
                {num}
              </div>
            );
          })}
      </div>
      {styleNPS !== "" && <style>{styleNPS}</style>}
    </div>
  );
};

export default NPSComponent;
