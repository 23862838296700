import React, { useEffect, useState, useRef } from "react";
import EmailEditor from "react-email-editor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, Empty } from "antd";
import MonacoEditor from "@monaco-editor/react";
import { StopOutlined } from "@ant-design/icons";

//utils
import i18n from "../../../utils/i18n";

//redux
import {
  getTempleteEmailById,
  updateTemplete,
} from "../../../store/general/home.action";
import { template } from "../../../store/general/home.slice";
import {
  loadingRedux,
  windowWidthRedux,
} from "../../../store/application/app.slice";

//styles
import "../../../assets/styles/general/template-builder.css";

const TemplateBuilder = () => {
  const dispatch = useDispatch();
  const { enviroment, templateid } = useParams();

  const emailEditorRef = useRef(null);
  const [openExport, setOpenExport] = useState(false);
  const [htmlData, setHtmlData] = useState(<></>);
  const [showBtn, setShowBtn] = useState(false);
  const [blockEdition, setBlockEdition] = useState(false);

  //redux
  const templateX = useSelector(template);
  const loading = useSelector(loadingRedux);
  const widthPage = useSelector(windowWidthRedux);

  useEffect(() => {
    toast.info(i18n.LOADING_TEMPLATE);
    dispatch(getTempleteEmailById(enviroment, templateid));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBlockEdition(widthPage < 1029);
    // eslint-disable-next-line
  }, [widthPage]);

  const onReady = (unlayer) => {
    if (template !== null) {
      unlayer.loadDesign(templateX);
    }

    setShowBtn(true);
  };

  const saveTemplate = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      setShowBtn(false);

      dispatch(
        updateTemplete(enviroment, templateid, {
          json_content: design,
          html_content: html,
        })
      );
    });
  };

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { html } = data;
      setHtmlData(html);
      setOpenExport(!openExport);
    });
  };

  return (
    <>
      {!loading && !blockEdition && (
        <div id="template-builder">
          {showBtn && (
            <div className="export">
              <Button onClick={exportHtml}>{i18n.EXPORT_HTML}</Button>
              <Button onClick={saveTemplate} type="primary">
                {i18n.SAVE}
              </Button>
            </div>
          )}
          <EmailEditor
            editorId="inovyo-template-editor"
            ref={emailEditorRef}
            onReady={onReady}
          />
        </div>
      )}
      {!loading && blockEdition && (
        <Empty
          image={<StopOutlined />}
          imageStyle={{ fontSize: "80px" }}
          description={
            <>
              O edição ativa apenas para
              <br /> resoluções maiores que 1029px.
            </>
          }
        />
      )}
      <Modal
        open={openExport}
        onCancel={() => setOpenExport(!openExport)}
        width={1000}
      >
        <div id="template-content-html">
          <MonacoEditor
            height="500px"
            defaultLanguage="html"
            value={htmlData}
            theme="vs-dark"
          />
        </div>
      </Modal>
    </>
  );
};

export default TemplateBuilder;
