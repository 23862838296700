import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "auth",
  initialState: {
    responseLogin: null,
  },
  reducers: {
    setResponseLogin(state, { payload }) {
      state.responseLogin = payload;
    },
  },
});

export const { setResponseLogin } = slice.actions;

export const responseLogin = (state) => state.auth.responseLogin;

export default slice.reducer;
