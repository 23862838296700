import React from "react";

import { getLuminosity } from "../../../../utils/String";

//styles
import "../../../../assets/styles/builder/reset-button.css";

const ResetButtonComponent = (props) => {
  const { data, language } = props.data;
  const { bgColor, align, block } = data.properties;

  let styleButton = {
    backgroundColor: bgColor ? bgColor : "#9a2376",
    color: bgColor
      ? getLuminosity(bgColor) === "dark"
        ? "#fff"
        : "#000"
      : "#fff",
    display: block ? "block" : "inline",
    width: block ? "100%" : "auto",
  };
  let styleContent = {
    textAlign: align ? align : "center",
  };

  return (
    <div id="reset-button-component">
      <div className="reset-button-content" style={styleContent}>
        <button style={styleButton} disabled>
          {data.title[language]}
        </button>
      </div>
    </div>
  );
};

export default ResetButtonComponent;
