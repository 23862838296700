import { toast } from "react-toastify";

//redux
import {
  fetchHomeSurveysFoldersAPI,
  createNewFolderAPI,
  createNewSurveyAPI,
  deleteSurveyAPI,
  updateSurveyAPI,
  moveSurveyAPI,
  deleteFolderAPI,
  updateFolderAPI,
  getTempleteEmailByIdAPI,
  updateTempleteAPI,
} from "../../store/general/home.service";
import { setSurveys, setTemplate } from "../../store/general/home.slice";
import { setLoadingRedux } from "../../store/application/app.slice";

//utils
import { setStorage, getStorage } from "../../utils/Storage";
import { getSession, deleteSession } from "../../utils/Session";
import i18n from "../../utils/i18n";

export const fetchHomeSurveysFolders = () => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    fetchHomeSurveysFoldersAPI()
      .then(({ data }) => {
        const surveysStorage = getStorage("SURVEYS", true);

        const surveyJSON = {
          id: data.id,
          type: data.type,
          wid: data.wid,
          selectedFolder: surveysStorage?.selectedFolder || {
            id: "*",
            title: i18n.INIT,
          },
          surveyView: "grid",
          surveyFolders: data.folders,
          surveyFull: data.full,
          breadcrumb: surveysStorage?.breadcrumb || [
            { id: "*", title: i18n.INIT },
          ],
          openFolders: surveysStorage?.openFolders || [],
        };

        setStorage("SURVEYS", surveyJSON, true);
        dispatch(setSurveys(surveyJSON));
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
        dispatch(setLoadingRedux(false));
      });
  };
};

export const createNewFolder = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewFolderAPI(data)
      .then(() => {
        toast.success(i18n.FOLDER_CREATED_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_CREATE_FOLDER, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const createNewSurvey = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewSurveyAPI(data)
      .then(({ data: surveyId }) => {
        toast.success(i18n.SURVEY_CREATED);
        setTimeout(() => {
          window.location.href = `/s/${surveyId}/builder`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_CREATED_SURVEY, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateSurvey = (surveyid, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateSurveyAPI(surveyid, data)
      .then(() => {
        toast.success(i18n.CHANGE_MADE_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = `/`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_MAKE_CHANGE, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateFolder = (folderid, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateFolderAPI(folderid, data)
      .then(() => {
        toast.success(i18n.CHANGE_MADE_SUCCESSFULLY);
        setTimeout(() => {
          window.location.href = `/`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAILED_MAKE_CHANGE, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const copySurvey = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createNewSurveyAPI(data)
      .then(({ data: surveyId }) => {
        toast.success(i18n.COPIED_SURVEY);
        setTimeout(() => {
          window.location.href = `/s/${surveyId}/builder`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COPY_SURVEY, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const deleteSurvey = (surveyid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    deleteSurveyAPI(surveyid)
      .then(() => {
        toast.success(i18n.DELETED_SURVEY);
        setTimeout(() => {
          window.location.href = `/`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_DELETE_SURVEY, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const deleteFolder = (folderid) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    deleteFolderAPI(folderid)
      .then(() => {
        toast.success(i18n.DELETED_FOLDER);
        setTimeout(() => {
          window.location.href = `/`;
        }, 2000);
      })
      .catch(() => {
        toast.error(i18n.FAIL_DELETE_FOLDER, { autoClose: 2000 });
        dispatch(setLoadingRedux(false));
      });
  };
};

export const hanfleDnD = () => {
  return (dispatch) => {
    const d = getSession("DRAGGIND_ID", true);
    const t = getSession("TARGET_ID", true);

    if (
      typeof d === "object" &&
      typeof t === "object" &&
      d !== "" &&
      t !== ""
    ) {
      const surveysStorage = getStorage("SURVEYS", true);

      // FOLDER => FOLDER
      if (d.type === "f" && t.type === "f") {
        //FOLDER ID === FOLDER ID
        if (d.draggingId === t.targetId) {
          toast.warning(i18n.CANNOT_MOVE_FOLDER_OWN_FOLDER, {
            autoClose: 2000,
          });
          return;
        }

        //FOLDER AT SAME LOCAL
        if (t.targetId === surveysStorage.selectedFolder.id) {
          toast.warning(i18n.MOVE_FOLDER_MSG1, {
            autoClose: 2000,
          });
          return;
        }
      }

      // SURVEY => FOLDER
      if (d.type === "s" && t.type === "f") {
        //SURVEY AT SAME LOCAL
        if (t.targetId === surveysStorage.selectedFolder.id) {
          toast.warning(i18n.MOVE_SURVEY_MSG1, {
            autoClose: 2000,
          });
          return;
        }
      }

      dispatch(setLoadingRedux(true));
      moveSurveyAPI({
        dragged: {
          //filho
          id: d.draggingId,
          type: d.type, // [f|s]
        },
        target: {
          //pai
          id: t.targetId,
          folderSurveyId: surveysStorage.id,
        },
      })
        .then(() => {
          toast.success(
            `${
              d.type === "f"
                ? i18n.FOLDER_MOVED_SUCCESSFULLY
                : i18n.SURVEY_MOVED_SUCCESSFULLY
            }`,
            {
              autoClose: 2000,
            }
          );
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch(() => {
          dispatch(setLoadingRedux(false));
          toast.error(i18n.FAIL_MOVE_ITEM, {
            autoClose: 2000,
          });
        });
    }

    deleteSession("DRAGGIND_ID");
    deleteSession("TARGET_ID");
  };
};

export const getTempleteEmailById = (enviroment, templateId) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getTempleteEmailByIdAPI(enviroment, templateId)
      .then(({ data }) => {
        if (data !== null) {
          dispatch(setTemplate(data));
        } else {
          toast.success(i18n.NEW_TEMPLATE_CREATED);
        }
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updateTemplete = (enviroment, templateId, data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updateTempleteAPI(enviroment, templateId, data)
      .then(() => {
        dispatch(setTemplate(data.json_content));
        toast.success(i18n.TEMPLATE_UPDATED);
      })
      .catch(() => {
        toast.error(i18n.FAIL_COMMUNICATION);
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};
