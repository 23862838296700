import { Empty, Card } from "antd";
import {
  InboxOutlined,
  LoadingOutlined,
  FileOutlined,
  FormatPainterOutlined,
} from "@ant-design/icons";

//utils
import i18n from "../../../utils/i18n";

//styles
import "../../../assets/styles/components/ui/empty-box.css";

const EmptyBox = ({ type }) => {
  const listIcons = {
    loading: {
      icon: <LoadingOutlined />,
      desc: i18n.LOADING,
    },
    "no-surveys": {
      icon: <FileOutlined />,
      desc: i18n.NO_SURVEYS_FOUND,
    },
    "empty-folder": {
      icon: <FileOutlined />,
      desc: i18n.EMPTY_FOLDER,
    },
    "empty-theme": {
      icon: <FormatPainterOutlined />,
      desc: i18n.EMPTY_THEME,
    },
    "survey-do-not-found": {
      icon: <FileOutlined />,
      desc: i18n.SURVEY_DO_NOT_FOUND,
    },
  }[type] || {
    icon: <InboxOutlined />,
    desc: i18n.NO_DATA,
  };

  return (
    <Card style={{ width: "100%" }} id="empty-box">
      <Empty
        style={{ textAlign: "center" }}
        image={listIcons.icon}
        imageStyle={{ height: "50px" }}
        description={listIcons.desc}
      />
    </Card>
  );
};

export default EmptyBox;
