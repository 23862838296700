import React, { useState, useContext } from "react";
import {
  FileTextOutlined,
  LogoutOutlined,
  HighlightOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { Card, Modal, Button, List, Avatar } from "antd";
import { toast } from "react-toastify";

//providers
import { Context } from "../../providers/AuthContext";

//styles
import "../../assets/styles/components/app/header-app.css";

//utils
import { getStorage, setStorage } from "../../utils/Storage";
import i18n from "../../utils/i18n";

//images
import Logo from "../../assets/images/logos/inovyo-survey-manager.svg";
import USFlag from "../../assets/images/flag-us-svgrepo-com.svg";
import BRFlag from "../../assets/images/flag-brazil-svgrepo-com.svg";

const HeaderApp = () => {
  const { authenticated } = useContext(Context);
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeModalLang, setActiveModalLang] = useState(false);
  const flagLanguages = {
    "pt-br": BRFlag,
    "en-us": USFlag,
  };

  const lang = getStorage("LANG") || "pt-br";

  let menuItems = [
    { label: i18n.SURVEYS, link: "/", icon: <FileTextOutlined /> },
    { label: i18n.THEMES, link: "/themes", icon: <HighlightOutlined /> },
    { label: i18n.FILES, link: "/files", icon: <FileZipOutlined /> },
  ];

  return (
    <header id="header-inovyo">
      {authenticated && (
        <div className="main-menu" onClick={() => setActiveMenu(true)}>
          <div className="sticks"></div>
        </div>
      )}
      <a href="/" className="logo-inovyo" title={i18n.INIT}>
        <img src={Logo} alt="Logo Site" />
      </a>
      <div className="language-box" onClick={() => setActiveModalLang(true)}>
        <img src={flagLanguages[lang]} alt="Language" />
      </div>

      <Modal
        footer={null}
        open={activeModalLang}
        closable={true}
        onCancel={() => setActiveModalLang(false)}
        styles={{
          body: {
            paddingTop: "30px",
          },
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={[
            { lang: "Português", icon: flagLanguages["pt-br"], value: "pt-br" },
            { lang: "English", icon: flagLanguages["en-us"], value: "en-us" },
          ]}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                toast.info(i18n.CHANGING_LANGUAGE, { autoClose: 2000 });
                setStorage("LANG", item.value);
                setTimeout(() => {
                  window.location = window.location.href;
                }, 2000);
              }}
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.icon} />}
                title={item.lang}
              />
            </List.Item>
          )}
        />
      </Modal>
      {authenticated && (
        <Modal
          footer={null}
          open={activeMenu}
          onCancel={() => setActiveMenu(false)}
          styles={{
            body: {
              paddingTop: "30px",
            },
          }}
        >
          <Card
            styles={{ body: { padding: 0, overflow: "hidden" } }}
            id="box-menu-main"
          >
            {menuItems.map((item, i) => (
              <a href={item.link} key={`menu-item-${i}`}>
                <span className="icon">{item.icon}</span>
                <span className="label">{item.label}</span>
              </a>
            ))}
          </Card>
          <Button
            type="link"
            href="/logout"
            icon={<LogoutOutlined />}
            danger
            style={{ marginTop: "10px" }}
          >
            {i18n.LOGOUT}
          </Button>
        </Modal>
      )}
    </header>
  );
};

export default HeaderApp;
