import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { FaRegTrashCan } from "react-icons/fa6";
import {
  FolderOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  GlobalOutlined,
  PlusOutlined,
  FileAddFilled,
  FolderAddFilled,
} from "@ant-design/icons";
import { Modal, Flex, Button, Form, Input, Select } from "antd";

//components
import { Context } from "../../providers/AuthContext";

//styles
import "../../assets/styles/components/app/tree-folders.css";

//redux
import { surveys, update, setUpdate } from "../../store/general/home.slice";
import {
  createNewFolder,
  createNewSurvey,
  hanfleDnD,
} from "../../store/general/home.action";

//utils
import { getStorage, setStorage } from "../../utils/Storage";
import { setSession, deleteSession } from "../../utils/Session";
import i18n from "../../utils/i18n";

const TreeFolders = () => {
  const dispatch = useDispatch();
  const lang = getStorage("LANG");
  const { version } = useContext(Context);

  const listSurveys = useSelector(surveys);
  const updateX = useSelector(update);
  const [treeByBrandcrumb, setTreeByBrandcrumb] = useState([]);
  const [completeFolders, setCompleteFolders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showModalCreateFolder, setShowModalCreateFolder] = useState(false);
  const [showModalCreateSurvey, setShowModalCreateSurvey] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [surveyName, setSurveyName] = useState("");
  const [appLanguage, setAppLanguage] = useState(lang !== "" ? lang : "pt-br");

  const updateTree = () => {
    const surveysStorage = getStorage("SURVEYS", true);
    if (surveysStorage !== "") {
      setTreeByBrandcrumb(surveysStorage?.breadcrumb);
      setCompleteFolders(surveysStorage?.surveyFolders);
    }
  };

  useEffect(() => {
    updateTree();
    // eslint-disable-next-line
  }, [updateX]);

  const extractIDFromBreadcrumb = () => {
    const ids = treeByBrandcrumb.map((folder) => {
      return folder.id;
    });
    return ids;
  };

  const existInBreadcrumb = (id) => {
    let treeByBrandcrumbCopy = [...treeByBrandcrumb];
    let finded = false;

    treeByBrandcrumbCopy.forEach((el) => {
      if (!finded && el.id === id) {
        finded = true;
      }
    });

    return finded;
  };

  const FolderStructure = () => {
    const surveysStorage = getStorage("SURVEYS", true);

    const [openFolder, setOpenFolder] = useState(extractIDFromBreadcrumb());
    const [previewFolders, setPreviewFolders] = useState(
      surveysStorage.openFolders
    );

    useEffect(() => {
      setOpenFolder(extractIDFromBreadcrumb());
      // eslint-disable-next-line
    }, [updateX]);

    const showFolder = (fData) => {
      const surveysStorage = getStorage("SURVEYS", true);
      let previewFoldersCopy = [...previewFolders];

      if (previewFoldersCopy.includes(fData.key)) {
        previewFoldersCopy.splice(previewFoldersCopy.indexOf(fData.key), 1);
      } else {
        previewFoldersCopy.push(fData.key);
      }

      setStorage(
        "SURVEYS",
        { ...surveysStorage, openFolders: previewFoldersCopy },
        true
      );
      setPreviewFolders(previewFoldersCopy);
    };

    const clickFolder = (className, fData, hierarchyData) => {
      const classString = typeof className === "string" ? className : "";

      if (
        classString.indexOf("name") >= 0 ||
        classString.indexOf("tree-list-item") >= 0
      ) {
        let surveysStorage = getStorage("SURVEYS", true);

        surveysStorage.selectedFolder = { id: fData.key, title: fData.title };
        surveysStorage.breadcrumb = hierarchyData;

        setStorage("SURVEYS", surveysStorage, true);
      }

      dispatch(setUpdate());
    };

    const MountLevels = ({ children, lvl, hierarchy }) => {
      return children.map((f, i) => {
        return (
          <React.Fragment key={`tree-list-item` + i}>
            <div
              className={`tree-list-item ${
                existInBreadcrumb(f.key) ? "checked" : ""
              }`}
              onClick={({ target: { className } }) =>
                clickFolder(className, f, [
                  ...hierarchy,
                  {
                    id: f.key,
                    title: f.title,
                    type: "f",
                  },
                ])
              }
              onDragStart={(e) => {
                setSession(
                  "DRAGGIND_ID",
                  { draggingId: f.key, type: "f" },
                  true
                );
                e.target.classList.add("dragging");
              }}
              onDragEnd={(e) => {
                e.target.classList.remove("dragging");
                let overred = document.querySelector(
                  "div.tree-list-item > div.name.dragover"
                );

                if (overred) {
                  overred.classList.remove("dragover");
                }

                dispatch(hanfleDnD());
              }}
              draggable={true}
            >
              <div className="icon" onClick={() => showFolder(f)}>
                {previewFolders.includes(f.key) ||
                openFolder.includes(f.key) ? (
                  <FolderOpenOutlined />
                ) : (
                  <FolderOutlined />
                )}
              </div>
              <div
                className="name"
                onDragLeave={(e) => {
                  e.target.classList.remove("dragover");
                  setTimeout(() => {
                    deleteSession(
                      "TARGET_ID",
                      { targetId: f.key, type: "f" },
                      true
                    );
                  }, 10);
                }}
                onDragEnter={() => {
                  setTimeout(() => {
                    setSession(
                      "TARGET_ID",
                      { targetId: f.key, type: "f" },
                      true
                    );
                  }, 15);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.target.classList.add("dragover");
                }}
              >
                {f.title}
              </div>
            </div>
            <div
              className={`lvl-${lvl} ${
                previewFolders.includes(f.key) || openFolder.includes(f.key)
                  ? "open"
                  : "close"
              }`}
            >
              {f.children.length > 0 && (
                <MountLevels
                  children={f.children}
                  lvl={lvl + 1}
                  hierarchy={[
                    ...hierarchy,
                    {
                      id: f.key,
                      title: f.title,
                      type: "f",
                    },
                  ]}
                />
              )}
            </div>
          </React.Fragment>
        );
      });
    };

    return (
      <MountLevels
        children={completeFolders}
        lvl={1}
        hierarchy={[
          {
            id: "*",
            title: i18n.INIT,
            type: "f",
          },
        ]}
      />
    );
  };

  const handleCreateFolder = () => {
    const surveysStorage = getStorage("SURVEYS", true);
    dispatch(
      createNewFolder({
        id: surveysStorage.id,
        parent_id: surveysStorage.selectedFolder.id,
        name: folderName,
      })
    );
    setTimeout(() => setFolderName(""), 1000);
  };

  const handleCreateSurvey = () => {
    const surveysStorage = getStorage("SURVEYS", true);
    dispatch(
      createNewSurvey({
        language: appLanguage,
        folder_id: surveysStorage.selectedFolder.id,
        name: surveyName,
        status: "active",
      })
    );
    setTimeout(() => setSurveyName(""), 1000);
  };

  const goHome = () => {
    let surveysStorage = getStorage("SURVEYS", true);
    surveysStorage.selectedFolder = { title: i18n.INIT, id: "*" };
    surveysStorage.breadcrumb = [{ title: i18n.INIT, id: "*" }];
    setStorage("SURVEYS", surveysStorage, true);
    dispatch(setUpdate());
  };

  const goAllSurveys = () => {
    let surveysStorage = getStorage("SURVEYS", true);
    surveysStorage.selectedFolder = {
      title: i18n.INIT,
      id: "full",
    };
    surveysStorage.breadcrumb = [{ title: i18n.ALL_SURVEYS, id: "full" }];
    setStorage("SURVEYS", surveysStorage, true);
    dispatch(setUpdate());
  };

  return (
    <div id="content-tree-inovyo">
      {listSurveys !== null && (
        <>
          <div className="tree-list">
            <div
              className={`tree-list-item add`}
              onClick={() => setOpenModal(!openModal)}
            >
              <div className="icon">
                <PlusOutlined />
              </div>
              <div className="name">{i18n.CREATE}</div>
            </div>
            <div
              className={`tree-list-item ${
                treeByBrandcrumb.length === 1 && existInBreadcrumb("*")
                  ? "checked"
                  : ""
              }`}
              onClick={() => goHome()}
            >
              <div className="icon">
                <HomeOutlined />
              </div>
              <div className="name">{i18n.INIT}</div>
            </div>
            <div
              className={`tree-list-item ${
                existInBreadcrumb("full") ? "checked" : ""
              }`}
              onClick={() => goAllSurveys()}
            >
              <div className="icon">
                <GlobalOutlined />
              </div>
              <div className="name">{i18n.ALL_SURVEYS}</div>
            </div>
          </div>
          <div className="tree-list">
            <FolderStructure />
          </div>
          <span className="version">V{version}</span>
        </>
      )}
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(!openModal)}
        footer={null}
        closable
        title={i18n.CREATE}
      >
        <Flex gap="small" wrap justify="space-around" align="center">
          <Button
            type="primary"
            block
            icon={<FileAddFilled />}
            onClick={() => {
              setOpenModal(!openModal);
              setShowModalCreateSurvey(!showModalCreateSurvey);
            }}
          >
            {i18n.SURVEY}
          </Button>
          <Button
            type="primary"
            block
            icon={<FolderAddFilled />}
            onClick={() => {
              setOpenModal(!openModal);
              setShowModalCreateFolder(!showModalCreateFolder);
            }}
          >
            {i18n.FOLDER}
          </Button>
        </Flex>
      </Modal>
      <Modal
        title={i18n.NEW_FOLDER}
        open={showModalCreateFolder}
        cancelText={i18n.CANCEL}
        okText={i18n.CREATE}
        onCancel={() => {
          setShowModalCreateFolder(false);
          setFolderName("");
        }}
        onOk={() => handleCreateFolder()}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.NAME} htmlFor="folder-name">
            <Input
              id="folder-name"
              placeholder={i18n.FOLDER_NAME}
              onChange={({ target: { value } }) => setFolderName(value)}
              value={folderName}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={i18n.NEW_SURVEY}
        open={showModalCreateSurvey}
        onCancel={() => {
          setShowModalCreateSurvey(false);
          setSurveyName("");
        }}
        footer={null}
        styles={{ body: { padding: "0px" } }}
        style={{ padding: "0px" }}
      >
        <Form layout="vertical">
          <Form.Item label={i18n.TITLE} htmlFor="survey-name">
            <Input
              id="survey-name"
              placeholder="Título da pesquisa"
              onChange={({ target: { value } }) => setSurveyName(value)}
              value={surveyName}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label={i18n.LANGUAGE} htmlFor="survey-language">
            <Select
              id="survey-language"
              placeholder="Idioma da pesquisa"
              defaultValue={appLanguage}
              options={i18n.LANGUAGES}
              onChange={({ target: { value } }) => setAppLanguage(value)}
            />
          </Form.Item>
          <Flex justify="flex-end" align="flex-end" gap={4}>
            <Button
              onClick={() => {
                setShowModalCreateSurvey(false);
                setSurveyName("");
              }}
            >
              {i18n.CANCEL}
            </Button>
            <Button type="primary" onClick={() => handleCreateSurvey()}>
              {i18n.CREATE}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
};

export default TreeFolders;
