import ReactLoading from "react-loading";

//utils
import i18n from "../../../utils/i18n";

//styles
import "../../../assets/styles/components/ui/loading.css";

const Loading = (props) => {
  const { type } = props;

  return (
    <div id={type && type === "progress" ? `loading-progress` : `loading-page`}>
      {type && type === "progress" ? (
        <span>{i18n.PROCESSING}</span>
      ) : (
        <ReactLoading type="spin" color="#9A2376" height="50px" width="50px" />
      )}
    </div>
  );
};

export default Loading;
