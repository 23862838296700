import React from "react";
import { Interweave } from "interweave";

//icons
import { BsFillCameraFill } from "react-icons/bs";

//styles
import "../../../../assets/styles/builder/image-upload.css";

const ImageUploadComponent = (props) => {
  const { data, language } = props.data;
  const title = data.active
    ? data.title[language]
    : `<s>${data.title[language]}</s>`;

  return (
    <div id="image-upload-component" className="image-upload">
      <div className="image-upload-title">
        <Interweave content={title} />
      </div>
      <div className="image-upload-content">
        <BsFillCameraFill />
      </div>
    </div>
  );
};

export default ImageUploadComponent;
