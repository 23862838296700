import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Card,
  Divider,
  Input,
  Button,
  List,
  Modal,
  Row,
  Col,
  Space,
} from "antd";
import { useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

//components
import HorizontalMenu from "../../../components/app/builder/HorizontalMenu";

//utils
import { getStorage } from "../../../utils/Storage";
import i18n from "../../../utils/i18n";

//redux
import {
  getSurveyById,
  updateGeneralData,
  downloadFileDocx,
} from "../../../store/builder/builder.action";
import {
  loadingRedux,
  setLoadingRedux,
} from "../../../store/application/app.slice";
import { surveyData } from "../../../store/builder/builder.slice";

//styles
import "../../../assets/styles/builder/build.css";

const BuilderGeneralPage = () => {
  const { Title } = Typography;
  const { surveyid } = useParams();
  const dispatch = useDispatch();
  const [modal, contextHolderModal] = Modal.useModal();

  const [jsonData, setJsonData] = useState(null);
  const [internalTitle, setInternalTitle] = useState(null);
  const [externalTitle, setExternalTitle] = useState(null);
  const [surveyLanguages, setSurveyLanguages] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const loading = useSelector(loadingRedux);
  const surveyDataRedux = useSelector(surveyData);

  useEffect(() => {
    dispatch(getSurveyById(surveyid));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveyDataRedux !== null) {
      setJsonData(getStorage("SURVEY_" + surveyid, true));
      dispatch(setLoadingRedux(false));
    }
    // eslint-disable-next-line
  }, [surveyDataRedux]);

  useEffect(() => {
    if (jsonData !== null) {
      setExternalTitle(jsonData.surveyName);
      setInternalTitle(jsonData.surveyNameInternal);
      setSurveyLanguages(jsonData.languageOptions);
    }
    // eslint-disable-next-line
  }, [jsonData]);

  useEffect(() => {
    if (jsonData !== null) {
      //extraindo idiomas que não estão setados na pesquisa
      let newLanguages = [];
      i18n.LANGUAGES.forEach((l) => {
        let set = true;

        surveyLanguages.forEach((s) => {
          if (s.label === l.label && set) {
            set = false;
          }
        });

        if (set) {
          newLanguages.push(l);
        }
      });
      setLanguageOptions(newLanguages);
    }
    // eslint-disable-next-line
  }, [surveyLanguages]);

  useEffect(() => {
    if (jsonData !== null) {
      if (
        surveyDataRedux.surveyName !== externalTitle ||
        surveyDataRedux.surveyNameInternal !== internalTitle ||
        JSON.stringify(surveyDataRedux.languageOptions) !==
          JSON.stringify(surveyLanguages)
      ) {
        setActiveBtn(true);
      } else {
        setActiveBtn(false);
      }
    }
    // eslint-disable-next-line
  }, [internalTitle, externalTitle, surveyLanguages]);

  //Excluir uma liniguagem
  const deleteLanguage = (lang) => {
    let newSurveyLanguages = [];
    surveyLanguages.forEach((s) => {
      if (s.value !== lang) {
        newSurveyLanguages.push(s);
      }
    });
    setSurveyLanguages(newSurveyLanguages);

    let jsonDataCopy = { ...jsonData };
    jsonDataCopy.languageOptions = newSurveyLanguages;

    //mapepeando questões e rmeovendo o idioma que foi excluído
    let newPages = [];
    jsonDataCopy.pages.forEach((page) => {
      let newQuests = [];
      page.questions.forEach((question) => {
        delete question.title[lang];
        newQuests.push(question);
      });

      page.questions = newQuests;
      newPages.push(page);
    });

    jsonDataCopy.pages = newPages;
    setJsonData(jsonDataCopy);
  };

  //adiciona uma liniguagem
  const addLanguage = (lang) => {
    let surveyLanguagescopy = [...surveyLanguages];
    surveyLanguagescopy.push(lang);
    setSurveyLanguages(surveyLanguagescopy);

    let jsonDataCopy = { ...jsonData };
    jsonDataCopy.languageOptions = surveyLanguagescopy;

    //mapepeando questões e adicionando o idioma que foi escolhido
    let newPages = [];
    jsonDataCopy.pages.forEach((page) => {
      let newQuests = [];
      page.questions.forEach((question) => {
        question.title[lang.value] = question.title[jsonData.language];
        newQuests.push(question);
      });

      page.questions = newQuests;
      newPages.push(page);
    });

    jsonDataCopy.pages = newPages;
    setJsonData(jsonDataCopy);

    setModalOpen(!modalOpen);
  };

  return (
    <>
      {contextHolderModal}
      <div id="build-page-general">
        {jsonData && jsonData !== null && !loading ? (
          <>
            <HorizontalMenu />
            <Card>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <strong>Título</strong>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Input
                      value={externalTitle}
                      onChange={({ target: { value } }) => {
                        setExternalTitle(value);
                        setJsonData({ ...jsonData, surveyName: value });
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <strong>Título interno</strong>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Input
                      value={internalTitle}
                      onChange={({ target: { value } }) => {
                        setInternalTitle(value);
                        setJsonData({ ...jsonData, surveyNameInternal: value });
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Title level={5}>Idioma da Pesquisa</Title>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <List
                      size="small"
                      bordered
                      dataSource={surveyLanguages}
                      renderItem={(item, index) => (
                        <List.Item key={`sl` + index}>
                          {item.value === jsonData.language ? (
                            <>
                              {item.label}
                              <br />
                              <span
                                style={{
                                  fontSize: 11,
                                  color: "#aaa",
                                }}
                              >
                                <em>*Idioma padrão não pode ser removido</em>
                              </span>
                            </>
                          ) : (
                            <>
                              {item.label}
                              <br />
                              <div>
                                <CloseOutlined
                                  onClick={() => {
                                    modal.confirm({
                                      title: "ATENÇÃO!",
                                      content:
                                        "Deseja excluir o idioma selecionado?",
                                      onOk: () => deleteLanguage(item.value),
                                      footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                          <CancelBtn />
                                          <OkBtn />
                                        </>
                                      ),
                                    });
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Button
                      type="primary"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      Adicionar idioma
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Title level={5}>Extra Developers</Title>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Button
                      href={`/template-builder/action/${surveyid}`}
                      target="_blank"
                    >
                      Editor de Email
                    </Button>
                    <Button
                      onClick={() => dispatch(downloadFileDocx(surveyid))}
                    >
                      Exportar
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Button
                      type="primary"
                      block
                      disabled={!activeBtn}
                      onClick={() => {
                        dispatch(updateGeneralData(surveyid, jsonData));
                      }}
                    >
                      SALVAR
                    </Button>
                  </Col>
                </Row>
              </Space>

              <Modal
                title="Idiomas"
                open={modalOpen}
                onCancel={() => setModalOpen(!modalOpen)}
                width={1000}
                footer={null}
                styles={{ body: { padding: "0px" } }}
                style={{ padding: "0px" }}
              >
                <List
                  id="list-language-options"
                  size="small"
                  bordered
                  dataSource={languageOptions}
                  renderItem={(item, index) => (
                    <List.Item
                      key={`so` + index}
                      onClick={() => addLanguage(item)}
                    >
                      {item.label}
                    </List.Item>
                  )}
                />
              </Modal>
            </Card>
          </>
        ) : (
          <Card>
            <div>Carregando....</div>
          </Card>
        )}
      </div>
    </>
  );
};

export default BuilderGeneralPage;
